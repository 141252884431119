import './notificationList.scss';

import { NotificationCard } from '@components/NotificationCard';
import { Typography } from '@prenetics/prenetics-react-library';
import { Notification } from '@services/api/b2b';
import colors from '@theme/colors.module.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface NotificationsByDateProps {
    todayNotifications: Notification[];
    oneWeekNotifications: Notification[];
    thirtyDaysNotifications: Notification[];
}

type IntervalType = 'today' | 'this_week' | 'last_month';

interface NotifcationListProps {
    notifications: NotificationsByDateProps;
    updateReadStatus: (notificationId: string, profileId?: string) => void;
    handleSeeMore: (interval: IntervalType) => void;
}

export const NotificationList = ({ notifications, updateReadStatus, handleSeeMore }: NotifcationListProps) => {
    const { t } = useTranslation();

    const { todayNotifications, oneWeekNotifications, thirtyDaysNotifications } = notifications;

    const generateSeeMoreButton = (interval: IntervalType) => {
        return (
            <Typography
                className="NotifcationList--title-container-link NotifcationList--card-link"
                text={t('notifications.list.link.see_more')}
                weight="black"
                color={colors.C5}
                type="p3"
                onClick={() => {
                    handleSeeMore(interval);
                }}
            />
        );
    };

    return (
        <div className="NotifcationList">
            <div className="NotifcationList--title-container">
                <Typography color={colors.B9} text={t('notifications.list.title.today')} type="p2" weight="semibold" />
                {todayNotifications.length > 9 && generateSeeMoreButton('today')}
            </div>
            {todayNotifications.length > 0 && (
                <div className="NotifcationList--list">
                    {todayNotifications.map(notification => (
                        <NotificationCard updateReadStatus={updateReadStatus} key={notification.notificationId} notification={notification} />
                    ))}
                </div>
            )}
            <div className="NotifcationList--title-container">
                <Typography color={colors.B9} text={t('notifications.list.title.this_week')} type="p2" weight="semibold" />
                {oneWeekNotifications.length > 9 && generateSeeMoreButton('this_week')}
            </div>
            {oneWeekNotifications.length > 0 && (
                <div className="NotifcationList--list">
                    {oneWeekNotifications.map(notification => (
                        <NotificationCard updateReadStatus={updateReadStatus} key={notification.notificationId} notification={notification} />
                    ))}
                </div>
            )}
            <div className="NotifcationList--title-container">
                <Typography color={colors.B9} text={t('notifications.list.title.last_month')} type="p2" weight="semibold" />
                {thirtyDaysNotifications.length > 9 && generateSeeMoreButton('last_month')}
            </div>
            {thirtyDaysNotifications.length > 0 && (
                <div className="NotifcationList--list">
                    {thirtyDaysNotifications.map(notification => (
                        <NotificationCard updateReadStatus={updateReadStatus} key={notification.notificationId} notification={notification} />
                    ))}
                </div>
            )}
        </div>
    );
};
