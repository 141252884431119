export const ResultSku = {
    'global-health': 'Health DNA Test',
    'global-premium': 'Premium DNA Test',
    'global-vital': 'Vital DNA Test',
    'global-vital-v2': 'Vital DNA Test',
    'bdmsth-health': 'Health DNA Test',
    'bdmsth-premium': 'Premium DNA Test',
    'bdmsth-vital': 'Vital DNA Test',
    'bdmsth-family-planning': 'Family Planning DNA Test',
} as const;

export const ResultSkuName = {
    'global-health': 'Health',
    'global-premium': 'Premium DNA',
    'global-vital': 'Vital DNA',
    'global-vital-v2': 'Vital DNA',
    'bdmsth-health': 'Health',
    'bdmsth-premium': 'Premium DNA',
    'bdmsth-vital': 'Vital DNA',
    'global-family-planning': 'Family Planning DNA',
    'bdmsth-family-planning': 'Family Planning DNA',
} as const;
