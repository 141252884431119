import './customerDetail.scss';

import ChevronLeftC5 from '@assets/images/ChevronLeftC5.svg?react';
import PencilImg from '@assets/images/pencil.svg?react';
import PlusImg from '@assets/images/plus.svg?react';
import { AddTestModal } from '@components/AddTestModal';
import { Badge, BadgeVariantEnum } from '@components/Badge';
import { Table } from '@components/Table';
import { TestStatusBadge } from '@components/TestStatusBadge';
import { Tooltip } from '@components/Tooltip';
import { TransferReportErrorModal } from '@components/TransferReportErrorModal';
import { TransferReportModal } from '@components/TransferReportModal';
import { UpdateCustomerModal } from '@components/UpdateCustomerModal';
import { UserAvatar } from '@components/UserAvatar';
import { API_BASE_URL } from '@config';
import type { Ethnicity } from '@customTypes/Ethnicity';
import { ResultSku } from '@customTypes/Result';
import { Button } from '@prenetics/prenetics-react-library/lib/Button';
import { InputWithButton } from '@prenetics/prenetics-react-library/lib/InputWithButton';
import { Loader } from '@prenetics/prenetics-react-library/lib/Loader';
import { Typography } from '@prenetics/prenetics-react-library/lib/Typography';
import { AdminContext } from '@providers/AdminProvider';
import { Profile, ProfileApiFactory, ResultApiFactory, ResultStatus, ResultWithExtraMetadata } from '@services/api/b2b';
import type { ColumnDef } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import colors from '@theme/colors.module.scss';
import { barcodeFormat } from '@util/barcode';
import { FeatureType, isFeatureEnabled } from '@util/feature';
import { isOldSKU } from '@util/result';
import { isAxiosError } from 'axios';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

const b2bProfileApi = ProfileApiFactory(undefined, API_BASE_URL + '/b2b');
const b2bResultApi = ResultApiFactory(undefined, API_BASE_URL + '/b2b');

const initialState: Profile = {
    profileId: '',
    externalId: '',
    externalUserId: '',
    name: {
        firstName: '',
        lastName: '',
    },
    health: {
        gender: '',
        ethnicity: '',
        dob: '',
        height: null,
        heightUnit: undefined,
        weight: null,
        weightUnit: undefined,
    },
    email: [],
    phone: [],
    createdAt: '',
    updatedAt: '',
    version: 0,
    results: [],
    transferred: false,
};

export const CustomerDetail = () => {
    const [customerDetail, setCustomerDetail] = useState(initialState);
    const [results, setResults] = useState<ResultWithExtraMetadata[]>([]);
    const [isAddTestModalOpen, setIsAddTestModalOpen] = useState(false);
    const [isTransferReportModalOpen, setIsTransferReportModalOpen] = useState(false);
    const [isTransferReportErrorModalOpen, setIsTransferReportErrorModalOpen] = useState(false);
    const [isUpdateCustomerModalOpen, setIsUpdateCustomerModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isTransferring, setIsTransferring] = useState(false);
    const navigate = useNavigate();
    const { customerId } = useParams();
    const { t } = useTranslation();
    const { ethnicityList } = useOutletContext<{
        ethnicityList: Ethnicity[];
    }>();
    const { admin } = useContext(AdminContext);

    const fullName = useMemo(() => `${customerDetail.name?.firstName || ''} ${customerDetail.name?.lastName || ''}`, [customerDetail.name?.firstName, customerDetail.name?.lastName]);
    const ethnicity = useMemo(() => {
        const item = ethnicityList.find(e => e.code === customerDetail.health?.ethnicity);
        return item?.description || '';
    }, [customerDetail.health?.ethnicity, ethnicityList]);
    const isReportReady = useMemo(() => results.some(r => r.resultStatus === ResultStatus.ReportReleased), [results]);
    const isReportOld = useMemo(() => results.some(r => r.resultStatus === ResultStatus.ReportReleased && isOldSKU(r.kitMetadata?.sku || '')), [results]);
    const canAddTest = useMemo(() => results.length === 0 || results.every(result => result.resultStatus === ResultStatus.SampleRejected), [results]);
    const canEditCustomer = useMemo(
        () => results.length === 0 || results.every(result => result.resultStatus === ResultStatus.Activated || result.resultStatus === ResultStatus.SampleRejected),
        [results],
    );
    const lastRejectedTestId = useMemo(() => {
        if (results.length === 0 || results.every(result => result.resultStatus !== ResultStatus.SampleRejected)) return null;

        return results.filter(result => result.resultStatus === ResultStatus.SampleRejected).pop()?.resultId;
    }, [results]);

    const columnHelper = createColumnHelper<ResultWithExtraMetadata>();

    const columns = [
        columnHelper.accessor(row => (row?.kitMetadata?.sku ? ResultSku[row.kitMetadata.sku as keyof typeof ResultSku] : ''), {
            header: () => <Typography weight="black" type="p3" color={colors.B9} text={t('customerDetail.test.table.column.testName')} />,
            id: 'sku',
        }),
        columnHelper.accessor('kitMetadata.barcode', {
            header: () => <Typography weight="black" type="p3" color={colors.B9} text={t('customerDetail.test.table.column.barcode')} />,
            cell: info => <Typography weight="regular" type="p3" color={colors.B9} text={barcodeFormat(info.getValue() || '')} />,
        }),
        columnHelper.accessor('resultStatus', {
            header: () => <Typography weight="black" type="p3" color={colors.B9} text={t('customerDetail.test.table.column.status')} />,
            cell: info => <TestStatusBadge status={info.getValue()} isOld={isOldSKU(info.row.original.kitMetadata?.sku || '')} />,
        }),
        columnHelper.accessor('resultStatus', {
            header: () => <Typography weight="black" type="p3" color={colors.B9} text={t('customerDetail.test.table.column.action')} />,
            cell: info => {
                if (info.getValue() === ResultStatus.SampleRejected && !(canAddTest && lastRejectedTestId === info.row.original.resultId)) {
                    return <Typography weight="regular" type="p3" color={colors.B9} text={t('customerDetail.test.action.sample_rejected_none')} />;
                }
                return (
                    <Typography
                        weight="regular"
                        type="p3"
                        color={colors.B9}
                        text={t(`customerDetail.test.action.${info.getValue()?.toLowerCase()}`, { context: customerDetail.transferred ? 'transferred' : '' })}
                    />
                );
            },
            id: 'action',
        }),
    ] as ColumnDef<object>[];

    const getResults = useCallback(async (profileId: string) => {
        try {
            const {
                data: { data },
            } = await b2bResultApi.getResults(profileId);

            setResults(data);
        } catch (error) {
            setResults([]);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const handleBackClick = () => {
        navigate('/customers');
    };

    const handleRowClick = (row: object) => {
        const { resultId } = row as ResultWithExtraMetadata;
        navigate(`/tests/${resultId}`);
    };

    const onAddTestSuccess = () => {
        if (!customerId) return;
        getResults(customerId);
    };

    const transferReport = async () => {
        if (!customerId) return;

        try {
            setIsTransferring(true);
            setIsTransferReportModalOpen(false);

            await b2bProfileApi.transferProfile(customerId);
            setCustomerDetail({ ...customerDetail, transferred: true });
        } catch (error) {
            if (isAxiosError(error) && error.response?.data.error_message === 'CUSTOMER_NOT_FOUND') {
                setIsTransferReportErrorModalOpen(true);
            }
        } finally {
            setIsTransferring(false);
        }
    };

    const onUpdateCustomerSuccess = async () => {
        if (!customerId) return;

        try {
            setIsLoading(true);
            const { data } = await b2bProfileApi.getProfileById(customerId);
            setCustomerDetail(data);
        } catch (error) {
            navigate('/customers');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const getProfile = async (profileId: string) => {
            try {
                setIsLoading(true);
                const { data } = await b2bProfileApi.getProfileById(profileId);
                setCustomerDetail(data);

                if (data.results?.length) {
                    getResults(profileId);
                } else {
                    setIsLoading(false);
                }
            } catch (error) {
                navigate('/customers');
            }
        };

        if (customerId) {
            getProfile(customerId);
        }
    }, [customerId, navigate, getResults]);

    return (
        <div className="CustomerDetail">
            <div className="CustomerDetail__detail">
                <div className="CustomerDetail__detail-header">
                    <Button
                        label={<Typography text={t('customers.title')} color={colors.C5} type="p3" weight="black" />}
                        buttonType="plain"
                        color={colors.C5}
                        leftIcon={() => <ChevronLeftC5 />}
                        onClick={handleBackClick}
                    ></Button>
                </div>
                <div className="CustomerDetail__detail-title">
                    <Typography text={fullName} color={colors.B9} type="h1" weight="semibold" />
                    {isReportReady && customerDetail.transferred && (
                        <Tooltip text={t('customerDetail.badge.tooltip.transferred', { context: isReportOld ? 'old' : '' })}>
                            <Badge text={t('customerDetail.badge.transferred', { context: isReportOld ? 'old' : '' })} variant={BadgeVariantEnum.Success} />
                        </Tooltip>
                    )}
                </div>
                <div className="CustomerDetail__detail-column">
                    <div className="CustomerDetail__tests">
                        <div className="CustomerDetail__tests-title">
                            <Typography text={t('customerDetail.title.tests')} color={colors.B9} type="h3" weight="semibold" />
                        </div>
                        {isLoading && <Loader />}
                        {results.length > 0 && (
                            <Table
                                data={results}
                                columns={columns}
                                onClickRow={handleRowClick}
                                disabledRows={{
                                    field: 'resultId' as never,
                                    ids: results.filter(result => result.resultStatus === ResultStatus.SampleRejected).map(result => result.resultId),
                                }}
                            />
                        )}
                        {!isLoading && (
                            <>
                                {canAddTest && (
                                    <Button
                                        label={<Typography text={t('customerDetail.button.add.test')} color={colors.C5} type="p3" weight="black" />}
                                        buttonType="secondary"
                                        borderColor={colors.C5}
                                        leftIcon={() => <PlusImg />}
                                        backgroundColor={colors.White}
                                        onClick={() => setIsAddTestModalOpen(true)}
                                    />
                                )}
                                {isReportReady && !customerDetail.transferred && (
                                    <Button
                                        label={<Typography text={t('customerDetail.button.transfer')} color={colors.White} weight="black" type="p2" />}
                                        backgroundColor={colors.C5}
                                        additionalsize="large"
                                        onClick={() => setIsTransferReportModalOpen(true)}
                                        isLoading={isTransferring}
                                    ></Button>
                                )}
                            </>
                        )}
                    </div>
                    {isFeatureEnabled(FeatureType.ENABLE_CONSULTATION) && (
                        <div className="CustomerDetail__consultations">
                            <div className="CustomerDetail__consultations-title">
                                <Typography text={t('customerDetail.title.consultations')} color={colors.B9} type="h3" weight="semibold" />
                            </div>
                            <div className="CustomerDetail__activity-block-content">
                                <Button
                                    label={<Typography text={t('customerDetail.button.add.consultation')} color={colors.C5} type="p3" weight="black" />}
                                    buttonType="secondary"
                                    borderColor={colors.C5}
                                    leftIcon={() => <PlusImg />}
                                    backgroundColor={colors.White}
                                />
                            </div>
                        </div>
                    )}
                    {isFeatureEnabled(FeatureType.ENABLE_CONSULTATION) && (
                        <div className="CustomerDetail__activity">
                            <div className="CustomerDetail__activity-title">
                                <Typography text={t('customerDetail.title.activity')} color={colors.B9} type="h3" weight="semibold" />
                            </div>
                            <div className="CustomerDetail__activity-comment">
                                <UserAvatar size="small" name={fullName} />
                                <InputWithButton additionalsize="medium" button={{ label: 'Comment', buttonType: 'secondary', color: colors.C5, borderColor: colors.C5 }} />
                            </div>
                            <div className="CustomerDetail__activity-log">
                                <div className="CustomerDetail__activity-log-title">
                                    <Typography text="Today" color={colors.B9} type="p2" weight="semibold" />
                                </div>
                                <div className="CustomerDetail__activity-log-block">
                                    <UserAvatar size="small" name={fullName} />
                                    <div className="CustomerDetail__activity-log-info">
                                        <Typography text="CircleDNA test" color={colors.B9} type="p3" weight="semibold" />
                                        <Typography text="sample rejected." color={colors.B9} type="p3" weight="regular" />
                                        <Typography text="12:20, Today" color={colors.B9} type="p3" weight="semiboldItalic" />
                                    </div>
                                </div>
                            </div>
                            <div className="CustomerDetail__activity-log">
                                <div className="CustomerDetail__activity-log-title">
                                    <Typography text="This week" color={colors.B9} type="p2" weight="semibold" />
                                </div>
                                <div className="CustomerDetail__activity-log-block">
                                    <UserAvatar size="small" name={fullName} />
                                    <div className="CustomerDetail__activity-log-info">
                                        <Typography text="CircleDNA test" color={colors.B9} type="p3" weight="semibold" />
                                        <Typography text="sample rejected." color={colors.B9} type="p3" weight="regular" />
                                        <Typography text="12:20, Today" color={colors.B9} type="p3" weight="regular" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="CustomerDetail__info">
                <div className="CustomerDetail__info-group">
                    <div className="CustomerDetail__info-title">
                        <Typography text={t('customers.customerDetail.title.info')} color={colors.B9} type="p3" weight="semibold" />
                        {!isLoading && canEditCustomer && (
                            <div className="CustomerDetail__info-title-icon">
                                <Button leftIcon={() => <PencilImg />} label="" buttonType="plain" onClick={() => setIsUpdateCustomerModalOpen(true)} />
                            </div>
                        )}
                    </div>
                    <div className="CustomerDetail__info-field">
                        <Typography text={t('customers.addCustomer.modal.field.firstName')} color={colors.B6} type="caption" weight="semibold" />
                        <Typography text={customerDetail.name?.firstName || ''} color={colors.B9} type="p3" weight="regular" />
                    </div>
                    <div className="CustomerDetail__info-field">
                        <Typography text={t('customers.addCustomer.modal.field.lastName')} color={colors.B6} type="caption" weight="semibold" />
                        <Typography text={customerDetail.name?.lastName || ''} color={colors.B9} type="p3" weight="regular" />
                    </div>
                    <div className="CustomerDetail__info-field">
                        <Typography text={t('customers.addCustomer.modal.field.gender')} color={colors.B6} type="caption" weight="semibold" />
                        <Typography text={customerDetail.health?.gender ? t(`common.${customerDetail.health.gender}`) : ''} color={colors.B9} type="p3" weight="regular" />
                    </div>
                    <div className="CustomerDetail__info-field">
                        <Typography text={t('customers.addCustomer.modal.field.ethnicity')} color={colors.B6} type="caption" weight="semibold" />
                        <Typography text={ethnicity} color={colors.B9} type="p3" weight="regular" />
                    </div>
                    <div className="CustomerDetail__info-field">
                        <Typography text={t('customers.addCustomer.modal.field.dob')} color={colors.B6} type="caption" weight="semibold" />
                        <Typography text={customerDetail.health?.dob ? moment(customerDetail.health.dob).format('YYYY-MM-DD') : ''} color={colors.B9} type="p3" weight="regular" />
                    </div>
                    <div className="CustomerDetail__info-field">
                        <div>
                            <Typography text={t('customers.addCustomer.modal.field.height')} color={colors.B6} type="caption" weight="semibold" />
                        </div>
                        <div className="CustomerDetail__info-field-content">
                            <Typography
                                text={customerDetail.health?.height ? `${customerDetail.health?.height} ${customerDetail.health?.heightUnit}` : ''}
                                color={colors.B9}
                                type="p3"
                                weight="regular"
                            />
                        </div>
                    </div>
                    <div className="CustomerDetail__info-field">
                        <Typography text={t('customers.addCustomer.modal.field.weight')} color={colors.B6} type="caption" weight="semibold" />
                        <Typography text={customerDetail.health?.weight ? `${customerDetail.health?.weight} ${customerDetail.health?.weightUnit}` : ''} color={colors.B9} type="p3" weight="regular" />
                    </div>
                    <div className="CustomerDetail__info-field">
                        <Typography text={t('customers.customerDetail.title.customerId')} color={colors.B6} type="caption" weight="semibold" />
                        <div className="CustomerDetail__info-externalUserId--expand">
                            <Typography text={customerDetail.externalUserId || ''} color={colors.B9} type="p3" weight="regular" />
                        </div>
                    </div>
                </div>
                <div className="CustomerDetail__info-group">
                    <div className="CustomerDetail__info-title">
                        <Typography text={t('customers.customerDetail.title.contact')} color={colors.B9} type="p3" weight="semibold" />
                    </div>
                    <div className="CustomerDetail__info-field">
                        <div>
                            <Typography text={t('customers.addCustomer.modal.field.email')} color={colors.B6} type="caption" weight="semibold" />
                        </div>
                        {customerDetail.email?.map(item => {
                            return (
                                <div className="CustomerDetail__info-field-content" key={item.emailId}>
                                    <Typography text={item.email || ''} color={colors.B9} type="p3" weight="regular" />
                                </div>
                            );
                        })}
                    </div>
                    <div className="CustomerDetail__info-field">
                        <div>
                            <Typography text={t('customers.customerDetail.title.phone')} color={colors.B6} type="caption" weight="semibold" />
                        </div>
                        {customerDetail.phone?.map(item => {
                            return (
                                <div className="CustomerDetail__info-field-content" key={item.phoneId}>
                                    <Typography text={`${item.countryCode}${item.number}` || ''} color={colors.B9} type="p3" weight="regular" />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <AddTestModal
                isOpen={isAddTestModalOpen}
                closeModal={() => setIsAddTestModalOpen(false)}
                profileId={customerId}
                organisationId={admin?.organisation?.organisationId}
                onSuccess={onAddTestSuccess}
            />
            <TransferReportModal
                isOpen={isTransferReportModalOpen}
                closeModal={() => setIsTransferReportModalOpen(false)}
                onSuccess={transferReport}
                email={customerDetail?.email && customerDetail.email.length > 0 ? customerDetail.email[0].email : ''}
            />
            <TransferReportErrorModal isOpen={isTransferReportErrorModalOpen} closeModal={() => setIsTransferReportErrorModalOpen(false)} />
            {!isLoading && (
                <UpdateCustomerModal
                    isOpen={isUpdateCustomerModalOpen}
                    closeModal={() => setIsUpdateCustomerModalOpen(false)}
                    ethnicityList={ethnicityList}
                    initialPersonalDetail={customerDetail}
                    onSuccess={onUpdateCustomerSuccess}
                />
            )}
        </div>
    );
};
