import { COOKIE_DOMAIN } from '@config';
import i18n, { ResourceKey, use } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import enHk from '../languages/en-HK.json';
import thTH from '../languages/th-TH.json';

export enum LanguagesWebApp {
    en = 'English',
    th = 'ภาษาไทย',
}

export const defaultNS = 'common';
export const resources: Record<keyof typeof LanguagesWebApp, Record<string, ResourceKey>> = {
    en: {
        common: enHk,
    },
    th: {
        common: thTH,
    },
} as const;

use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        fallbackLng: code => {
            if (!code || code === 'en' || code === 'en_hk') return ['en'];
            const fallbacks = [code];
            fallbacks.push('en');
            return fallbacks;
        },
        supportedLngs: ['en', 'th'],
        resources,
        ns: ['common', 'tos'],
        detection: {
            // order and from where user language should be detected
            order: ['querystring', 'cookie'],
            lookupCookie: 'currentLanguage',
            lookupQuerystring: 'lang',
            // cache user language on
            caches: ['cookie'],
            // optional expire and domain for set cookie
            cookieMinutes: 60 * 24 * 30, // 30 days
            cookieDomain: COOKIE_DOMAIN,
        },
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
