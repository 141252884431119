import moment from 'moment';

export const API_BASE_URL = process.env.VITE_API_END_POINT || 'http://localhost:3010';
export const APP_ENVIRONMENT = process.env.VITE_APP_ENVIRONMENT || 'dev';
export const SENTRY_DSN = process.env.VITE_SENTRY_DSN || 'dsn_test';

export const BASE_URL = window.location.origin;

export const IS_PRODUCTION = APP_ENVIRONMENT === 'prod';
export const IS_STAGING = APP_ENVIRONMENT === 'staging';
export const IS_LOCAL = APP_ENVIRONMENT === 'local';
export const ENV = IS_PRODUCTION ? 'production' : IS_STAGING ? 'staging' : 'testing';

export const COOKIE_DOMAIN = window.location.hostname.includes('localhost') || window.location.hostname.includes('ngrok') ? undefined : process.env.VITE_COOKIE_DOMAIN;
export const CIRCLE_DNA_WEBSITE_URL = process.env.VITE_CIRCLEDNA_WEBSITE_URL || 'https://circledna.com';

export const COOKIE_PARAMS = {
    expires: moment().add(30, 'days').toDate(),
    path: '/',
    domain: COOKIE_DOMAIN,
};

export const X_OWNER = 'X-Owner';

export const OTP_LENGTH = 4;
export const OTP_RESEND_DELAY_SECONDS = 20;

export const PHONE_NUMBER_MAX_LENGTH = 20;
