import { API_BASE_URL, COOKIE_PARAMS } from '@config';
import { useAuth } from '@prenetics/react-context-provider';
import type { AdminWithExtra } from '@services/api/b2b';
import { AdminApiFactory, OrganisationApiFactory } from '@services/api/b2b';
import { initializeAxios } from '@services/axios';
import React, { createContext, FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

export const b2bAdminApi = AdminApiFactory(undefined, API_BASE_URL + '/b2b');
export const b2bOrganisationApi = OrganisationApiFactory(undefined, API_BASE_URL + '/b2b');

export type AdminContextType = {
    admin: AdminWithExtra;
    adminTeamId: string;
    adminOrganisationId: string | undefined;
    getAdmin: () => void;
    logout: () => void;
};

const initialState: AdminWithExtra = {
    adminId: '',
    name: '',
    roles: [],
    team: {
        teamId: '',
        name: '',
        createdAt: '',
        updatedAt: '',
        version: 0,
    },
    organisation: {
        organisationId: '',
        name: '',
        active: false,
        createdAt: '',
        updatedAt: '',
        version: 0,
    },
    physicianDetails: {
        alias: '',
        email: '',
        phone: {
            countryCode: '',
            phoneNumber: '',
        },
        alternateEmail: '',
    },
    createdAt: '',
    updatedAt: '',
    version: 0,
    active: false,
    isOrgAdmin: false,
};

export const AdminContext = createContext<AdminContextType>({
    admin: initialState,
    adminTeamId: '',
    adminOrganisationId: '',
    getAdmin: () => {},
    logout: () => {},
});

const AdminProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [admin, setAdmin] = useState(initialState);
    const [adminTeamId, setAdminTeamId] = useState('');
    const [adminOrganisationId, setAdminOrganisationId] = useState<string | undefined>(undefined);
    const { logout: logoutWithSpockAuth, token } = useAuth();
    const navigate = useNavigate();
    const [, setToken] = useCookies(['token']);

    const logout = useCallback(async () => {
        setToken('token', '', COOKIE_PARAMS);
        setAdmin(initialState);
        navigate('/login');
        await logoutWithSpockAuth();
    }, [logoutWithSpockAuth, navigate, setToken]);

    const getTeamsOfOrganisation = useCallback(async (organisationId: string) => {
        try {
            const {
                data: { data },
            } = await b2bOrganisationApi.getOrganisationTeams(organisationId);

            if (data.length) {
                setAdminTeamId(data[0].teamId);
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    const getAdmin = useCallback(async () => {
        try {
            const { data } = await b2bAdminApi.getAdmin();
            setAdmin(data);

            if (data.isOrgAdmin && data.organisation?.organisationId) {
                setAdminOrganisationId(data.organisation.organisationId);
                getTeamsOfOrganisation(data.organisation.organisationId);
            } else if (data.team?.teamId) {
                setAdminOrganisationId(undefined);
                setAdminTeamId(data.team.teamId);
            }
        } catch (error) {
            logout();
        }
    }, [logout, getTeamsOfOrganisation]);

    useEffect(() => {
        if (token) {
            initializeAxios(logout, token);
            getAdmin();
        }
    }, [getAdmin, logout, token]);

    return <AdminContext.Provider value={{ admin, adminTeamId, adminOrganisationId, getAdmin, logout }}>{children}</AdminContext.Provider>;
};

export default AdminProvider;
