import './tests.scss';

import ProfileIcon from '@assets/images/profile.svg?react';
import { Table } from '@components/Table';
import { TestStatusBadge } from '@components/TestStatusBadge';
import { API_BASE_URL } from '@config';
import { ResultSku } from '@customTypes/Result';
import { Button } from '@prenetics/prenetics-react-library/lib/Button';
import { Dropdown } from '@prenetics/prenetics-react-library/lib/DropDown';
import { InputSearch } from '@prenetics/prenetics-react-library/lib/InputSearch';
import { Loader } from '@prenetics/prenetics-react-library/lib/Loader';
import { Pagination } from '@prenetics/prenetics-react-library/lib/Pagination';
import { Typography } from '@prenetics/prenetics-react-library/lib/Typography';
import { AdminContext } from '@providers/AdminProvider';
import type { ResultWithExtraMetadata } from '@services/api/b2b';
import { ResultApiFactory, ResultStatus } from '@services/api/b2b';
import { sendException } from '@services/util';
import type { ColumnDef } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import colors from '@theme/colors.module.scss';
import { barcodeFormat } from '@util/barcode';
import { debounce } from '@util/debounce';
import { isOldSKU } from '@util/result';
import { AxiosError } from 'axios';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const b2bResultApi = ResultApiFactory(undefined, API_BASE_URL + '/b2b');

const PAGE_SIZE = 8;

export const Tests = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [results, setResults] = useState<ResultWithExtraMetadata[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalTestCount, setTotalTestCount] = useState(0);
    const [filteredStatus, setFilteredStatus] = useState<ResultStatus | undefined>(undefined);
    const [searchInput, setSearchInput] = useState('');
    const { adminTeamId, adminOrganisationId } = useContext(AdminContext);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const statusOptions = useMemo(() => {
        return [
            { id: 'all', name: 'All' },
            ...Object.values(ResultStatus)
                .map(status => ({
                    id: status,
                    name: t(`common.test.status.${status.toLowerCase()}`),
                }))
                .filter(status => status.id !== ResultStatus.ExpectedReportReleased),
        ];
    }, [t]);

    const columnHelper = createColumnHelper<ResultWithExtraMetadata>();

    const columns = [
        columnHelper.accessor('profileId', {
            header: () => <Typography weight="black" type="p3" color={colors.B9} text={t('tests.table.column.customer')} />,
            cell: info => <Button buttonType="plain" onClick={() => navigate(`/customers/${info.getValue()}`)} label="" leftIcon={() => <ProfileIcon />} />,
        }),
        columnHelper.accessor('kitMetadata.barcode', {
            header: () => <Typography weight="black" type="p3" color={colors.B9} text={t('tests.table.column.barcode')} />,
            cell: info => (
                <Button
                    buttonType="plain"
                    label={<Typography weight="regular" type="p3" color={colors.C5} style={{ textDecoration: 'underline' }} text={barcodeFormat(info.getValue() || '')} />}
                    onClick={() => navigate(`/tests/${info.row.original.resultId}`)}
                />
            ),
        }),
        columnHelper.accessor('kitMetadata.sku', {
            header: () => <Typography weight="black" type="p3" color={colors.B9} text={t('tests.table.column.name')} />,
            cell: info => <Typography weight="regular" type="p3" color={colors.B9} text={ResultSku[info.getValue() as keyof typeof ResultSku]} />,
        }),

        columnHelper.accessor('resultStatus', {
            header: () => <Typography weight="black" type="p3" color={colors.B9} text={t('tests.table.column.status')} />,
            cell: info => <TestStatusBadge status={info.getValue()} isOld={isOldSKU(info.row.original.kitMetadata?.sku || '')} />,
        }),
        columnHelper.accessor('teamName', {
            header: () => <Typography weight="black" type="p3" color={colors.B9} text={t('tests.table.column.clinic')} />,
            cell: info => <Typography weight="regular" type="p3" color={colors.B9} text={info.getValue() || ''} />,
        }),
        columnHelper.accessor('kitMetadata.datetime', {
            header: () => <Typography weight="black" type="p3" color={colors.B9} text={t('tests.table.column.date')} />,
            cell: info => <Typography weight="regular" type="p3" color={colors.B9} text={moment(info.getValue()).format('YYYY-MM-DD')} />,
        }),
    ] as ColumnDef<object>[];

    const getResults = useCallback(async (teamId: string, organisationId: string | undefined, from: number, size: number, status: ResultStatus | undefined, search: string) => {
        try {
            setIsLoading(true);

            const {
                data: { data, totalCount },
            } = await b2bResultApi.getResults(undefined, teamId, organisationId, from, size, status, search);

            setResults(data);
            setTotalTestCount(totalCount || 0);
        } catch (err) {
            const error = err as Error | AxiosError;
            setResults([]);
            setTotalTestCount(0);
            sendException(error, false, { from, size, status, search, teamId, organisationId });
        } finally {
            setIsLoading(false);
        }
    }, []);

    const debouncedGetResults = useMemo(() => debounce(getResults as unknown as (...args: unknown[]) => void, 300), [getResults]);

    useEffect(() => {
        if (adminTeamId) {
            debouncedGetResults(adminTeamId, adminOrganisationId, (currentPage - 1) * PAGE_SIZE, PAGE_SIZE, filteredStatus, searchInput.trim() || undefined);
        } else if (adminOrganisationId) {
            setIsLoading(false);
        }
    }, [adminTeamId, adminOrganisationId, debouncedGetResults, filteredStatus, currentPage, searchInput]);

    const handlePageChange = (page: number | string) => {
        if (typeof page === 'string') return;
        setCurrentPage(page);
    };

    return (
        <div className="Tests">
            <div className="Tests__header">
                <Typography weight="semibold" type="h1" color={colors.B9} text={t('tests.title')} />
            </div>
            <div className="Tests__search">
                <InputSearch value={searchInput} onChange={e => setSearchInput(e.target.value)} onClear={() => setSearchInput('')} placeholder={t('tests.search.input.placeholder')} />
                <Dropdown
                    dropDownList={statusOptions}
                    itemTextColorOnHover={colors.White}
                    itemBackgroundColorOnHover={colors.C5}
                    itemSelectedTextColor={colors.White}
                    itemSelectedBackgroundColor={colors.C2}
                    isReadOnly={true}
                    onItemChange={item => {
                        if (typeof item !== 'string') {
                            if (item.id === 'all') {
                                setFilteredStatus(undefined);
                            } else {
                                setFilteredStatus(item.id as ResultStatus);
                            }
                        } else {
                            setFilteredStatus(item as ResultStatus);
                        }
                        setCurrentPage(1);
                    }}
                    onCreateNewItem={() => null}
                    onClickArrow={() => null}
                    placeholder="Filter by status"
                />
            </div>
            {isLoading && <Loader />}
            {!isLoading && (
                <div className="Tests__table">
                    <div className="Tests__table-title">
                        <Typography weight="semibold" type="h4" color={colors.B9} text={t('tests.search.total.test.count', { count: totalTestCount })} />
                        <Pagination totalCount={totalTestCount} pageSize={PAGE_SIZE} currentPage={currentPage} onPageChange={handlePageChange} />
                    </div>
                    {totalTestCount > 0 && <Table data={results} columns={columns} />}
                </div>
            )}
        </div>
    );
};
