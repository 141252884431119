import '@components/Card/card.scss';
import './customers.scss';

import AddIcon from '@assets/images/add.svg?react';
import AncestryImg from '@assets/images/ancestry.svg';
import ChevronDownIcon from '@assets/images/chevronDown.svg?react';
import { Table } from '@components/Table';
import { TestStatusBadge } from '@components/TestStatusBadge';
import { API_BASE_URL } from '@config';
import { ResultSku } from '@customTypes/Result';
import { Button } from '@prenetics/prenetics-react-library/lib/Button';
import { InputSearch } from '@prenetics/prenetics-react-library/lib/InputSearch';
import { Loader } from '@prenetics/prenetics-react-library/lib/Loader';
import { Pagination } from '@prenetics/prenetics-react-library/lib/Pagination';
import { Typography } from '@prenetics/prenetics-react-library/lib/Typography';
import { AdminContext } from '@providers/AdminProvider';
import type { Profile, ResultWithExtraMetadata } from '@services/api/b2b';
import { ProfileApiFactory, ResultApiFactory } from '@services/api/b2b';
import { sendException } from '@services/util';
import type { ColumnDef } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import colors from '@theme/colors.module.scss';
import { debounce } from '@util/debounce';
import { isOldSKU } from '@util/result';
import { AxiosError } from 'axios';
import moment from 'moment';
import type { MouseEvent } from 'react';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';

const b2bProfileApi = ProfileApiFactory(undefined, API_BASE_URL + '/b2b');
const b2bResultApi = ResultApiFactory(undefined, API_BASE_URL + '/b2b');

export const NoCustomerCard = ({ openAddCustomerModal }: { openAddCustomerModal: () => void }) => {
    const { t } = useTranslation('common');

    return (
        <div className="NoCustomer">
            <div className="NoCustomerCard Card">
                <img src={AncestryImg} width={120} height={120} alt="No customer" />
                <div className="Card__body">
                    <div className="Card__body-title">
                        <Typography weight="semibold" type="h3" color={colors.B9} text={t('customers.noCustomer.card.title')} />
                    </div>
                    <div className="Card__body-description">
                        <Typography weight="regular" type="p2" color={colors.B9} text={t('customers.noCustomer.card.description')} />
                    </div>
                </div>
                <div className="Card_footer">
                    <Button
                        label={<Typography weight="semibold" type="p3" color="inherit" text={t('customers.noCustomer.card.button.add.customer')} />}
                        color={colors.White}
                        backgroundColor={colors.C5}
                        fullWidth
                        leftIcon={() => <AddIcon />}
                        onClick={openAddCustomerModal}
                    ></Button>
                </div>
            </div>
        </div>
    );
};

const PAGE_SIZE = 8;
const MINIMUM_SEARCH_LENGTH = 2;

export const Customers = () => {
    const [customers, setCustomers] = useState<Profile[]>([]);
    const [initialTotalCustomerCount, setInitialTotalCustomerCount] = useState(0);
    const [totalCustomerCount, setTotalCustomerCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchInput, setSearchInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [results, setResults] = useState<ResultWithExtraMetadata[]>([]);
    const [expandedProfileId, setExpandedProfileId] = useState<string | null>(null);
    const { adminTeamId, adminOrganisationId } = useContext(AdminContext);
    const { shouldUpdateCustomer, onUpdateCustomerSuccess, openAddCustomerModal } = useOutletContext<{
        shouldUpdateCustomer: boolean;
        onUpdateCustomerSuccess: () => void;
        openAddCustomerModal: () => void;
    }>();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const isSearching = searchInput.length >= MINIMUM_SEARCH_LENGTH;

    const customersWithResults = customers
        .filter((_, index) => {
            if (searchInput.length < MINIMUM_SEARCH_LENGTH) return true;

            return index < PAGE_SIZE * currentPage && index >= PAGE_SIZE * (currentPage - 1);
        })
        .map(customer => {
            if (!customer.results?.length) return customer;

            const customerResults = customer.results.map(result => {
                const resultWithExtraMetadata = results.find(r => r.correlationId === result.correlationId);
                if (!resultWithExtraMetadata) return result;

                return {
                    ...result,
                    ...resultWithExtraMetadata,
                };
            });
            return {
                ...customer,
                results: customerResults,
            };
        });

    const getCustomersWithSearchQuery = useCallback(async (teamId: string, organisationId: string | undefined, query: string) => {
        try {
            setIsLoading(true);
            const {
                data: { data, totalCount },
            } = await b2bProfileApi.getProfiles(teamId, organisationId, undefined, query, undefined, true);

            setCustomers(data);
            setTotalCustomerCount(totalCount || 0);
            setCurrentPage(1);
        } catch (err) {
            const error = err as Error | AxiosError;
            setCustomers([]);
            sendException(error, false, { teamId, organisationId, query });
        } finally {
            setIsLoading(false);
        }
    }, []);

    const getCustomersWithoutSearchQuery = useCallback(
        async (teamId: string, organisationId: string | undefined, from: number, size: number) => {
            try {
                setIsLoading(true);

                const {
                    data: { data, totalCount },
                } = await b2bProfileApi.getProfiles(teamId, organisationId, (from - 1) * size, undefined, size, true);

                setCustomers(data);
                setTotalCustomerCount(totalCount || 0);
                if (initialTotalCustomerCount !== totalCount) {
                    setInitialTotalCustomerCount(totalCount || 0);
                }

                onUpdateCustomerSuccess();
            } catch (error) {
                setCustomers([]);
            } finally {
                setIsLoading(false);
                setIsInitialLoading(false);
            }
        },
        [initialTotalCustomerCount, onUpdateCustomerSuccess],
    );

    const debouncedGetCustomersWithoutSearchQuery = useMemo(() => debounce(getCustomersWithoutSearchQuery as unknown as (...args: unknown[]) => void, 300), [getCustomersWithoutSearchQuery]);
    const debouncedGetCustomersWithSearchQuery = useMemo(() => debounce(getCustomersWithSearchQuery as unknown as (...args: unknown[]) => void, 300), [getCustomersWithSearchQuery]);

    const handleRowClick = (row: object) => {
        const { profileId } = row as Profile;
        navigate(`/customers/${profileId}`);
    };

    const handlePageChange = (page: number | string) => {
        if (typeof page === 'string') return;
        setCurrentPage(page);
    };

    useEffect(() => {
        const getResults = async (teamId: string, organisationId: string | undefined) => {
            try {
                const {
                    data: { data },
                } = await b2bResultApi.getResults(undefined, teamId, organisationId);
                setResults(data);
            } catch (error) {
                console.log(error);
            }
        };

        if (adminTeamId && initialTotalCustomerCount > 0) {
            getResults(adminTeamId, adminOrganisationId);
        }
    }, [adminTeamId, adminOrganisationId, initialTotalCustomerCount]);

    useEffect(() => {
        if (adminTeamId && isSearching) {
            debouncedGetCustomersWithSearchQuery(adminTeamId, adminOrganisationId, searchInput.trim());
        }
    }, [adminTeamId, adminOrganisationId, isSearching, searchInput, debouncedGetCustomersWithSearchQuery]);

    useEffect(() => {
        if ((adminTeamId && !isSearching) || shouldUpdateCustomer) {
            debouncedGetCustomersWithoutSearchQuery(adminTeamId, adminOrganisationId, currentPage, PAGE_SIZE);
        } else if (adminOrganisationId) {
            setIsLoading(false);
            setIsInitialLoading(false);
        }
    }, [adminTeamId, adminOrganisationId, isSearching, currentPage, debouncedGetCustomersWithoutSearchQuery, shouldUpdateCustomer]);

    if (initialTotalCustomerCount === 0 && !isInitialLoading) {
        return <NoCustomerCard openAddCustomerModal={openAddCustomerModal} />;
    }

    const handleDropdownClick = (e: MouseEvent<HTMLElement>, profileId?: string) => {
        e.stopPropagation();

        if (!profileId || profileId === expandedProfileId) {
            setExpandedProfileId(null);
        } else {
            setExpandedProfileId(profileId);
        }
    };

    const columnHelper = createColumnHelper<Profile>();

    const columns = [
        columnHelper.accessor('name', {
            header: () => <Typography weight="black" type="p3" color={colors.B9} text={t('customers.table.column.name')} />,
            cell: info => <Typography weight="regular" type="p3" color={colors.B9} text={`${info.getValue()?.firstName || ''} ${info.getValue()?.lastName || ''}`} />,
        }),
        columnHelper.accessor('externalUserId', {
            header: () => <Typography weight="black" type="p3" color={colors.B9} text={t('customers.table.column.uniqueId')} />,
            cell: info => <span className="Customers__table-column--externalUserId">{info.getValue() || '-'}</span>,
        }),
        columnHelper.accessor('results', {
            header: () => <Typography weight="black" type="p3" color={colors.B9} text={t('customers.table.column.tests')} />,
            cell: info => {
                const res: ResultWithExtraMetadata[] | undefined = info.getValue();
                if (typeof res === 'undefined' || !res.length) return '-';

                const dropdownClassName = expandedProfileId === info.row.original.profileId ? 'Customers__results-dropdown Customers__results-dropdown--active' : 'Customers__results-dropdown';

                return (
                    <div className={dropdownClassName}>
                        <Button
                            label={<Typography text={t('common.test.count', { count: res.length })} type="p3" weight="regular" color={colors.B9} />}
                            buttonType="plain"
                            rightIcon={() => <ChevronDownIcon />}
                            onClick={e => handleDropdownClick(e, info.row.original.profileId)}
                        ></Button>
                        <div className="Customers__results-dropdown-menu" onClick={e => e.stopPropagation()}>
                            {res.map(result => (
                                <div className="Customers__results-dropdown-menu-item" key={result.correlationId}>
                                    <Typography weight="regular" type="p3" color={colors.B9} text={result?.kitMetadata?.sku ? ResultSku[result.kitMetadata.sku as keyof typeof ResultSku] : ''} />
                                    <TestStatusBadge status={result.resultStatus} isOld={isOldSKU(result.kitMetadata?.sku || '')} />
                                </div>
                            ))}
                        </div>
                    </div>
                );
            },
            id: 'results',
        }),
        columnHelper.accessor('health.gender', {
            header: () => <Typography weight="black" type="p3" color={colors.B9} text={t('customers.table.column.gender')} />,
            cell: info => <Typography weight="regular" type="p3" color={colors.B9} text={info.getValue() ? t(`common.${info.getValue()}`) : ''} />,
        }),
        columnHelper.accessor('team.name', {
            header: () => <Typography weight="black" type="p3" color={colors.B9} text={t('customers.table.column.clinic')} />,
            cell: info => <Typography weight="regular" type="p3" color={colors.B9} text={info.getValue() || ''} />,
        }),
        columnHelper.accessor('updatedAt', {
            header: () => <Typography weight="black" type="p3" color={colors.B9} text={t('customers.table.column.last.activity')} />,
            cell: info => <Typography weight="regular" type="p3" color={colors.B9} text={moment(info.getValue()).format('YYYY-MM-DD HH:mm')} />,
        }),
    ] as ColumnDef<object>[];

    return (
        <div className="Customers">
            <div className="Customers__header">
                <Typography weight="semibold" type="h1" color={colors.B9} text={t('customers.title')} />
            </div>
            <div className="Customers__filters">
                <InputSearch value={searchInput} onChange={e => setSearchInput(e.target.value)} onClear={() => setSearchInput('')} placeholder={t('customers.search.input.placeholder')} />
            </div>
            {isInitialLoading && <Loader />}
            {!isInitialLoading && (
                <div className="Customers__table">
                    <div className="Customers__table-title">
                        {isSearching && (
                            <Typography
                                weight="semibold"
                                type="h4"
                                color={colors.B9}
                                text={t('customers.search.customers.count', {
                                    count: initialTotalCustomerCount,
                                    customerCount: totalCustomerCount,
                                })}
                            />
                        )}
                        {!isSearching && <Typography weight="semibold" type="h4" color={colors.B9} text={t('customers.search.total.customer.count', { count: totalCustomerCount })} />}
                        <Pagination totalCount={isSearching ? totalCustomerCount : initialTotalCustomerCount} pageSize={PAGE_SIZE} currentPage={currentPage} onPageChange={handlePageChange} />
                    </div>
                    {totalCustomerCount > 0 && <Table data={customersWithResults} columns={columns} onClickRow={handleRowClick} />}
                    {totalCustomerCount === 0 && !isLoading && (
                        <div className="Customers__search-not-found">
                            <Typography text={t('customers.search.notFound.title')} weight="semibold" type="h3" color={colors.B9} />
                            <Typography text={t('customers.search.notFound.description')} weight="regular" type="p2" color={colors.B9} />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
