import './tooltip.scss';

import { Typography } from '@prenetics/prenetics-react-library';
import colors from '@theme/colors.module.scss';
import React, { ReactNode, useRef, useState } from 'react';

export const Tooltip = ({ text, children }: { text: string; children: ReactNode }) => {
    const [isVisible, setIsVisible] = useState(false);
    const targetRef = useRef<HTMLDivElement>(null);

    const showTooltip = () => {
        if (targetRef.current) {
            setIsVisible(true);
        }
    };

    const hideTooltip = () => {
        setIsVisible(false);
    };

    return (
        <div className="tooltip-container tooltip-container--medium" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
            <div ref={targetRef}>{children}</div>
            {isVisible && (
                <div className="tooltip">
                    <Typography text={text} color={colors.B9} type="caption" weight="light" />
                    <div className="tooltip-pointer" />
                </div>
            )}
        </div>
    );
};
