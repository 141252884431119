import './badge.scss';

import { Typography } from '@prenetics/prenetics-react-library/lib/Typography';
import React from 'react';

export enum BadgeVariantEnum {
    Plain = 'plain',
    Primary = 'primary',
    Success = 'success',
    Warning = 'warning',
    Error = 'error',
}

export enum BadgeShapeEnum {
    Rectangle = 'rectangle',
    Circle = 'circle',
}

interface BadgeProps {
    text: string;
    variant?: BadgeVariantEnum;
    shape?: BadgeShapeEnum;
}

export const Badge = ({ text, variant, shape }: BadgeProps) => {
    const badgeClassName = `Badge ${variant ? `Badge--${variant} ` : ''} ${shape ? `Badge--${shape}` : ''}`;
    return shape === BadgeShapeEnum.Circle ? (
        <div className={badgeClassName}>
            <Typography text={text} weight="regular" type="caption" color="" />
        </div>
    ) : (
        <span className={badgeClassName}>
            <span className="Badge__text">
                <Typography text={text} weight="regular" type="caption" color="" />
            </span>
        </span>
    );
};
