import { CountryCodeIso31661 } from '@customTypes/CountryCodeIso31661';
import { Locale } from 'locale-enum';
import { MobilePhoneLocale } from 'validator/lib/isMobilePhone';

export type PhoneCountryCode =
    | '+1'
    | '+20'
    | '+212'
    | '+230'
    | '+233'
    | '+261'
    | '+264'
    | '+267'
    | '+27'
    | '+30'
    | '+31'
    | '+32'
    | '+34'
    | '+350'
    | '+351'
    | '+352'
    | '+353'
    | '+354'
    | '+356'
    | '+357'
    | '+358'
    | '+359'
    | '+36'
    | '+370'
    | '+371'
    | '+372'
    | '+377'
    | '+380'
    | '+385'
    | '+386'
    | '+389'
    | '+39'
    | '+40'
    | '+41'
    | '+420'
    | '+421'
    | '+423'
    | '+43'
    | '+44'
    | '+45'
    | '+46'
    | '+47'
    | '+48'
    | '+49'
    | '+506'
    | '+51'
    | '+52'
    | '+54'
    | '+55'
    | '+56'
    | '+57'
    | '+58'
    | '+594'
    | '+595'
    | '+598'
    | '+60'
    | '+61'
    | '+62'
    | '+63'
    | '+64'
    | '+65'
    | '+66'
    | '+7'
    | '+81'
    | '+82'
    | '+852'
    | '+853'
    | '+886'
    | '+90'
    | '+91'
    | '+92'
    | '+961'
    | '+962'
    | '+965'
    | '+966'
    | '+968'
    | '+971'
    | '+972'
    | '+973'
    | '+974'
    | '+995'
    | '+86'
    | '+33'
    | '+597'
    | '+84';

export const phoneCountryCodes: Record<CountryCodeIso31661, PhoneCountryCode> = {
    [CountryCodeIso31661.Australia]: '+61',
    [CountryCodeIso31661.Canada]: '+1',
    [CountryCodeIso31661.HongKong]: '+852',
    [CountryCodeIso31661.Macau]: '+853',
    [CountryCodeIso31661.Malaysia]: '+60',
    [CountryCodeIso31661.NewZealand]: '+64',
    [CountryCodeIso31661.Philippines]: '+63',
    [CountryCodeIso31661.Singapore]: '+65',
    // [CountryCodeIso31661.Taiwan]: '+886',
    [CountryCodeIso31661.Thailand]: '+66',
    [CountryCodeIso31661.Uae]: '+971',
    [CountryCodeIso31661.UnitedKingdom]: '+44',
    [CountryCodeIso31661.UnitedStates]: '+1',
    [CountryCodeIso31661.Luxembourg]: '+352',
    [CountryCodeIso31661.Austria]: '+43',
    [CountryCodeIso31661.Belgium]: '+32',
    [CountryCodeIso31661.Colombia]: '+57',
    [CountryCodeIso31661.CzechRepublic]: '+420',
    [CountryCodeIso31661.Denmark]: '+45',
    [CountryCodeIso31661.Finland]: '+358',
    [CountryCodeIso31661.Germany]: '+49',
    [CountryCodeIso31661.Greece]: '+30',
    [CountryCodeIso31661.Hungary]: '+36',
    [CountryCodeIso31661.Indonesia]: '+62',
    [CountryCodeIso31661.Italy]: '+39',
    [CountryCodeIso31661.Japan]: '+81',
    // [CountryCodeIso31661.Mexico]: '+52',
    [CountryCodeIso31661.Norway]: '+47',
    [CountryCodeIso31661.Poland]: '+48',
    [CountryCodeIso31661.Portugal]: '+351',
    [CountryCodeIso31661.Romania]: '+40',
    // [CountryCodeIso31661.Russia]: '+7',
    [CountryCodeIso31661.Slovakia]: '+421',
    [CountryCodeIso31661.SouthAfrica]: '+27',
    [CountryCodeIso31661.SouthKorea]: '+82',
    [CountryCodeIso31661.Spain]: '+34',
    [CountryCodeIso31661.Sweden]: '+46',
    [CountryCodeIso31661.Switzerland]: '+41',
    [CountryCodeIso31661.Turkey]: '+90',
    [CountryCodeIso31661.Argentina]: '+54',
    [CountryCodeIso31661.Bahrain]: '+973',
    [CountryCodeIso31661.Botswana]: '+267',
    [CountryCodeIso31661.Bulgaria]: '+359',
    [CountryCodeIso31661.CaymanIslands]: '+1',
    [CountryCodeIso31661.Chile]: '+56',
    [CountryCodeIso31661.CostaRica]: '+506',
    [CountryCodeIso31661.Croatia]: '+385',
    [CountryCodeIso31661.Cyprus]: '+357',
    [CountryCodeIso31661.DominicanRepublic]: '+1',
    [CountryCodeIso31661.Egypt]: '+20',
    [CountryCodeIso31661.Estonia]: '+372',
    [CountryCodeIso31661.FrenchGuiana]: '+594',
    [CountryCodeIso31661.Georgia]: '+995',
    [CountryCodeIso31661.Ghana]: '+233',
    [CountryCodeIso31661.Gibraltar]: '+350',
    [CountryCodeIso31661.Iceland]: '+354',
    [CountryCodeIso31661.Ireland]: '+353',
    [CountryCodeIso31661.Israel]: '+972',
    [CountryCodeIso31661.Jordan]: '+962',
    [CountryCodeIso31661.Kuwait]: '+965',
    [CountryCodeIso31661.Latvia]: '+371',
    [CountryCodeIso31661.Lebanon]: '+961',
    [CountryCodeIso31661.Liechtenstein]: '+423',
    [CountryCodeIso31661.Lithuania]: '+370',
    [CountryCodeIso31661.NorthMacedonia]: '+389',
    [CountryCodeIso31661.Madagascar]: '+261',
    [CountryCodeIso31661.Malta]: '+356',
    [CountryCodeIso31661.Mauritius]: '+230',
    [CountryCodeIso31661.Monaco]: '+377',
    [CountryCodeIso31661.Morocco]: '+212',
    [CountryCodeIso31661.Namibia]: '+264',
    [CountryCodeIso31661.Netherlands]: '+31',
    [CountryCodeIso31661.Oman]: '+968',
    [CountryCodeIso31661.Pakistan]: '+92',
    [CountryCodeIso31661.Paraguay]: '+595',
    [CountryCodeIso31661.Peru]: '+51',
    [CountryCodeIso31661.Qatar]: '+974',
    [CountryCodeIso31661.SaudiArabia]: '+966',
    [CountryCodeIso31661.Slovenia]: '+386',
    // [CountryCodeIso31661.Ukraine]: '+380',
    [CountryCodeIso31661.Uruguay]: '+598',
    [CountryCodeIso31661.Venezuela]: '+58',
    // [CountryCodeIso31661.Brazil]: '+55',
    [CountryCodeIso31661.India]: '+91',
    [CountryCodeIso31661.PuertoRico]: '+1',
    // [CountryCodeIso31661.China]: '+86',
    // [CountryCodeIso31661.France]: '+33',
    [CountryCodeIso31661.Jamaica]: '+1',
    [CountryCodeIso31661.Suriname]: '+597',
    [CountryCodeIso31661.VirginIslandsUS]: '+1',
    [CountryCodeIso31661.Vietnam]: '+84',
};

type PhoneValidationLocale = MobilePhoneLocale | Locale.el_CY | Locale.en_JM | Locale.en_VI | 'any';

export const phoneValidationLocales: Record<Partial<CountryCodeIso31661>, PhoneValidationLocale> = {
    [CountryCodeIso31661.Australia]: Locale.en_AU,
    [CountryCodeIso31661.Canada]: Locale.en_CA,
    [CountryCodeIso31661.HongKong]: Locale.en_HK,
    [CountryCodeIso31661.Macau]: Locale.en_MO,
    [CountryCodeIso31661.Malaysia]: Locale.ms_MY,
    [CountryCodeIso31661.NewZealand]: Locale.en_NZ,
    [CountryCodeIso31661.Philippines]: Locale.en_PH,
    [CountryCodeIso31661.Singapore]: Locale.en_SG,
    // [CountryCodeIso31661.Taiwan]: 'any',
    [CountryCodeIso31661.Thailand]: Locale.th_TH,
    [CountryCodeIso31661.Uae]: Locale.ar_AE,
    [CountryCodeIso31661.UnitedKingdom]: Locale.en_GB,
    [CountryCodeIso31661.UnitedStates]: Locale.en_US,
    [CountryCodeIso31661.Luxembourg]: Locale.de_LU,
    [CountryCodeIso31661.Austria]: Locale.de_AT,
    [CountryCodeIso31661.Belgium]: Locale.fr_BE,
    [CountryCodeIso31661.Colombia]: Locale.es_CO,
    [CountryCodeIso31661.CzechRepublic]: Locale.cs_CZ,
    [CountryCodeIso31661.Denmark]: Locale.da_DK,
    [CountryCodeIso31661.Finland]: Locale.fi_FI,
    [CountryCodeIso31661.Germany]: Locale.de_DE,
    [CountryCodeIso31661.Greece]: Locale.el_GR,
    [CountryCodeIso31661.Hungary]: 'any',
    [CountryCodeIso31661.Indonesia]: Locale.id_ID,
    [CountryCodeIso31661.Italy]: Locale.it_IT,
    [CountryCodeIso31661.Japan]: Locale.ja_JP,
    // [CountryCodeIso31661.Mexico]: Locale.es_MX,
    [CountryCodeIso31661.Norway]: Locale.nb_NO,
    [CountryCodeIso31661.Poland]: Locale.pl_PL,
    [CountryCodeIso31661.Portugal]: Locale.pt_PT,
    [CountryCodeIso31661.Romania]: Locale.ro_RO,
    // [CountryCodeIso31661.Russia]: Locale.ru_RU,
    [CountryCodeIso31661.Slovakia]: Locale.sk_SK,
    [CountryCodeIso31661.SouthAfrica]: Locale.en_ZA,
    [CountryCodeIso31661.SouthKorea]: Locale.ko_KR,
    [CountryCodeIso31661.Spain]: Locale.es_ES,
    [CountryCodeIso31661.Sweden]: Locale.sv_SE,
    [CountryCodeIso31661.Switzerland]: Locale.de_CH,
    [CountryCodeIso31661.Turkey]: Locale.tr_TR,
    [CountryCodeIso31661.Argentina]: Locale.es_AR,
    [CountryCodeIso31661.Bahrain]: Locale.ar_BH,
    [CountryCodeIso31661.Botswana]: 'any',
    [CountryCodeIso31661.Bulgaria]: Locale.bg_BG,
    [CountryCodeIso31661.CaymanIslands]: 'any',
    [CountryCodeIso31661.Chile]: Locale.es_CL,
    [CountryCodeIso31661.CostaRica]: Locale.es_CR,
    [CountryCodeIso31661.Croatia]: 'any',
    [CountryCodeIso31661.Cyprus]: Locale.el_CY,
    [CountryCodeIso31661.DominicanRepublic]: Locale.es_DO,
    [CountryCodeIso31661.Egypt]: 'any',
    [CountryCodeIso31661.Estonia]: 'any',
    [CountryCodeIso31661.FrenchGuiana]: Locale.fr_GF,
    [CountryCodeIso31661.Georgia]: Locale.ka_GE,
    [CountryCodeIso31661.Ghana]: Locale.en_GH,
    [CountryCodeIso31661.Gibraltar]: 'any',
    [CountryCodeIso31661.Iceland]: 'any',
    [CountryCodeIso31661.Ireland]: Locale.en_IE,
    [CountryCodeIso31661.Israel]: Locale.he_IL,
    [CountryCodeIso31661.Jordan]: Locale.ar_JO,
    [CountryCodeIso31661.Kuwait]: Locale.ar_KW,
    [CountryCodeIso31661.Latvia]: Locale.lv_LV,
    [CountryCodeIso31661.Lebanon]: Locale.ar_LB,
    [CountryCodeIso31661.Liechtenstein]: 'any',
    [CountryCodeIso31661.Lithuania]: Locale.lt_LT,
    [CountryCodeIso31661.NorthMacedonia]: 'any',
    [CountryCodeIso31661.Madagascar]: 'any',
    [CountryCodeIso31661.Malta]: Locale.en_MT,
    [CountryCodeIso31661.Mauritius]: Locale.en_MU,
    [CountryCodeIso31661.Monaco]: 'any',
    [CountryCodeIso31661.Morocco]: Locale.ar_MA,
    [CountryCodeIso31661.Namibia]: 'any',
    [CountryCodeIso31661.Netherlands]: Locale.nl_NL,
    [CountryCodeIso31661.Oman]: Locale.ar_OM,
    [CountryCodeIso31661.Pakistan]: Locale.en_PK,
    [CountryCodeIso31661.Paraguay]: Locale.es_PY,
    [CountryCodeIso31661.Peru]: Locale.es_PE,
    [CountryCodeIso31661.Qatar]: 'any',
    [CountryCodeIso31661.SaudiArabia]: Locale.ar_SA,
    [CountryCodeIso31661.Slovenia]: Locale.sl_SI,
    // [CountryCodeIso31661.Ukraine]: Locale.uk_UA,
    [CountryCodeIso31661.Uruguay]: Locale.es_UY,
    [CountryCodeIso31661.Venezuela]: Locale.es_VE,
    // [CountryCodeIso31661.Brazil]: Locale.pt_BR,
    [CountryCodeIso31661.India]: Locale.en_IN,
    [CountryCodeIso31661.PuertoRico]: 'any',
    // [CountryCodeIso31661.China]: 'zh-CN',
    // [CountryCodeIso31661.France]: 'fr-FR',
    [CountryCodeIso31661.Jamaica]: Locale.en_JM,
    [CountryCodeIso31661.Suriname]: 'any',
    [CountryCodeIso31661.VirginIslandsUS]: Locale.en_VI,
    [CountryCodeIso31661.Vietnam]: Locale.vi_VN,
};

export const phoneValidationWithMinLength: { [key in CountryCodeIso31661]?: number } = {
    [CountryCodeIso31661.Croatia]: 13,
    [CountryCodeIso31661.Egypt]: 13,
    [CountryCodeIso31661.Monaco]: 13,
    [CountryCodeIso31661.Qatar]: 12,
    [CountryCodeIso31661.Gibraltar]: 12,
    [CountryCodeIso31661.Iceland]: 11,
    [CountryCodeIso31661.Liechtenstein]: 11,
    [CountryCodeIso31661.NorthMacedonia]: 12,
    [CountryCodeIso31661.Suriname]: 10,
    [CountryCodeIso31661.Hungary]: 11,
};

export const phoneNumberFormatRegx: { [key in PhoneCountryCode]?: { searchValue: string | RegExp; replaceValue: string } } = {
    ['+82']: { searchValue: /(.{4})/g, replaceValue: '$1' }, // removes spaces between number
};

export function getPhoneCountryCode(country: CountryCodeIso31661): PhoneCountryCode {
    return phoneCountryCodes[country];
}
