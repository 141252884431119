import './userAvatar.scss';

import { Typography } from '@prenetics/prenetics-react-library/lib/Typography';
import colors from '@theme/colors.module.scss';
import React from 'react';

type Size = 'small' | 'medium' | 'large';

export const UserAvatar = ({ name, imageUrl, size = 'medium', backgroundColor = colors.C5 }: { name: string; imageUrl?: string; size?: Size; backgroundColor?: string }) => {
    const firstLetter = name.charAt(0).toUpperCase();
    const className = `UserAvatar ${size}`;
    const typographyType = size === 'small' ? 'caption' : 'h4';

    return (
        <div className={className} style={{ backgroundColor }}>
            {imageUrl && <img src={imageUrl} alt={name} />}
            {!imageUrl && <Typography color={colors.White} text={firstLetter} type={typographyType} weight="black" />}
        </div>
    );
};
