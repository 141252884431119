import { LanguageSelect } from '@prenetics/prenetics-react-library/lib/LanguageSelect';
import { LanguagesWebApp } from '@services/i18n';
import colors from '@theme/colors.module.scss';
import { changeLanguage } from 'i18next';
import React from 'react';

export const CustomLanguageSelect = ({ lang }: { lang: string }) => {
    const handleSelectLanguage = (lng: string) => {
        changeLanguage(lng);
    };

    return <LanguageSelect theme="dropdown" lang={lang} languages={LanguagesWebApp} itemTextColor={colors.B6} clickHandler={handleSelectLanguage} />;
};
