import './testDetail.scss';

import ChevronLeftC5 from '@assets/images/ChevronLeftC5.svg?react';
import { Table } from '@components/Table';
import { TestStatusBadge } from '@components/TestStatusBadge';
import { API_BASE_URL } from '@config';
import type { Ethnicity } from '@customTypes/Ethnicity';
import { Button } from '@prenetics/prenetics-react-library/lib/Button';
import { Dropdown } from '@prenetics/prenetics-react-library/lib/DropDown';
import { Loader } from '@prenetics/prenetics-react-library/lib/Loader';
import { Typography } from '@prenetics/prenetics-react-library/lib/Typography';
import { useAuth } from '@prenetics/react-context-provider';
import type { ResultWithExtraMetadata, ResultWithExtraMetadataAllOfResultHistory } from '@services/api/b2b';
import { Profile, ProfileApiFactory, ResultApiFactory, ResultStatus } from '@services/api/b2b';
import type { ColumnDef } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import colors from '@theme/colors.module.scss';
import { barcodeFormat } from '@util/barcode';
import { isOldSKU } from '@util/result';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

const b2bProfileApi = ProfileApiFactory(undefined, API_BASE_URL + '/b2b');
const b2bResultApi = ResultApiFactory(undefined, API_BASE_URL + '/b2b');

const initialState: Profile = {
    profileId: '',
    externalId: '',
    externalUserId: '',
    name: {
        firstName: '',
        lastName: '',
    },
    health: {
        gender: '',
        ethnicity: '',
        dob: '',
        height: null,
        heightUnit: undefined,
        weight: null,
        weightUnit: undefined,
    },
    email: [],
    phone: [],
    createdAt: '',
    updatedAt: '',
    version: 0,
    results: [],
};

const REPORT_ID = '00';
const fileType = 'pdf';

type LanguageType = 'en-HK' | 'zh-Hans-HK' | 'zh-Hant-HK' | 'th-TH' | 'ja-JP' | undefined;

const DEFAULT_LANGUAGE = 'en-HK';

const languageOptions = [
    {
        id: 'en-HK',
        name: 'en-HK',
    },
    {
        id: 'zh-Hans-HK',
        name: 'zh-Hans-HK',
    },
    {
        id: 'zh-Hant-HK',
        name: 'zh-Hant-HK',
    },
    {
        id: 'th-TH',
        name: 'th-TH',
    },
    {
        id: 'ja-JP',
        name: 'ja-JP',
    },
];

export const TestDetail = () => {
    const { token } = useAuth();
    const [language, setLanguage] = useState<LanguageType>(DEFAULT_LANGUAGE);
    const [isLoading, setIsLoading] = useState(true);
    const [isDownloading, setIsDownloading] = useState(false);
    const [customerDetail, setCustomerDetail] = useState(initialState);
    const [result, setResult] = useState<ResultWithExtraMetadata | null>(null);
    const { testId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { ethnicityList } = useOutletContext<{
        ethnicityList: Ethnicity[];
    }>();

    const fullName = useMemo(() => `${customerDetail.name?.firstName || ''} ${customerDetail.name?.lastName || ''}`, [customerDetail.name?.firstName, customerDetail.name?.lastName]);
    const ethnicity = useMemo(() => {
        const item = ethnicityList.find(e => e.code === customerDetail.health?.ethnicity);
        return item?.description || '';
    }, [customerDetail.health?.ethnicity, ethnicityList]);
    const testDetailClassName = useMemo(() => {
        if (result?.resultStatus === ResultStatus.ReportReleased) {
            return 'TestDetail__detail TestDetail__detail--two-column';
        }
        return 'TestDetail__detail';
    }, [result?.resultStatus]);
    const iFrameUrl = useMemo(() => {
        if (customerDetail.externalId && customerDetail.owner && token) {
            return `${API_BASE_URL}/report/report/home?bearerToken=${token}&profileId=${customerDetail.externalId}&xOwner=${customerDetail.owner}&language=${language}&identifier=b2b`;
        }
        return undefined;
    }, [customerDetail.externalId, token, language, customerDetail.owner]);

    const columnHelper = createColumnHelper<ResultWithExtraMetadataAllOfResultHistory>();

    const columns = [
        columnHelper.accessor('datetime', {
            header: () => <Typography weight="black" type="p3" color={colors.B9} text={t('customerDetail.test.activity.table.column.time')} />,
            cell: info => <Typography weight="regular" type="p3" color={colors.B9} text={moment(info.getValue()).format('YYYY-MM-DD HH:mm:ss')} />,
        }),
        columnHelper.accessor('status', {
            header: () => <Typography weight="black" type="p3" color={colors.B9} text={t('customerDetail.test.activity.table.column.description')} />,
            cell: info => <Typography weight="black" type="p3" color={colors.B9} text={t(`common.test.status.${info.getValue().toLowerCase()}`)} />,
        }),
        columnHelper.accessor('actor', {
            header: () => <Typography weight="black" type="p3" color={colors.B9} text={t('customerDetail.test.activity.table.column.actor')} />,
            cell: info => <Typography weight="regular" type="p3" color={colors.B9} text={info.getValue() || ''} />,
        }),
    ] as ColumnDef<object>[];

    const handleBackClick = () => {
        if (result?.profileId) {
            navigate(`/customers/${result?.profileId}`);
        } else if (result?.profile?.profileId) {
            navigate(`/customers/${result.profile.profileId}`);
        }
    };

    const getReport = async () => {
        if (!result?.resultId) return;

        try {
            setIsDownloading(true);

            const { data } = await b2bResultApi.getReportById(result.resultId, REPORT_ID, fileType, language || 'en-HK', {
                responseType: 'arraybuffer',
            });

            downloadPDF(data as ArrayBuffer);
        } catch (error) {
            setIsDownloading(false);
        }
    };

    const downloadPDF = (file: ArrayBuffer) => {
        const blob = new Blob([file], { type: 'application/pdf' });
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', `${fullName}_Report_${moment().format('YYYYMMDD')}.pdf`);
        document.body.appendChild(link);
        link.click();

        link.remove();
        window.URL.revokeObjectURL(url);
        setIsDownloading(false);
    };

    const handleIFrameBackClick = () => {
        window.history.back();
    };

    useEffect(() => {
        const getProfile = async (profileId: string) => {
            try {
                const { data } = await b2bProfileApi.getProfileById(profileId);
                setCustomerDetail(data);
            } catch (error) {
                navigate('/customers');
            }
        };

        const getResult = async (resultId: string) => {
            try {
                const { data } = await b2bResultApi.getResult(resultId);
                setResult(data);

                if (data.profileId) {
                    getProfile(data.profileId);
                } else if (data.profile?.profileId) {
                    getProfile(data.profile.profileId);
                }
            } catch (error) {
                setResult(null);
            } finally {
                setIsLoading(false);
            }
        };

        if (testId) {
            getResult(testId);
        }
    }, [testId, navigate]);

    return (
        <div className="TestDetail">
            <div className={testDetailClassName}>
                {isLoading && <Loader />}
                {!isLoading && (
                    <>
                        <div className="TestDetail__detail-header">
                            <Button
                                label={<Typography text={t('common.back')} color={colors.C5} type="p3" weight="black" />}
                                buttonType="plain"
                                color={colors.C5}
                                leftIcon={() => <ChevronLeftC5 />}
                                onClick={handleBackClick}
                            ></Button>
                        </div>
                        <div className="TestDetail__detail-title">
                            <Typography text={fullName} color={colors.B9} type="h1" weight="semibold" />
                        </div>
                        <div className="TestDetail__detail-column">
                            <div className="TestDetail__test-detail">
                                <Typography text={t('customerDetail.test.table.column.status')} color={colors.B9} type="h3" weight="semibold" />
                                <Typography text={t('customerDetail.test.table.column.barcode')} color={colors.B9} type="h3" weight="semibold" />
                                <div>
                                    <TestStatusBadge status={result?.resultStatus} isOld={isOldSKU(result?.kitMetadata?.sku || '')} />
                                </div>
                                <div>{barcodeFormat(result?.kitMetadata?.barcode || '')}</div>
                            </div>

                            {result?.resultHistory?.length && (
                                <div className="TestDetail__activity">
                                    <div className="TestDetail__activity-title">
                                        <Typography text={t('customerDetail.title.status.log')} color={colors.B9} type="h3" weight="semibold" />
                                    </div>
                                    <div className="TestDetail__activity-log">
                                        <Table data={result.resultHistory} columns={columns} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}
                {result?.resultStatus === ResultStatus.ReportReleased && (
                    <div className="TestDetail__detail-column">
                        <div className="TestDetail__result-title">
                            <Typography text="Results" color={colors.B9} type="h3" weight="semibold" />
                            <div className="TestDetail__result-language-dropdown">
                                <Dropdown
                                    dropDownList={languageOptions}
                                    customDefaultValue={{ id: DEFAULT_LANGUAGE, name: DEFAULT_LANGUAGE }}
                                    onItemChange={item => {
                                        if (typeof item !== 'string') {
                                            setLanguage(item.name as LanguageType);
                                        }
                                    }}
                                    onCreateNewItem={() => null}
                                    onClickArrow={() => null}
                                    value={language}
                                />
                            </div>
                            <Button label="Download PDF" buttonType="plain" borderColor={colors.C5} color={colors.C5} onClick={getReport} isLoading={isDownloading} />
                        </div>
                        {iFrameUrl && (
                            <div className="TestDetail__iframe-wrapper">
                                <div>
                                    <Button label={<ChevronLeftC5 />} buttonType="plain" borderColor={colors.C5} color={colors.C5} onClick={handleIFrameBackClick} />
                                </div>
                                <iframe title="Result" src={iFrameUrl} />
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className="TestDetail__info">
                <div className="TestDetail__info-group">
                    <div className="TestDetail__info-title">
                        <Typography text={t('customers.customerDetail.title.info')} color={colors.B9} type="p3" weight="semibold" />
                    </div>
                    <div className="TestDetail__info-field">
                        <Typography text={t('customers.addCustomer.modal.field.firstName')} color={colors.B6} type="caption" weight="semibold" />
                        <Typography text={customerDetail?.name?.firstName || ''} color={colors.B9} type="p3" weight="regular" />
                    </div>
                    <div className="TestDetail__info-field">
                        <Typography text={t('customers.addCustomer.modal.field.lastName')} color={colors.B6} type="caption" weight="semibold" />
                        <Typography text={customerDetail?.name?.lastName || ''} color={colors.B9} type="p3" weight="regular" />
                    </div>
                    <div className="TestDetail__info-field">
                        <Typography text={t('customers.addCustomer.modal.field.gender')} color={colors.B6} type="caption" weight="semibold" />
                        <Typography text={customerDetail?.health?.gender ? t(`common.${customerDetail?.health.gender}`) : ''} color={colors.B9} type="p3" weight="regular" />
                    </div>
                    <div className="TestDetail__info-field">
                        <Typography text={t('customers.addCustomer.modal.field.ethnicity')} color={colors.B6} type="caption" weight="semibold" />
                        <Typography text={ethnicity} color={colors.B9} type="p3" weight="regular" />
                    </div>
                    <div className="TestDetail__info-field">
                        <Typography text={t('customers.addCustomer.modal.field.dob')} color={colors.B6} type="caption" weight="semibold" />
                        <Typography text={customerDetail?.health?.dob ? moment(customerDetail?.health.dob).format('YYYY-MM-DD') : ''} color={colors.B9} type="p3" weight="regular" />
                    </div>
                    <div className="TestDetail__info-field">
                        <div>
                            <Typography text={t('customers.addCustomer.modal.field.height')} color={colors.B6} type="caption" weight="semibold" />
                        </div>
                        <div className="TestDetail__info-field-content">
                            <Typography
                                text={customerDetail?.health?.height ? `${customerDetail?.health?.height} ${customerDetail.health?.heightUnit}` : ''}
                                color={colors.B9}
                                type="p3"
                                weight="regular"
                            />
                        </div>
                    </div>
                    <div className="TestDetail__info-field">
                        <Typography text={t('customers.addCustomer.modal.field.weight')} color={colors.B6} type="caption" weight="semibold" />
                        <Typography
                            text={customerDetail?.health?.weight ? `${customerDetail?.health?.weight} ${customerDetail.health?.weightUnit}` : ''}
                            color={colors.B9}
                            type="p3"
                            weight="regular"
                        />
                    </div>
                    <div className="TestDetail__info-field">
                        <Typography text={t('customers.customerDetail.title.customerId')} color={colors.B6} type="caption" weight="semibold" />
                        <div className="TestDetail__info-externalUserId--expand">
                            <Typography text={customerDetail?.externalUserId || ''} color={colors.B9} type="p3" weight="regular" />
                        </div>
                    </div>
                </div>
                <div className="TestDetail__info-group">
                    <div className="TestDetail__info-title">
                        <Typography text={t('customers.customerDetail.title.contact')} color={colors.B9} type="p3" weight="semibold" />
                    </div>
                    <div className="TestDetail__info-field">
                        <div>
                            <Typography text={t('customers.addCustomer.modal.field.email')} color={colors.B6} type="caption" weight="semibold" />
                        </div>
                        {customerDetail?.email?.map(item => {
                            return (
                                <div className="TestDetail__info-field-content" key={item.emailId}>
                                    <Typography text={item.email || ''} color={colors.B9} type="p3" weight="regular" />
                                </div>
                            );
                        })}
                    </div>
                    <div className="TestDetail__info-field">
                        <div>
                            <Typography text={t('customers.customerDetail.title.phone')} color={colors.B6} type="caption" weight="semibold" />
                        </div>
                        {customerDetail?.phone?.map(item => {
                            return (
                                <div className="TestDetail__info-field-content" key={item.phoneId}>
                                    <Typography text={`${item.countryCode}${item.number}` || ''} color={colors.B9} type="p3" weight="regular" />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};
