import './updateCustomerModal.scss';

import CloseIcon from '@assets/images/close.svg?react';
import { AddCustomerErrorModal } from '@components/AddCustomerErrorModal';
import { ConfirmModal } from '@components/ConfirmModal';
import { FormInput } from '@components/FormInput';
import { FormInputWithIcon } from '@components/FormInputWithIcon';
import { API_BASE_URL, PHONE_NUMBER_MAX_LENGTH } from '@config';
import { CountryCodeIso31661 } from '@customTypes/CountryCodeIso31661';
import type { Ethnicity } from '@customTypes/Ethnicity';
import { PhoneCountryCode, phoneCountryCodes } from '@customTypes/PhoneCountryCode';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@prenetics/prenetics-react-library/lib/Button';
import { DatePicker } from '@prenetics/prenetics-react-library/lib/DatePicker';
import { Dropdown } from '@prenetics/prenetics-react-library/lib/DropDown';
import { Modal } from '@prenetics/prenetics-react-library/lib/Modal';
import { Typography } from '@prenetics/prenetics-react-library/lib/Typography';
import type { HealthUpsertGenderEnum, HeightUnit, Profile, UpdateProfileRequest, WeightUnit } from '@services/api/b2b';
import { ProfileApiFactory } from '@services/api/b2b';
import colors from '@theme/colors.module.scss';
import moment from 'moment';
import type { ChangeEvent } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import isMobilePhone from 'validator/lib/isMobilePhone';
import * as yup from 'yup';

interface UpdateProfileRequestSchema {
    firstName: string;
    lastName: string;
    dob: string;
    gender: string;
    ethnicity: string;
    heightValue: string;
    weightValue: string;
    externalUserId?: string;
    email: string;
    phoneCountryCode: string;
    phoneNumber: string;
}

const personalDetailSchema = yup
    .object({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        weightValue: yup
            .string()
            .required()
            .test('weightValue', 'Weight must be between 10 and 800', value => {
                if (value) {
                    const weightValue = parseFloat(value);
                    return weightValue >= 10 && weightValue <= 800;
                }
                return false;
            }),
        heightValue: yup
            .string()
            .required()
            .test('heightValue', 'Height must be between 50 and 300', value => {
                if (value) {
                    const heightValue = parseInt(value);
                    return heightValue >= 50 && heightValue <= 300;
                }
                return false;
            }),
        dob: yup.string().required(),
        ethnicity: yup.string().required(),
        gender: yup.string().required(),
        externalUserId: yup.string(),
        email: yup.string().email().required(),
        phoneCountryCode: yup.string().required(),
        phoneNumber: yup
            .string()
            .required()
            .test('phoneNumber', 'Phone number invalid', (value, context) => isMobilePhone(`${context.parent.phoneCountryCode}${value}`) && value.length <= PHONE_NUMBER_MAX_LENGTH),
    })
    .required();

const genderOptions = [
    { id: 'male', name: 'Male' },
    { id: 'female', name: 'Female' },
];

const filteredPhoneCountryCodes = [...new Set(Object.values(phoneCountryCodes))];
const phoneCountryCodeOptions = filteredPhoneCountryCodes
    .map((value, i) => {
        return {
            id: i.toString(),
            name: value as PhoneCountryCode,
        };
    })
    .sort((a, b) => {
        if (a.name === phoneCountryCodes[CountryCodeIso31661.HongKong]) {
            return -1;
        } else if (b.name === phoneCountryCodes[CountryCodeIso31661.HongKong]) {
            return 1;
        } else {
            return a.name.localeCompare(b.name);
        }
    });

const datePickerFormat = 'DD/MMM/YYYY';

const b2bProfileApi = ProfileApiFactory(undefined, API_BASE_URL + '/b2b');

const mapProfileToPersonalDetail = (profile: Profile): UpdateProfileRequestSchema => {
    return {
        firstName: profile?.name?.firstName || '',
        lastName: profile?.name?.lastName || '',
        dob: profile?.health?.dob || '',
        gender: profile?.health?.gender || '',
        ethnicity: profile?.health?.ethnicity || '',
        heightValue: profile?.health?.height ? profile.health.height.toString() : '',
        weightValue: profile?.health?.weight || '',
        externalUserId: profile?.externalUserId || '',
        email: profile?.email && profile.email.length > 0 ? profile.email[0].email : '',
        phoneCountryCode: profile?.phone && profile.phone.length > 0 ? profile.phone[0].countryCode : '',
        phoneNumber: profile?.phone && profile.phone.length > 0 ? profile.phone[0].number : '',
    };
};

export const UpdateCustomerModal = ({
    isOpen,
    closeModal,
    ethnicityList,
    initialPersonalDetail,
    onSuccess,
}: {
    isOpen: boolean;
    closeModal: () => void;
    ethnicityList: Ethnicity[];
    initialPersonalDetail: Profile;
    onSuccess: () => void;
}) => {
    const [isAddCustomerErrorModalOpen, setIsAddCustomerErrorModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();
    const {
        control,
        trigger: triggerPersonalDetail,
        formState: personalDetailFormState,
        getValues: getPersonalDetailValues,
        setValue: setPersonalDetailValue,
        reset: resetPersonalDetailForm,
    } = useForm<UpdateProfileRequestSchema>({
        resolver: yupResolver(personalDetailSchema),
        defaultValues: mapProfileToPersonalDetail(initialPersonalDetail),
    });

    const ethnicityOptions = ethnicityList.map(item => ({
        id: item.code,
        name: item.description,
    }));

    const isExternalUserIdInvalidLengthError = useMemo(() => {
        return (
            personalDetailFormState.dirtyFields.externalUserId &&
            !!personalDetailFormState.defaultValues?.externalUserId &&
            ((getPersonalDetailValues('externalUserId')?.length || 0) < 3 || (getPersonalDetailValues('externalUserId')?.length || 0) > 30)
        );
    }, [personalDetailFormState, getPersonalDetailValues]);

    const isPersonalDetailFormValid = useMemo(() => {
        // If you want to subscribe to formState via useEffect, make sure that you place the entire formState in the optional array.
        return Object.keys(personalDetailFormState.errors).length === 0 && !isExternalUserIdInvalidLengthError;
    }, [personalDetailFormState, isExternalUserIdInvalidLengthError]);

    const isDoneButtonDisabled = useMemo(() => {
        return !personalDetailFormState.isDirty || !isPersonalDetailFormValid;
    }, [personalDetailFormState, isPersonalDetailFormValid]);

    const initialize = useCallback(() => {
        resetPersonalDetailForm();

        const initialValues = mapProfileToPersonalDetail(initialPersonalDetail);
        Object.keys(initialValues).forEach(key => {
            setPersonalDetailValue(key as keyof UpdateProfileRequestSchema, initialValues[key as keyof UpdateProfileRequestSchema], { shouldDirty: false, shouldValidate: false });
        });
    }, [initialPersonalDetail, resetPersonalDetailForm, setPersonalDetailValue]);

    const handleDoneClick = async () => {
        const res = await triggerPersonalDetail();
        if (!res) return;

        onSubmit({
            ...getPersonalDetailValues(),
        });
    };

    const handleCloseModal = () => {
        if (personalDetailFormState.isDirty) {
            setIsConfirmModalOpen(true);
        } else {
            closeModal();
        }
    };

    const handleHeightInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === '') {
            setPersonalDetailValue('heightValue', e.target.value, { shouldDirty: true, shouldValidate: true });
        } else if (Number(e.target.value)) {
            const flooredValue = Math.floor(parseFloat(e.target.value));
            setPersonalDetailValue('heightValue', flooredValue.toString(), { shouldDirty: true, shouldValidate: true });
        }
    };

    const handleWeightInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === '') {
            setPersonalDetailValue('weightValue', e.target.value, { shouldDirty: true, shouldValidate: true });
        } else if (Number(e.target.value)) {
            setPersonalDetailValue('weightValue', e.target.value, { shouldDirty: true, shouldValidate: true });
        }
    };

    const getFormatData = (data: UpdateProfileRequestSchema): UpdateProfileRequest => {
        const formData: Partial<UpdateProfileRequest> = {};

        if (initialPersonalDetail.email && initialPersonalDetail.email.length && personalDetailFormState.dirtyFields.email) {
            formData.email = {
                emailId: initialPersonalDetail.email[0].emailId,
                value: data.email,
            };
        }
        if (initialPersonalDetail.phone && initialPersonalDetail.phone.length && (personalDetailFormState.dirtyFields.phoneCountryCode || personalDetailFormState.dirtyFields.phoneNumber)) {
            formData.phone = {
                phoneId: initialPersonalDetail.phone[0].phoneId,
                number: data.phoneNumber,
                countryCode: data.phoneCountryCode,
            };
        }
        if (personalDetailFormState.dirtyFields.firstName || personalDetailFormState.dirtyFields.lastName) {
            formData.name = {
                firstName: data.firstName,
                lastName: data.lastName,
            };
        }
        if (personalDetailFormState.dirtyFields.externalUserId) {
            formData.externalUserId = data.externalUserId;
        }
        if (personalDetailFormState.dirtyFields.dob) {
            formData.health = {
                ...formData.health,
                dob: moment(data.dob, datePickerFormat).format('YYYY-MM-DD'),
            };
        }
        if (personalDetailFormState.dirtyFields.gender) {
            formData.health = {
                ...formData.health,
                gender: data.gender as HealthUpsertGenderEnum,
            };
        }
        if (personalDetailFormState.dirtyFields.ethnicity) {
            formData.health = {
                ...formData.health,
                ethnicity: data.ethnicity,
            };
        }
        if (personalDetailFormState.dirtyFields.heightValue) {
            formData.health = {
                ...formData.health,
                height: {
                    unit: 'cm' as HeightUnit,
                    value: data.heightValue,
                },
            };
        }
        if (personalDetailFormState.dirtyFields.weightValue) {
            formData.health = {
                ...formData.health,
                weight: {
                    unit: 'kg' as WeightUnit,
                    value: parseFloat(data.weightValue).toFixed(1),
                },
            };
        }

        return formData;
    };

    const onSubmit = async (data: UpdateProfileRequestSchema) => {
        try {
            setIsLoading(true);
            if (initialPersonalDetail.profileId) {
                await b2bProfileApi.updateProfile(initialPersonalDetail.profileId, getFormatData(data));
            }
            onSuccess();
            closeModal();
        } catch (error) {
            setIsAddCustomerErrorModalOpen(true);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!isOpen) {
            initialize();
        }
    }, [initialize, isOpen]);

    return (
        <>
            <div className="AddCustomerModal">
                <Modal isOpen={isOpen} handleModalOpenClose={handleCloseModal} borderRadius="0.5rem">
                    <>
                        <div className="AddCustomerModal__header">
                            <Typography color={colors.B9} text={t('customers.updateCustomer.modal.title')} type="h3" weight="semibold" />
                            <Button label="" leftIcon={() => <CloseIcon />} buttonType="secondary" onClick={handleCloseModal} />
                        </div>
                        <div className="AddCustomerModal__body">
                            <form id="add-customer-form" className="AddCustomerModal__form" onSubmit={e => e.preventDefault()}>
                                <div className="AddCustomerModal__form-body AddCustomerModal__form-step-0">
                                    <div className="AddCustomerModal__form-row">
                                        <FormInput
                                            id="first-name"
                                            name="firstName"
                                            labelcomponent={<Typography text={t('customers.addCustomer.modal.field.firstName')} color={colors.B7} type="p3" weight="regular" />}
                                            control={control as unknown as Control<FieldValues>}
                                            onChange={e => setPersonalDetailValue('firstName', e.target.value, { shouldDirty: true, shouldValidate: true })}
                                            isError={!!personalDetailFormState.errors?.firstName?.message}
                                        />
                                        <FormInput
                                            id="last-name"
                                            name="lastName"
                                            labelcomponent={<Typography text={t('customers.addCustomer.modal.field.lastName')} color={colors.B7} type="p3" weight="regular" />}
                                            control={control as unknown as Control<FieldValues>}
                                            onChange={e => setPersonalDetailValue('lastName', e.target.value, { shouldDirty: true, shouldValidate: true })}
                                            isError={!!personalDetailFormState.errors?.lastName?.message}
                                        />
                                    </div>
                                    <div className="AddCustomerModal__form-row">
                                        <div>
                                            <FormInput
                                                id="email"
                                                name="email"
                                                labelcomponent={<Typography text={t('customers.addCustomer.modal.field.email')} color={colors.B7} type="p3" weight="regular" />}
                                                control={control as unknown as Control<FieldValues>}
                                                onChange={e => setPersonalDetailValue('email', e.target.value, { shouldValidate: true, shouldDirty: true })}
                                                isError={!!personalDetailFormState.errors?.email?.message}
                                            />
                                        </div>
                                        <div className="AddCustomerModal__form-row--phone">
                                            <Dropdown
                                                customDefaultValue={phoneCountryCodeOptions.find(code => code.name === getPersonalDetailValues('phoneCountryCode'))}
                                                dropDownList={phoneCountryCodeOptions}
                                                inputLabel={<Typography text={t('customers.addCustomer.modal.field.countryCode')} color={colors.B7} type="p3" weight="regular" />}
                                                onItemChange={item => {
                                                    if (typeof item !== 'string') {
                                                        setPersonalDetailValue('phoneCountryCode', item.name, { shouldDirty: true, shouldValidate: true });
                                                    }
                                                    triggerPersonalDetail();
                                                }}
                                                onCreateNewItem={() => null}
                                                onClickArrow={() => null}
                                                isError={!!personalDetailFormState.errors?.phoneCountryCode?.message}
                                            />
                                            <FormInput
                                                id="contact-number"
                                                name="phoneNumber"
                                                labelcomponent={<Typography text={t('customers.addCustomer.modal.field.contactNumber')} color={colors.B7} type="p3" weight="regular" />}
                                                control={control as unknown as Control<FieldValues>}
                                                onChange={e => setPersonalDetailValue('phoneNumber', e.target.value, { shouldValidate: true, shouldDirty: true })}
                                                isError={!!personalDetailFormState.errors?.phoneNumber?.message}
                                                errormessage={personalDetailFormState.errors?.phoneNumber?.message ? t('customers.addCustomer.modal.contactNumber.invalid') : ''}
                                            />
                                        </div>
                                    </div>
                                    <div className="AddCustomerModal__form-row">
                                        <DatePicker
                                            id="dob"
                                            name="dob"
                                            defaultValue={moment(getPersonalDetailValues('dob'), 'YYYY-MM-DD').format(datePickerFormat)}
                                            labelcomponent={<Typography text={t('customers.addCustomer.modal.field.dob')} color={colors.B7} type="p3" weight="regular" />}
                                            format={datePickerFormat}
                                            themeColor={colors.C5}
                                            handlechange={value => setPersonalDetailValue('dob', value, { shouldDirty: true, shouldValidate: true })}
                                            isError={!!personalDetailFormState.errors?.dob?.message}
                                        />
                                    </div>
                                    <div className="AddCustomerModal__form-row">
                                        <Dropdown
                                            inputLabel={<Typography text={t('customers.addCustomer.modal.field.gender')} color={colors.B7} type="p3" weight="regular" />}
                                            customDefaultValue={genderOptions.find(gender => gender.id === getPersonalDetailValues('gender'))}
                                            dropDownList={genderOptions}
                                            onItemChange={item => {
                                                if (typeof item !== 'string') {
                                                    setPersonalDetailValue('gender', item.id as HealthUpsertGenderEnum, { shouldDirty: true, shouldValidate: true });
                                                } else {
                                                    setPersonalDetailValue('gender', item as HealthUpsertGenderEnum, { shouldDirty: true, shouldValidate: true });
                                                }
                                                triggerPersonalDetail('gender');
                                            }}
                                            onCreateNewItem={() => null}
                                            onClickArrow={() => null}
                                            isError={!!personalDetailFormState.errors?.gender?.message}
                                        />
                                        <Dropdown
                                            inputLabel={<Typography text={t('customers.addCustomer.modal.field.ethnicity')} color={colors.B7} type="p3" weight="regular" />}
                                            dropDownList={ethnicityOptions}
                                            customDefaultValue={ethnicityOptions.find(ethnicity => ethnicity.id === getPersonalDetailValues('ethnicity'))}
                                            onItemChange={item => {
                                                if (typeof item !== 'string') {
                                                    setPersonalDetailValue('ethnicity', item.id, { shouldDirty: true, shouldValidate: true });
                                                }
                                                triggerPersonalDetail('ethnicity');
                                            }}
                                            onCreateNewItem={() => null}
                                            onClickArrow={() => null}
                                            isError={!!personalDetailFormState.errors?.ethnicity?.message}
                                        />
                                    </div>
                                    <div className="AddCustomerModal__form-row">
                                        <FormInputWithIcon
                                            id="height"
                                            name="heightValue"
                                            placeholder={t('customers.addCustomer.modal.field.height')}
                                            Icon={<Typography text="cm" color={colors.B7} type="p3" weight="regular" />}
                                            control={control as unknown as Control<FieldValues>}
                                            onChange={handleHeightInputChange}
                                            isError={!!personalDetailFormState.errors?.heightValue?.message}
                                        />
                                        <FormInputWithIcon
                                            id="weight"
                                            name="weightValue"
                                            placeholder={t('customers.addCustomer.modal.field.weight')}
                                            Icon={<Typography text="kg" color={colors.B7} type="p3" weight="regular" />}
                                            control={control as unknown as Control<FieldValues>}
                                            onChange={handleWeightInputChange}
                                            isError={!!personalDetailFormState.errors?.weightValue?.message}
                                        />
                                    </div>
                                    <div className="AddCustomerModal__form-row">
                                        <FormInput
                                            id="unique-id"
                                            name="externalUserId"
                                            placeholder={t('customers.addCustomer.modal.field.uniqueId')}
                                            control={control as unknown as Control<FieldValues>}
                                            onChange={e => setPersonalDetailValue('externalUserId', e.target.value, { shouldDirty: true, shouldValidate: true })}
                                            isError={!!personalDetailFormState.errors?.externalUserId?.message || isExternalUserIdInvalidLengthError}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="AddCustomerModal__footer">
                            <div className="AddCustomerModal__footer--end-block">
                                {!isPersonalDetailFormValid && <Typography color={colors.ES5} text={t('customers.addCustomer.modal.message.required')} type="p3" weight="regular" />}
                                <Button
                                    label={t('customers.addCustomer.modal.button.done')}
                                    buttonType="primary"
                                    backgroundColor={colors.C5}
                                    onClick={handleDoneClick}
                                    disable={isDoneButtonDisabled}
                                    isLoading={isLoading}
                                />
                            </div>
                        </div>
                    </>
                </Modal>
            </div>
            <AddCustomerErrorModal isOpen={isAddCustomerErrorModalOpen} closeModal={() => setIsAddCustomerErrorModalOpen(false)} />
            <ConfirmModal isOpen={isConfirmModalOpen} closeModal={() => setIsConfirmModalOpen(false)} onConfirm={closeModal} />
        </>
    );
};
