import { Routes } from '@components/Routes';
import { API_BASE_URL } from '@config';
import { AuthProvider } from '@prenetics/react-context-provider';
import axios from 'axios';
import React from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';

axios.defaults.baseURL = API_BASE_URL;

const App = () => {
    const [{ token }] = useCookies(['token']);

    return (
        <CookiesProvider>
            <AuthProvider defaults={{ token }}>
                <Routes />
            </AuthProvider>
        </CookiesProvider>
    );
};

export default App;
