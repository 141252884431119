import './sidebar.scss';

import AddIcon from '@assets/images/add.svg?react';
import AlertIcon from '@assets/images/alert.svg?react';
import CalendarIcon from '@assets/images/calendar.svg?react';
import CircleDNALogo from '@assets/images/DNAProfessional.png';
import LogoutIcon from '@assets/images/logout.svg?react';
import ProfileIcon from '@assets/images/profile.svg?react';
import ReportIcon from '@assets/images/report.svg?react';
import TestIcon from '@assets/images/test.svg?react';
import { Badge, BadgeShapeEnum, BadgeVariantEnum } from '@components/Badge';
import { CustomLanguageSelect } from '@components/CustomLanguageSelect';
import { LazyLoadImageComponent } from '@components/LazyLoadImage';
import { UserAvatar } from '@components/UserAvatar';
import { API_BASE_URL } from '@config';
import { ROUTES } from '@customTypes/Routes';
import { Button } from '@prenetics/prenetics-react-library/lib/Button';
import { Typography } from '@prenetics/prenetics-react-library/lib/Typography';
import { AdminContext } from '@providers/AdminProvider';
import { ApiUpdateContext } from '@providers/ApiUpdateProvider';
import { NotificationApiFactory } from '@services/api/b2b';
import colors from '@theme/colors.module.scss';
import { FeatureType, isFeatureEnabled } from '@util/feature';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const b2bNotifcationApi = NotificationApiFactory(undefined, API_BASE_URL + '/b2b');

export const Sidebar = ({ openAddCustomerModal }: { openAddCustomerModal: () => void }) => {
    const { t, i18n } = useTranslation();
    const { admin, logout, adminOrganisationId, adminTeamId } = useContext(AdminContext);
    const { updateSuccess, setUpdateSuccess } = useContext(ApiUpdateContext);
    const [badgeNumber, setBadgeNumber] = useState(0);

    const username = admin.physicianDetails?.alias || admin.email || '';
    const organisationName = admin.organisation?.name || '';
    const teamName = admin.team?.name || '';

    useEffect(() => {
        if (adminOrganisationId) {
            getNotifications(adminOrganisationId);
        } else {
            if (adminTeamId) {
                getNotifications(undefined, adminTeamId);
            }
        }
        if (updateSuccess.notification) {
            if (adminOrganisationId) {
                getNotifications(adminOrganisationId);
                setUpdateSuccess({ notification: false });
            } else {
                if (adminTeamId) {
                    getNotifications(undefined, adminTeamId);
                    setUpdateSuccess({ notification: false });
                }
            }
        }
    }, [adminOrganisationId, adminTeamId, updateSuccess.notification, setUpdateSuccess]);

    const getNotifications = async (organisationId?: string, teamId?: string) => {
        try {
            const {
                data: { totalCount },
            } = await b2bNotifcationApi.getNotifications(organisationId, teamId, 30, 0, false, 0, 100);
            setBadgeNumber(totalCount || 0);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <nav className="Sidebar">
            <div className="Sidebar__user">
                <div className="Sidebar__user-avatar">
                    <UserAvatar name={username} />
                </div>
                <div className="Sidebar__user-info">
                    <Typography weight="black" type="h4" color={colors.B9} text={admin.isOrgAdmin ? organisationName : teamName} />
                    <Typography weight="regular" type="p3" color={colors.B9} text={organisationName} />
                </div>
            </div>
            <div className="Sidebar__nav">
                <div className="Sidebar__add-customer-btn">
                    <Button
                        label={<Typography weight="semibold" type="p3" color="inherit" text={t('sidebar.button.add.customer')} />}
                        color={colors.White}
                        backgroundColor={colors.C5}
                        fullWidth
                        leftIcon={() => <AddIcon />}
                        onClick={openAddCustomerModal}
                    ></Button>
                </div>
                <NavLink className="Sidebar__nav-link" to={ROUTES.OVERVIEW}>
                    <Button
                        label={<Typography weight="semibold" type="p3" color="inherit" text={t('sidebar.nav.overview')} />}
                        color={colors.B6}
                        backgroundColor="transparent"
                        backgroundColorOnHover={colors.C0}
                        textColorOnHover={colors.B9}
                        borderRadius="8px"
                        leftIcon={() => <ReportIcon />}
                        width="216px"
                    ></Button>
                </NavLink>
                <NavLink className="Sidebar__nav-link" to={ROUTES.CUSTOMERS}>
                    <Button
                        label={<Typography weight="semibold" type="p3" color="inherit" text={t('sidebar.nav.customers')} />}
                        color={colors.B6}
                        backgroundColor="transparent"
                        backgroundColorOnHover={colors.C0}
                        textColorOnHover={colors.B9}
                        borderRadius="8px"
                        leftIcon={() => <ProfileIcon />}
                        width="216px"
                    ></Button>
                </NavLink>
                <NavLink className="Sidebar__nav-link" to={ROUTES.TESTS}>
                    <Button
                        label={<Typography weight="semibold" type="p3" color="inherit" text={t('sidebar.nav.tests')} />}
                        color={colors.B6}
                        backgroundColor="transparent"
                        backgroundColorOnHover={colors.C0}
                        textColorOnHover={colors.B9}
                        borderRadius="8px"
                        leftIcon={() => <TestIcon />}
                        width="216px"
                    ></Button>
                </NavLink>
                {isFeatureEnabled(FeatureType.ENABLE_CONSULTATION) ? (
                    <NavLink className="Sidebar__nav-link" to={ROUTES.CALENDAR}>
                        <Button
                            label={<Typography weight="semibold" type="p3" color="inherit" text={t('sidebar.nav.calendar')} />}
                            color={colors.B6}
                            backgroundColor="transparent"
                            backgroundColorOnHover={colors.C0}
                            textColorOnHover={colors.B9}
                            borderRadius="8px"
                            leftIcon={() => <CalendarIcon />}
                            width="216px"
                        ></Button>
                    </NavLink>
                ) : (
                    <></>
                )}
                {isFeatureEnabled(FeatureType.ENABLE_NOTIFICATION) ? (
                    <NavLink className="Sidebar__nav-link" to={ROUTES.NOTIFICATIONS}>
                        <Button
                            label={<Typography weight="semibold" type="p3" color="inherit" text={t('sidebar.nav.notifications')} />}
                            color={colors.B6}
                            backgroundColor="transparent"
                            backgroundColorOnHover={colors.C0}
                            textColorOnHover={colors.B9}
                            borderRadius="8px"
                            leftIcon={() => <AlertIcon />}
                            rightIcon={
                                badgeNumber > 0 ? () => <Badge shape={BadgeShapeEnum.Circle} text={badgeNumber > 99 ? '99+' : badgeNumber.toString()} variant={BadgeVariantEnum.Error} /> : () => <></>
                            }
                            width="216px"
                        ></Button>
                    </NavLink>
                ) : (
                    <></>
                )}
            </div>

            <div className="Sidebar__footer">
                <CustomLanguageSelect lang={i18n.language} />
                <Button
                    label={<Typography weight="semibold" type="p3" color="inherit" text={t('sidebar.nav.logout')} />}
                    color={colors.B6}
                    backgroundColor="transparent"
                    backgroundColorOnHover={colors.C0}
                    textColorOnHover={colors.B9}
                    borderRadius="8px"
                    leftIcon={() => <LogoutIcon />}
                    onClick={logout}
                ></Button>
                <LazyLoadImageComponent src={CircleDNALogo} data-testid="desktop-left-item-logo" className="Sidebar__logo" alt="circle-snapshot" />
            </div>
        </nav>
    );
};
