import '@components/Card/card.scss';
import './notifications.scss';

import NoNotificationAvatar from '@assets/images/noNotification.svg?react';
import { NotificationList } from '@components/NotificationList';
import { API_BASE_URL } from '@config';
import { Loader, Typography } from '@prenetics/prenetics-react-library';
import { notify } from '@prenetics/prenetics-react-library/lib/Toast';
import { AdminContext } from '@providers/AdminProvider';
import { ApiUpdateContext } from '@providers/ApiUpdateProvider';
import { Notification, NotificationApiFactory } from '@services/api/b2b';
import colors from '@theme/colors.module.scss';
import { isAxiosError } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const b2bNotifcationApi = NotificationApiFactory(undefined, API_BASE_URL + '/b2b');

export const Notifications = () => {
    const { adminOrganisationId, adminTeamId } = useContext(AdminContext);
    const { updateSuccess, setUpdateSuccess } = useContext(ApiUpdateContext);
    const [isLoading, setIsLoading] = useState(true);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [todayNotifications, setTodayNotifications] = useState<Notification[]>([]);
    const [oneWeekNotifications, setOneWeekNotifications] = useState<Notification[]>([]);
    const [thirtyDaysNotifications, setThirtyDaysNotifications] = useState<Notification[]>([]);

    useEffect(() => {
        const intervalValues = [
            { start: 0, end: 1 },
            { start: 1, end: 7 },
            { start: 7, end: 30 },
        ];
        const getNotifications = async (organisationId: string | undefined, teamId: string | undefined, start: number, end: number) => {
            try {
                setIsLoading(true);
                const {
                    data: { data },
                } = await b2bNotifcationApi.getNotifications(organisationId, teamId, end, start, undefined, 0, 10);

                if (start === 0 && end === 1) {
                    setTodayNotifications(data);
                } else if (start === 1 && end === 7) {
                    setOneWeekNotifications(data);
                } else {
                    setThirtyDaysNotifications(data);
                }
            } catch (error) {
                if (start === 0 && end === 1) {
                    setTodayNotifications([]);
                } else if (start === 1 && end === 7) {
                    setOneWeekNotifications([]);
                } else {
                    setThirtyDaysNotifications([]);
                }
                console.log(error);
                navigate('/customers');
            } finally {
                setIsLoading(false);
            }
        };
        if (adminOrganisationId) {
            intervalValues.map(interval => {
                getNotifications(adminOrganisationId, undefined, interval.start, interval.end);
            });
        } else {
            if (adminTeamId) {
                intervalValues.map(interval => {
                    getNotifications(undefined, adminTeamId, interval.start, interval.end);
                });
            }
        }
        if (updateSuccess.notification) {
            if (adminOrganisationId) {
                intervalValues.map(interval => {
                    getNotifications(adminOrganisationId, undefined, interval.start, interval.end);
                });
            } else {
                if (adminTeamId) {
                    intervalValues.map(interval => {
                        getNotifications(undefined, adminTeamId, interval.start, interval.end);
                    });
                }
            }
        }
    }, [adminOrganisationId, adminTeamId, updateSuccess.notification, navigate]);

    const updateReadStatus = async (notificationId: string, profileId?: string) => {
        /** Only call API when read status is false */
        const notification = [...todayNotifications, ...oneWeekNotifications, ...thirtyDaysNotifications].find(obj => obj.notificationId === notificationId);
        if (notification && !notification.read) {
            try {
                const { data } = await b2bNotifcationApi.updateNotification(notificationId, { read: true });
                if (data) {
                    setUpdateSuccess({ notification: true });
                    if (profileId) {
                        navigate(`/customers/${profileId}`);
                    }
                }
            } catch (error) {
                if (isAxiosError(error)) {
                    notify({ title: 'Failed to update notification read status', subtitle: `${error.response?.data?.error_message || error.message}`, type: 'error', progressColor: colors.ES5 });
                }
            }
        } else {
            /** `View Patient` button click but notification has been read */
            if (profileId) {
                navigate(`/customers/${profileId}`);
            }
        }
    };

    const handleSeeMore = (interval: 'today' | 'this_week' | 'last_month') => {
        navigate(`/notifications/${interval}`);
    };

    return (
        <div className="Notifcations">
            <div className="Notifcations__header">
                <Typography color={colors.B9} text={t('notifications.list.title.main')} type="h1" weight="semibold" />
            </div>
            {isLoading ? (
                <Loader />
            ) : [...todayNotifications, ...oneWeekNotifications, ...thirtyDaysNotifications].length > 0 ? (
                <NotificationList handleSeeMore={handleSeeMore} updateReadStatus={updateReadStatus} notifications={{ todayNotifications, oneWeekNotifications, thirtyDaysNotifications }} />
            ) : (
                <div className="NoNotifcationContainer">
                    <div className="NoNotifcationCard Card">
                        <div className="Card__body">
                            <NoNotificationAvatar className="NoNotificationAvatar" />
                            <Typography color={colors.B9} text={t('notifications.noNotifications.card.title')} type="h3" weight="semibold" />
                            <Typography color={colors.B9} text={t('notifications.noNotifications.card.description')} type="p2" weight="regular" />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
