import './login.scss';

import ShowLogo from '@assets/images/show.svg?react';
import { COOKIE_PARAMS } from '@config';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@prenetics/prenetics-react-library/lib/Button';
import { Input } from '@prenetics/prenetics-react-library/lib/Input';
import { InputWithIcon } from '@prenetics/prenetics-react-library/lib/InputWithIcon';
import { Typography } from '@prenetics/prenetics-react-library/lib/Typography';
import { useAuth } from '@prenetics/react-context-provider';
import { initializeAxios } from '@services/axios';
import colors from '@theme/colors.module.scss';
import { stylize } from '@util/stylize';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

type FormValues = {
    email: string;
    password: string;
};

const schema = yup
    .object({
        email: yup.string().email('login.field.email.invalid').required('login.field.required'),
        password: yup.string().required('login.field.required'),
    })
    .required();

export const Login = () => {
    const { t } = useTranslation('common');
    const { login, logout } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [loginFailed, setLoginFailed] = useState(false);
    const navigate = useNavigate();
    const [, setToken] = useCookies(['token']);

    const {
        handleSubmit,
        trigger,
        formState: { errors },
        setValue,
        setError,
    } = useForm<FormValues>({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data: FormValues) => {
        try {
            setLoginFailed(false);
            setIsLoading(true);
            const token = await login(data.email, data.password);
            setToken('token', token, COOKIE_PARAMS);
            initializeAxios(logout, token);
            navigate('/');
        } catch (error) {
            setError('email', { message: t('login.failed') });
            setError('password', { message: t('login.failed') });
            setLoginFailed(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="Login">
            <form id="login-form" className="Login__form" onSubmit={handleSubmit(onSubmit)}>
                <div className="Login__form-title">
                    <Typography text={t('login.welcome.title') as string} color={colors.B9} type="h1" weight="semibold" />
                </div>
                <div className="Login__form-body">
                    <Input
                        id="email"
                        name="email"
                        onChange={e => setValue('email', e.target.value)}
                        additionalsize="small"
                        labelcomponent={<Typography text={t('login.field.email.field')} color={colors.B7} type="p3" weight="regular" />}
                        onBlur={() => trigger('email')}
                        placeholder={t('login.field.email.placeholder')}
                        isError={!!errors?.email?.message}
                    />
                    <InputWithIcon
                        id="password"
                        name="password"
                        onChange={e => setValue('password', e.target.value)}
                        Icon={<ShowLogo id="show-password" className="show" onClick={() => setShowPassword(!showPassword)} />}
                        additionalsize="small"
                        label={t('login.field.password.field')}
                        labelcomponent={<Typography text={t('login.field.password.field')} color={colors.B7} type="p3" weight="regular" />}
                        placeholder={t('login.field.password.placeholder')}
                        type={showPassword ? 'text' : 'password'}
                        onBlur={() => trigger('password')}
                        isError={!!errors?.password?.message}
                    />

                    {loginFailed && (
                        <div>
                            <Typography text={t('login.failed')} color={colors.ES6} type="p3" weight="black" />
                        </div>
                    )}

                    <div className="Login__form-forgot-password">
                        <Typography id="forgot-password" text={stylize(t('login.forgotPassword.button.request'), ['/forgot-password']) as string} color={colors.C5} type="p3" weight="regular" />
                    </div>

                    <Button
                        id="submit"
                        type="submit"
                        isLoading={isLoading}
                        buttonType="primary"
                        label={<Typography weight="black" type="p3" color={colors.B0} text={t('login.button.login')} />}
                        backgroundColor={isLoading ? colors.B4 : colors.C5}
                        additionalsize="medium"
                    />
                </div>
                <div className="Login__form-footer">
                    <div className="Login__form-create-account">
                        <Typography text={t('login.contactUs.text.0') as string} color={colors.B7} type="p3" weight="regular" />
                        <Typography text={stylize(t('login.contactUs.link.0.title'), ['/create-account']) as string} color={colors.B7} type="p3" weight="regular" />
                    </div>
                </div>
            </form>
        </div>
    );
};
