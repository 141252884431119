import './transferReportModal.scss';

import { Button } from '@prenetics/prenetics-react-library/lib/Button';
import { Modal } from '@prenetics/prenetics-react-library/lib/Modal';
import { Typography } from '@prenetics/prenetics-react-library/lib/Typography';
import colors from '@theme/colors.module.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const TransferReportModal = ({ isOpen, closeModal, onSuccess, email }: { isOpen: boolean; closeModal: () => void; onSuccess: () => void; email: string }) => {
    const { t } = useTranslation();

    return (
        <div className="TransferReportModal">
            <Modal isOpen={isOpen} handleModalOpenClose={() => null}>
                <div className="TransferReportModal__body">
                    <div className="TransferReportModal__description">
                        <Typography text={t('customerDetail.transfer.report.modal.description.0', { email })} weight="regular" color={colors.B9} type="p2" />
                    </div>
                    <div className="TransferReportModal__description">
                        <Typography text={t('customerDetail.transfer.report.modal.description.1')} weight="regular" color={colors.B9} type="p2" />
                    </div>
                    <Button
                        label={<Typography weight="black" type="h4" color={colors.White} text={t('customerDetail.transfer.report.modal.button.transfer')} />}
                        buttonType="primary"
                        backgroundColor={colors.C5}
                        additionalsize="medium"
                        fullWidth
                        onClick={onSuccess}
                    />
                    <Button
                        label={<Typography weight="black" type="h4" color={colors.C5} text={t('customerDetail.transfer.report.modal.button.back')} />}
                        buttonType="secondary"
                        borderColor={colors.C5}
                        additionalsize="medium"
                        fullWidth
                        onClick={closeModal}
                    />
                </div>
            </Modal>
        </div>
    );
};
