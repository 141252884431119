import * as Sentry from '@sentry/react';
import { AxiosError, isAxiosError } from 'axios';

export function sendException(error: Error | AxiosError, skip404 = true, extra?: Record<string, unknown>) {
    if (isAxiosError(error)) {
        const errorMessage = error.response?.data.error_message;

        // replace all uuid in the string
        const newErrorMessage = errorMessage ? errorMessage.replace(/[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/g, '[uuid]') : 'unknown error';

        error.response?.status !== 404 || !skip404
            ? Sentry.captureException(new Error(newErrorMessage), {
                  extra: {
                      ...error.response?.data,
                      ...extra,
                  },
              })
            : null;
    } else {
        Sentry.captureException(error, {
            ...(extra
                ? {
                      extra,
                  }
                : {}),
        });
    }
}
