import '@services/i18n';
import './index.scss';
import './App.scss';

import { APP_ENVIRONMENT, IS_LOCAL, SENTRY_DSN } from '@config';
import { BrowserTracing } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { initializeAxios } from '@services/axios';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

const client = new Sentry.BrowserClient({
    dsn: SENTRY_DSN,
    transport: Sentry.makeFetchTransport,
    stackParser: Sentry.defaultStackParser,
    integrations: [
        ...(IS_LOCAL
            ? []
            : [
                  new BrowserTracing({
                      routingInstrumentation: Sentry.reactRouterV6Instrumentation(React.useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes),
                  }),
              ]),
    ],
    environment: APP_ENVIRONMENT,
    ignoreErrors: ['Unable to preload CSS', 'fbq is not defined'],
    release: 'circle-web-b2b-portal@' + process.env.npm_package_version,
    tracesSampleRate: 1.0,
});

Sentry.getCurrentHub().bindClient(client);

function render() {
    const container = document.getElementById('root');
    if (!container) {
        return null;
    }
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </React.StrictMode>,
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

(() => {
    initializeAxios();
    render();
})();
