import './addTestErrorModal.scss';

import WarningIcon from '@assets/images/warning.svg?react';
import { Button } from '@prenetics/prenetics-react-library/lib/Button';
import { Modal } from '@prenetics/prenetics-react-library/lib/Modal';
import { Typography } from '@prenetics/prenetics-react-library/lib/Typography';
import colors from '@theme/colors.module.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';

export enum AddTestErrorType {
    BarcodeInvalid = 'barcode_invalid',
    KitHasBeenAssigned = 'kit_has_been_assigned',
    Other = 'other',
}

export const AddTestErrorModal = ({ isOpen, closeModal, errorType = AddTestErrorType.Other }: { isOpen: boolean; closeModal: () => void; errorType?: AddTestErrorType }) => {
    const { t } = useTranslation();

    return (
        <div className="AddTestErrorModal">
            <Modal isOpen={isOpen} handleModalOpenClose={() => null}>
                <div className="AddTestErrorModal__body">
                    <WarningIcon />
                    <div className="AddTestErrorModal__title">
                        <Typography text={t('customers.addTest.error.modal.title')} weight="black" color={colors.B9} type="h1" />
                    </div>
                    <div className="AddTestErrorModal__description">
                        <Typography text={t(`customers.addTest.error.modal.description.${errorType}`)} weight="regular" color={colors.B9} type="p2" />
                    </div>
                    <Button
                        label={<Typography weight="black" type="h4" color={colors.White} text={t('customers.addTest.error.modal.button.retry')} />}
                        buttonType="primary"
                        backgroundColor={colors.C5}
                        additionalsize="medium"
                        fullWidth
                        onClick={closeModal}
                    />
                </div>
            </Modal>
        </div>
    );
};
