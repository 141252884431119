import './overview.scss';

import { API_BASE_URL } from '@config';
import { Loader } from '@prenetics/prenetics-react-library/lib/Loader';
import { Typography } from '@prenetics/prenetics-react-library/lib/Typography';
import { AdminContext } from '@providers/AdminProvider';
import { GetResultOverview200Response, ResultApiFactory, ResultStatus } from '@services/api/b2b';
import colors from '@theme/colors.module.scss';
import type { ChartData, ChartOptions } from 'chart.js';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import React, { useContext, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

const b2bResultApi = ResultApiFactory(undefined, API_BASE_URL + '/b2b');

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

type StatusType = {
    count: number;
};

const getStatusColor = (status: string, count: number) => {
    if (count === 0) {
        return colors.White;
    }

    switch (status) {
        case ResultStatus.Activated:
            return colors.B6;
        case ResultStatus.ReportReleased:
            return colors.SS4;
        case ResultStatus.SampleRejected:
            return colors.ES6;
        default:
            return colors.WS2;
    }
};

export const Overview = () => {
    const { adminTeamId, adminOrganisationId } = useContext(AdminContext);
    const [isLoading, setIsLoading] = useState(true);
    const [overviewRawData, setOverviewRawData] = useState<GetResultOverview200Response | null>(null);
    const [chartData, setChartData] = useState<ChartData<'bar'>>({ labels: [], datasets: [] });
    const [chartOptions, setChartOptions] = useState<ChartOptions<'bar'>>({});
    const { t } = useTranslation();

    useEffect(() => {
        const getOverview = async (teamId: string, organisationId: string | undefined) => {
            try {
                setIsLoading(true);
                const { data } = await b2bResultApi.getResultOverview(undefined, teamId, organisationId);

                setOverviewRawData(data);
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };

        if (adminTeamId) {
            getOverview(adminTeamId, adminOrganisationId);
        } else if (adminOrganisationId) {
            setIsLoading(false);
        }
    }, [adminTeamId, adminOrganisationId]);

    useEffect(() => {
        if (overviewRawData) {
            const labels = Object.keys(overviewRawData.statuses).map((status: string) => t(`common.test.status.${status.toLowerCase()}`));
            const datasets = [
                {
                    data: Object.values(overviewRawData.statuses).map((status: StatusType) => status.count),
                    backgroundColor: Object.keys(overviewRawData.statuses).map((status: string) => getStatusColor(status, overviewRawData.statuses[status].count)),
                    barThickness: 45,
                    minBarLength: 10,
                },
            ];

            setChartData({ labels, datasets });
            setChartOptions({
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        bodyAlign: 'center',
                        displayColors: false,
                        backgroundColor: colors.White,
                        bodyColor: colors.B9,
                        titleColor: colors.B9,
                        borderColor: 'rgba(0, 0, 0, 0.5)',
                        borderWidth: 1,
                        padding: 16,
                        callbacks: {
                            title: context => `${context[0].label}: ${t('common.test.count', { count: Number(context[0].raw) || 0 })}`,
                            label: context => {
                                const status = Object.keys(overviewRawData.statuses)[context.dataIndex].toLowerCase();
                                return t(`overview.chart.tooltip.${status}`);
                            },
                        },
                    },
                },
                elements: {
                    bar: {
                        borderRadius: 8,
                    },
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            maxTicksLimit: 5,
                            stepSize: 1,
                        },
                    },
                    x: {
                        grid: {
                            display: false,
                        },
                    },
                },
            });
        }
    }, [overviewRawData, t]);

    return (
        <div className="Overview">
            <div className="Overview__header">
                <Typography weight="semibold" type="h1" color={colors.B9} text={t('overview.title')} />
            </div>
            {isLoading && <Loader />}
            {!isLoading && (
                <div className="Overview__content">
                    <div className="Overview__content-header">
                        <Typography weight="semibold" type="h3" color={colors.B9} text={t('common.circledna')} />
                    </div>
                    <div className="Overview__content-body">
                        <Typography weight="semibold" type="h4" color={colors.B9} text={t('overview.subtitle.statuses')} />
                        <div className="Overview__content-chart">
                            <Bar data={chartData} options={chartOptions} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
