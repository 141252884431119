const APP_ENVIRONMENT = process.env.VITE_APP_ENVIRONMENT || 'dev';

export enum FeatureType {
    ENABLE_CONSULTATION,
    ENABLE_NOTIFICATION,
    NOTIFICATION_EXPIRY_DATE,
}

export const ENV_FEATURE_MAPPING = {
    [FeatureType.ENABLE_CONSULTATION]: {
        local: false,
        integration: false,
        staging: false,
        prod: false,
    },
    [FeatureType.ENABLE_NOTIFICATION]: {
        local: true,
        integration: true,
        staging: true,
        prod: true,
    },
    [FeatureType.NOTIFICATION_EXPIRY_DATE]: {
        local: 7,
        integration: 7,
        staging: 7,
        prod: 30,
    },
};

export function isFeatureEnabled(featureType: FeatureType): number | boolean {
    const environmentFeatures = ENV_FEATURE_MAPPING[featureType];
    if (!environmentFeatures) return false;

    return environmentFeatures[APP_ENVIRONMENT as keyof typeof environmentFeatures];
}
