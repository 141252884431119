export enum ROUTES {
    OVERVIEW = '/',
    LOGIN = '/login',
    FORGOT_PASSWORD = '/forgot-password',
    CUSTOMERS = '/customers',
    CUSTOMER_DETAIL = '/customers/:customerId',
    TESTS = '/tests',
    CALENDAR = '/calendar',
    NOTIFICATIONS = '/notifications',
}
