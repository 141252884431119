import { AxiosError } from 'axios';

type ErrorType = {
    error_code: string;
    error_message: string;
};

const regex = /(\d+) - {"error_code":"(\w+)","error_message":{"msg":"([^"]+)"}}/;

export const getErrorMessage = (error: AxiosError): string => {
    if (error?.response?.data) {
        const errorObj = error.response.data as ErrorType;
        const errorMessage = errorObj.error_message;

        const match = errorMessage.match(regex);
        if (match) {
            return match[3];
        }
        return errorMessage;
    }

    return error.message;
};
