/* tslint:disable */
/* eslint-disable */
/**
 * B2b Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddBarcodeToOrganisationRequest
 */
export interface AddBarcodeToOrganisationRequest {
    /**
     * 
     * @type {string}
     * @memberof AddBarcodeToOrganisationRequest
     */
    'barcode': string;
    /**
     * 
     * @type {boolean}
     * @memberof AddBarcodeToOrganisationRequest
     */
    'addResult'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddBarcodeToOrganisationRequest
     */
    'teamId'?: string;
}
/**
 * 
 * @export
 * @interface Admin
 */
export interface Admin {
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'adminId': string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof Admin
     */
    'active': boolean;
    /**
     * 
     * @type {number}
     * @memberof Admin
     */
    'version': number;
    /**
     * 
     * @type {Array<Role>}
     * @memberof Admin
     */
    'roles'?: Array<Role>;
    /**
     * 
     * @type {Organisation}
     * @memberof Admin
     */
    'organisation'?: Organisation | null;
    /**
     * 
     * @type {Team}
     * @memberof Admin
     */
    'team'?: Team | null;
}
/**
 * 
 * @export
 * @interface AdminWithExtra
 */
export interface AdminWithExtra {
    /**
     * 
     * @type {string}
     * @memberof AdminWithExtra
     */
    'adminId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminWithExtra
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminWithExtra
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminWithExtra
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminWithExtra
     */
    'active': boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminWithExtra
     */
    'version': number;
    /**
     * 
     * @type {Array<Role>}
     * @memberof AdminWithExtra
     */
    'roles'?: Array<Role>;
    /**
     * 
     * @type {Organisation}
     * @memberof AdminWithExtra
     */
    'organisation'?: Organisation | null;
    /**
     * 
     * @type {Team}
     * @memberof AdminWithExtra
     */
    'team'?: Team | null;
    /**
     * 
     * @type {PhysicianDetails}
     * @memberof AdminWithExtra
     */
    'physicianDetails'?: PhysicianDetails;
    /**
     * 
     * @type {string}
     * @memberof AdminWithExtra
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminWithExtra
     */
    'isOrgAdmin'?: boolean;
}
/**
 * 
 * @export
 * @interface AdminWithExtraAllOf
 */
export interface AdminWithExtraAllOf {
    /**
     * 
     * @type {PhysicianDetails}
     * @memberof AdminWithExtraAllOf
     */
    'physicianDetails'?: PhysicianDetails;
    /**
     * 
     * @type {string}
     * @memberof AdminWithExtraAllOf
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminWithExtraAllOf
     */
    'isOrgAdmin'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateAdminRequest
 */
export interface CreateAdminRequest {
    /**
     * The organisation to create the admin for
     * @type {string}
     * @memberof CreateAdminRequest
     */
    'organisationId'?: string;
    /**
     * The organisation to create the admin for
     * @type {string}
     * @memberof CreateAdminRequest
     */
    'teamId'?: string;
    /**
     * The name of the admin
     * @type {string}
     * @memberof CreateAdminRequest
     */
    'name'?: string;
    /**
     * The email of the admin
     * @type {string}
     * @memberof CreateAdminRequest
     */
    'email': string;
    /**
     * The password of the admin
     * @type {string}
     * @memberof CreateAdminRequest
     */
    'password': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAdminRequest
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminRequest
     */
    'externalId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAdminRequest
     */
    'roles': Array<CreateAdminRequestRolesEnum>;
}

export const CreateAdminRequestRolesEnum = {
    Physician: 'physician'
} as const;

export type CreateAdminRequestRolesEnum = typeof CreateAdminRequestRolesEnum[keyof typeof CreateAdminRequestRolesEnum];

/**
 * 
 * @export
 * @interface CreateOrganisationRequest
 */
export interface CreateOrganisationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrganisationRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganisationRequest
     */
    'entity': CreateOrganisationRequestEntityEnum;
}

export const CreateOrganisationRequestEntityEnum = {
    Prenetics: 'prenetics',
    Actg: 'actg'
} as const;

export type CreateOrganisationRequestEntityEnum = typeof CreateOrganisationRequestEntityEnum[keyof typeof CreateOrganisationRequestEntityEnum];

/**
 * 
 * @export
 * @interface CreateProfileRequest
 */
export interface CreateProfileRequest {
    /**
     * External ID of the profile - usually this ID link to an entity in our system
     * @type {string}
     * @memberof CreateProfileRequest
     */
    'externalId'?: string;
    /**
     * External User ID of the customer/patient - usually this ID link to an entity in an external system
     * @type {string}
     * @memberof CreateProfileRequest
     */
    'externalUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfileRequest
     */
    'email'?: string;
    /**
     * 
     * @type {HealthUpsert}
     * @memberof CreateProfileRequest
     */
    'health'?: HealthUpsert;
    /**
     * 
     * @type {NameUpsert}
     * @memberof CreateProfileRequest
     */
    'name'?: NameUpsert;
    /**
     * 
     * @type {PhoneUpsert}
     * @memberof CreateProfileRequest
     */
    'phone'?: PhoneUpsert;
}
/**
 * 
 * @export
 * @interface CreateResultRequest
 */
export interface CreateResultRequest {
    /**
     * Correlation Id of the sample when it has already been created
     * @type {string}
     * @memberof CreateResultRequest
     */
    'correlationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateResultRequest
     */
    'correlationType': string;
    /**
     * Barcode of the sample
     * @type {string}
     * @memberof CreateResultRequest
     */
    'barcode'?: string;
}
/**
 * 
 * @export
 * @interface CreateTask201Response
 */
export interface CreateTask201Response {
    /**
     * 
     * @type {string}
     * @memberof CreateTask201Response
     */
    'taskId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTask201Response
     */
    'type'?: string;
    /**
     * 
     * @type {object}
     * @memberof CreateTask201Response
     */
    'datetime'?: object;
    /**
     * 
     * @type {object}
     * @memberof CreateTask201Response
     */
    'detail'?: object;
    /**
     * 
     * @type {object}
     * @memberof CreateTask201Response
     */
    'schedule'?: object | null;
}
/**
 * 
 * @export
 * @interface CreateTaskRequest
 */
export interface CreateTaskRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTaskRequest
     */
    'type'?: string;
    /**
     * 
     * @type {CreateTaskRequestDetail}
     * @memberof CreateTaskRequest
     */
    'detail'?: CreateTaskRequestDetail;
    /**
     * date as YYYY-MM-DD HH:mm:ss
     * @type {string}
     * @memberof CreateTaskRequest
     */
    'schedule'?: string;
}
/**
 * task detail
 * @export
 * @interface CreateTaskRequestDetail
 */
export interface CreateTaskRequestDetail {
    /**
     * 
     * @type {string}
     * @memberof CreateTaskRequestDetail
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskRequestDetail
     */
    'kitId'?: string;
}
/**
 * 
 * @export
 * @interface CreateTeamRequest
 */
export interface CreateTeamRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTeamRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeamRequest
     */
    'organisationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeamRequest
     */
    'teamId'?: string;
}
/**
 * 
 * @export
 * @interface Email
 */
export interface Email {
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    'emailId': string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    'datetime': string;
    /**
     * 
     * @type {boolean}
     * @memberof Email
     */
    'primary': boolean;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface ErrorMessageObject
 */
export interface ErrorMessageObject {
    /**
     * 
     * @type {string}
     * @memberof ErrorMessageObject
     */
    'error_code': string;
    /**
     * 
     * @type {ErrorMessageObjectErrorMessage}
     * @memberof ErrorMessageObject
     */
    'error_message': ErrorMessageObjectErrorMessage;
}
/**
 * 
 * @export
 * @interface ErrorMessageObjectErrorMessage
 */
export interface ErrorMessageObjectErrorMessage {
    /**
     * 
     * @type {string}
     * @memberof ErrorMessageObjectErrorMessage
     */
    'msg'?: string;
}
/**
 * 
 * @export
 * @interface GetNotifications200Response
 */
export interface GetNotifications200Response {
    /**
     * 
     * @type {Array<Notification>}
     * @memberof GetNotifications200Response
     */
    'data': Array<Notification>;
    /**
     * 
     * @type {number}
     * @memberof GetNotifications200Response
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface GetOrganisationBarcode200Response
 */
export interface GetOrganisationBarcode200Response {
    /**
     * 
     * @type {string}
     * @memberof GetOrganisationBarcode200Response
     */
    'barcode': string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganisationBarcode200Response
     */
    'kitStatus': GetOrganisationBarcode200ResponseKitStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetOrganisationBarcode200Response
     */
    'kitId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetOrganisationBarcode200Response
     */
    'segment'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetOrganisationBarcode200Response
     */
    'scope': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetOrganisationBarcode200Response
     */
    'datetime': string;
}

export const GetOrganisationBarcode200ResponseKitStatusEnum = {
    Open: 'open',
    Ordered: 'ordered',
    Activated: 'activated',
    Scheduled: 'scheduled',
    Lab: 'lab',
    Ready: 'ready',
    Rejected: 'rejected',
    Replaced: 'replaced'
} as const;

export type GetOrganisationBarcode200ResponseKitStatusEnum = typeof GetOrganisationBarcode200ResponseKitStatusEnum[keyof typeof GetOrganisationBarcode200ResponseKitStatusEnum];

/**
 * 
 * @export
 * @interface GetOrganisationTeams200Response
 */
export interface GetOrganisationTeams200Response {
    /**
     * 
     * @type {Array<Team>}
     * @memberof GetOrganisationTeams200Response
     */
    'data': Array<Team>;
}
/**
 * 
 * @export
 * @interface GetOrganisations200Response
 */
export interface GetOrganisations200Response {
    /**
     * 
     * @type {Array<Organisation>}
     * @memberof GetOrganisations200Response
     */
    'data': Array<Organisation>;
}
/**
 * 
 * @export
 * @interface GetProfiles200Response
 */
export interface GetProfiles200Response {
    /**
     * 
     * @type {Array<Profile>}
     * @memberof GetProfiles200Response
     */
    'data': Array<Profile>;
    /**
     * 
     * @type {number}
     * @memberof GetProfiles200Response
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface GetResultOverview200Response
 */
export interface GetResultOverview200Response {
    /**
     * 
     * @type {number}
     * @memberof GetResultOverview200Response
     */
    'count': number;
    /**
     * 
     * @type {{ [key: string]: GetResultOverview200ResponseStatusesValue; }}
     * @memberof GetResultOverview200Response
     */
    'statuses': { [key: string]: GetResultOverview200ResponseStatusesValue; };
}
/**
 * 
 * @export
 * @interface GetResultOverview200ResponseStatusesValue
 */
export interface GetResultOverview200ResponseStatusesValue {
    /**
     * 
     * @type {number}
     * @memberof GetResultOverview200ResponseStatusesValue
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface GetResults200Response
 */
export interface GetResults200Response {
    /**
     * 
     * @type {Array<ResultWithExtraMetadata>}
     * @memberof GetResults200Response
     */
    'data': Array<ResultWithExtraMetadata>;
    /**
     * 
     * @type {number}
     * @memberof GetResults200Response
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface Health
 */
export interface Health {
    /**
     * 
     * @type {string}
     * @memberof Health
     */
    'weight'?: string | null;
    /**
     * 
     * @type {WeightUnit}
     * @memberof Health
     */
    'weightUnit'?: WeightUnit;
    /**
     * 
     * @type {number}
     * @memberof Health
     */
    'height'?: number | null;
    /**
     * 
     * @type {HeightUnit}
     * @memberof Health
     */
    'heightUnit'?: HeightUnit;
    /**
     * 
     * @type {string}
     * @memberof Health
     */
    'dob'?: string;
    /**
     * 
     * @type {string}
     * @memberof Health
     */
    'yob'?: string;
    /**
     * 
     * @type {string}
     * @memberof Health
     */
    'ethnicity'?: string;
    /**
     * 
     * @type {string}
     * @memberof Health
     */
    'gender'?: string;
}
/**
 * 
 * @export
 * @interface HealthUpsert
 */
export interface HealthUpsert {
    /**
     * 
     * @type {string}
     * @memberof HealthUpsert
     */
    'dob'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthUpsert
     */
    'ethnicity'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthUpsert
     */
    'gender'?: HealthUpsertGenderEnum;
    /**
     * 
     * @type {HealthUpsertHeight}
     * @memberof HealthUpsert
     */
    'height'?: HealthUpsertHeight;
    /**
     * 
     * @type {HealthUpsertWeight}
     * @memberof HealthUpsert
     */
    'weight'?: HealthUpsertWeight;
}

export const HealthUpsertGenderEnum = {
    Male: 'male',
    Female: 'female',
    Other: 'other'
} as const;

export type HealthUpsertGenderEnum = typeof HealthUpsertGenderEnum[keyof typeof HealthUpsertGenderEnum];

/**
 * 
 * @export
 * @interface HealthUpsertHeight
 */
export interface HealthUpsertHeight {
    /**
     * 
     * @type {HeightUnit}
     * @memberof HealthUpsertHeight
     */
    'unit': HeightUnit;
    /**
     * 
     * @type {string}
     * @memberof HealthUpsertHeight
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface HealthUpsertWeight
 */
export interface HealthUpsertWeight {
    /**
     * 
     * @type {WeightUnit}
     * @memberof HealthUpsertWeight
     */
    'unit': WeightUnit;
    /**
     * 
     * @type {string}
     * @memberof HealthUpsertWeight
     */
    'value': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const HeightUnit = {
    Cm: 'cm',
    In: 'in'
} as const;

export type HeightUnit = typeof HeightUnit[keyof typeof HeightUnit];


/**
 * 
 * @export
 * @interface KitMetadata
 */
export interface KitMetadata {
    /**
     * 
     * @type {string}
     * @memberof KitMetadata
     */
    'kitId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KitMetadata
     */
    'datetime'?: string;
    /**
     * 
     * @type {string}
     * @memberof KitMetadata
     */
    'status'?: KitMetadataStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof KitMetadata
     */
    'testStatus'?: KitMetadataTestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof KitMetadata
     */
    'barcode'?: string;
    /**
     * 
     * @type {string}
     * @memberof KitMetadata
     */
    'sku'?: string;
}

export const KitMetadataStatusEnum = {
    Open: 'open',
    Ordered: 'ordered',
    Activated: 'activated',
    Scheduled: 'scheduled',
    Lab: 'lab',
    Ready: 'ready',
    Rejected: 'rejected',
    Replaced: 'replaced'
} as const;

export type KitMetadataStatusEnum = typeof KitMetadataStatusEnum[keyof typeof KitMetadataStatusEnum];
export const KitMetadataTestStatusEnum = {
    TestCreated: 'test-created',
    LabResultSourceReady: 'lab-result-source-ready',
    LabResultSourceAncestryReady: 'lab-result-source-ancestry-ready',
    LabResultSourceApproved: 'lab-result-source-approved',
    LabResultInterpretationReady: 'lab-result-interpretation-ready',
    LabResultGtApproved: 'lab-result-gt-approved',
    LabResultInterpretationApproved: 'lab-result-interpretation-approved',
    LabResultCnvReady: 'lab-result-cnv-ready',
    LabResultReady: 'lab-result-ready',
    LabResultReadyApproved: 'lab-result-ready-approved',
    ScoreResultReady: 'score-result-ready',
    ResultNotificationReady: 'result-notification-ready',
    ReportResultReady: 'report-result-ready',
    ReportReady: 'report-ready',
    TestTerminated: 'test-terminated'
} as const;

export type KitMetadataTestStatusEnum = typeof KitMetadataTestStatusEnum[keyof typeof KitMetadataTestStatusEnum];

/**
 * 
 * @export
 * @interface ListAdmin200Response
 */
export interface ListAdmin200Response {
    /**
     * 
     * @type {Array<Admin>}
     * @memberof ListAdmin200Response
     */
    'data': Array<Admin>;
}
/**
 * 
 * @export
 * @interface ListAdminRequest
 */
export interface ListAdminRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ListAdminRequest
     */
    'externalAdminIds': Array<string>;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'error_code': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'error_message': string;
}
/**
 * 
 * @export
 * @interface Name
 */
export interface Name {
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'nickName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'chineseFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'chineseLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'otherName'?: string | null;
}
/**
 * 
 * @export
 * @interface NameUpsert
 */
export interface NameUpsert {
    /**
     * 
     * @type {string}
     * @memberof NameUpsert
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof NameUpsert
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'notificationId': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    'active': boolean;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'titleKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'contentKey'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    'read'?: boolean;
    /**
     * 
     * @type {ResultWithExtraMetadata}
     * @memberof Notification
     */
    'result'?: ResultWithExtraMetadata;
}
/**
 * 
 * @export
 * @interface Organisation
 */
export interface Organisation {
    /**
     * 
     * @type {string}
     * @memberof Organisation
     */
    'organisationId': string;
    /**
     * 
     * @type {string}
     * @memberof Organisation
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof Organisation
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof Organisation
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Organisation
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof Organisation
     */
    'version': number;
    /**
     * 
     * @type {Array<AdminWithExtra>}
     * @memberof Organisation
     */
    'admins'?: Array<AdminWithExtra>;
    /**
     * 
     * @type {Array<Team>}
     * @memberof Organisation
     */
    'teams'?: Array<Team>;
}
/**
 * 
 * @export
 * @interface OverviewOrganisation
 */
export interface OverviewOrganisation {
    /**
     * 
     * @type {string}
     * @memberof OverviewOrganisation
     */
    'organisationId': string;
    /**
     * 
     * @type {string}
     * @memberof OverviewOrganisation
     */
    'name': string;
    /**
     * 
     * @type {Array<OverviewTeam>}
     * @memberof OverviewOrganisation
     */
    'children': Array<OverviewTeam>;
}
/**
 * 
 * @export
 * @interface OverviewTeam
 */
export interface OverviewTeam {
    /**
     * 
     * @type {string}
     * @memberof OverviewTeam
     */
    'teamId': string;
    /**
     * 
     * @type {string}
     * @memberof OverviewTeam
     */
    'name': string;
    /**
     * 
     * @type {Array<OverviewTeam>}
     * @memberof OverviewTeam
     */
    'children': Array<OverviewTeam>;
}
/**
 * 
 * @export
 * @interface Patient
 */
export interface Patient {
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'gender'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'patientId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'patientName'?: string | null;
}
/**
 * 
 * @export
 * @interface Phone
 */
export interface Phone {
    /**
     * 
     * @type {string}
     * @memberof Phone
     */
    'phoneId': string;
    /**
     * 
     * @type {string}
     * @memberof Phone
     */
    'datetime': string;
    /**
     * 
     * @type {boolean}
     * @memberof Phone
     */
    'primary': boolean;
    /**
     * 
     * @type {string}
     * @memberof Phone
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Phone
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof Phone
     */
    'number': string;
    /**
     * 
     * @type {boolean}
     * @memberof Phone
     */
    'mobile': boolean;
}
/**
 * 
 * @export
 * @interface PhoneUpsert
 */
export interface PhoneUpsert {
    /**
     * 
     * @type {string}
     * @memberof PhoneUpsert
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof PhoneUpsert
     */
    'number': string;
}
/**
 * 
 * @export
 * @interface PhysicianDetails
 */
export interface PhysicianDetails {
    /**
     * 
     * @type {string}
     * @memberof PhysicianDetails
     */
    'alias'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhysicianDetails
     */
    'email': string;
    /**
     * 
     * @type {PhysicianPhone}
     * @memberof PhysicianDetails
     */
    'phone'?: PhysicianPhone;
    /**
     * 
     * @type {string}
     * @memberof PhysicianDetails
     */
    'alternateEmail'?: string | null;
}
/**
 * 
 * @export
 * @interface PhysicianPhone
 */
export interface PhysicianPhone {
    /**
     * 
     * @type {string}
     * @memberof PhysicianPhone
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhysicianPhone
     */
    'phoneNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'profileId': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'externalId': string;
    /**
     * 
     * @type {Name}
     * @memberof Profile
     */
    'name'?: Name;
    /**
     * 
     * @type {Array<Result>}
     * @memberof Profile
     */
    'results'?: Array<Result>;
    /**
     * 
     * @type {Team}
     * @memberof Profile
     */
    'team'?: Team | null;
    /**
     * 
     * @type {Health}
     * @memberof Profile
     */
    'health'?: Health;
    /**
     * 
     * @type {Array<Phone>}
     * @memberof Profile
     */
    'phone'?: Array<Phone>;
    /**
     * 
     * @type {Array<Email>}
     * @memberof Profile
     */
    'email'?: Array<Email>;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'externalUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'owner'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    'transferred'?: boolean;
}
/**
 * 
 * @export
 * @interface ProjectDetails
 */
export interface ProjectDetails {
    /**
     * 
     * @type {Patient}
     * @memberof ProjectDetails
     */
    'patient': Patient;
    /**
     * 
     * @type {ProjectInProjectDetails}
     * @memberof ProjectDetails
     */
    'project': ProjectInProjectDetails;
    /**
     * 
     * @type {Array<ReportMetadata>}
     * @memberof ProjectDetails
     */
    'reports'?: Array<ReportMetadata> | null;
}
/**
 * 
 * @export
 * @interface ProjectInProjectDetails
 */
export interface ProjectInProjectDetails {
    /**
     * 
     * @type {string}
     * @memberof ProjectInProjectDetails
     */
    'actReportDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectInProjectDetails
     */
    'estReportDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectInProjectDetails
     */
    'physician'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectInProjectDetails
     */
    'product'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectInProjectDetails
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectInProjectDetails
     */
    'sales'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectInProjectDetails
     */
    'startTime'?: string;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof ProjectInProjectDetails
     */
    'status'?: ProjectStatus;
    /**
     * 
     * @type {string}
     * @memberof ProjectInProjectDetails
     */
    'statusUpdateTime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectInProjectDetails
     */
    'isUrgent'?: number;
}
/**
 * 
 * @export
 * @interface ProjectMetadata
 */
export interface ProjectMetadata {
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadata
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadata
     */
    'product'?: string | null;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof ProjectMetadata
     */
    'status'?: ProjectStatus;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadata
     */
    'patient'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadata
     */
    'physician'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadata
     */
    'sales'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadata
     */
    'estReportDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadata
     */
    'actReportDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadata
     */
    'statusUpdateTime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectMetadata
     */
    'isUrgent'?: number;
}
/**
 * 
 * @export
 * @interface ProjectStatus
 */
export interface ProjectStatus {
    /**
     * 
     * @type {number}
     * @memberof ProjectStatus
     */
    'statusId': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectStatus
     */
    'statusDes': string;
}
/**
 * 
 * @export
 * @interface ReportMetadata
 */
export interface ReportMetadata {
    /**
     * 
     * @type {string}
     * @memberof ReportMetadata
     */
    'fileName': string;
    /**
     * 
     * @type {number}
     * @memberof ReportMetadata
     */
    'reportId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportMetadata
     */
    'reportType': string;
    /**
     * 
     * @type {string}
     * @memberof ReportMetadata
     */
    'uploadTime'?: string;
}
/**
 * 
 * @export
 * @interface Result
 */
export interface Result {
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    'resultId': string;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof Result
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    'correlationId': string;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    'correlationType': string;
    /**
     * 
     * @type {Profile}
     * @memberof Result
     */
    'profile'?: Profile;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    'profileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    'teamName'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ResultStatus = {
    Activated: 'ACTIVATED',
    AtLab: 'AT_LAB',
    ResultsReady: 'RESULTS_READY',
    ReportReleased: 'REPORT_RELEASED',
    SampleRejected: 'SAMPLE_REJECTED',
    ExpectedReportReleased: 'EXPECTED_REPORT_RELEASED'
} as const;

export type ResultStatus = typeof ResultStatus[keyof typeof ResultStatus];


/**
 * 
 * @export
 * @interface ResultWithExtraMetadata
 */
export interface ResultWithExtraMetadata {
    /**
     * 
     * @type {string}
     * @memberof ResultWithExtraMetadata
     */
    'resultId': string;
    /**
     * 
     * @type {string}
     * @memberof ResultWithExtraMetadata
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResultWithExtraMetadata
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof ResultWithExtraMetadata
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof ResultWithExtraMetadata
     */
    'correlationId': string;
    /**
     * 
     * @type {string}
     * @memberof ResultWithExtraMetadata
     */
    'correlationType': string;
    /**
     * 
     * @type {Profile}
     * @memberof ResultWithExtraMetadata
     */
    'profile'?: Profile;
    /**
     * 
     * @type {string}
     * @memberof ResultWithExtraMetadata
     */
    'profileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResultWithExtraMetadata
     */
    'teamName'?: string;
    /**
     * 
     * @type {ProjectMetadata}
     * @memberof ResultWithExtraMetadata
     */
    'projectMetadata'?: ProjectMetadata;
    /**
     * 
     * @type {ResultStatus}
     * @memberof ResultWithExtraMetadata
     */
    'resultStatus'?: ResultStatus;
    /**
     * 
     * @type {Array<ResultWithExtraMetadataAllOfResultHistory>}
     * @memberof ResultWithExtraMetadata
     */
    'resultHistory'?: Array<ResultWithExtraMetadataAllOfResultHistory> | null;
    /**
     * 
     * @type {KitMetadata}
     * @memberof ResultWithExtraMetadata
     */
    'kitMetadata'?: KitMetadata;
    /**
     * 
     * @type {ProjectDetails}
     * @memberof ResultWithExtraMetadata
     */
    'projectDetails'?: ProjectDetails;
}
/**
 * 
 * @export
 * @interface ResultWithExtraMetadataAllOf
 */
export interface ResultWithExtraMetadataAllOf {
    /**
     * 
     * @type {ProjectMetadata}
     * @memberof ResultWithExtraMetadataAllOf
     */
    'projectMetadata'?: ProjectMetadata;
    /**
     * 
     * @type {ResultStatus}
     * @memberof ResultWithExtraMetadataAllOf
     */
    'resultStatus'?: ResultStatus;
    /**
     * 
     * @type {Array<ResultWithExtraMetadataAllOfResultHistory>}
     * @memberof ResultWithExtraMetadataAllOf
     */
    'resultHistory'?: Array<ResultWithExtraMetadataAllOfResultHistory> | null;
    /**
     * 
     * @type {KitMetadata}
     * @memberof ResultWithExtraMetadataAllOf
     */
    'kitMetadata'?: KitMetadata;
    /**
     * 
     * @type {ProjectDetails}
     * @memberof ResultWithExtraMetadataAllOf
     */
    'projectDetails'?: ProjectDetails;
}
/**
 * 
 * @export
 * @interface ResultWithExtraMetadataAllOfResultHistory
 */
export interface ResultWithExtraMetadataAllOfResultHistory {
    /**
     * 
     * @type {string}
     * @memberof ResultWithExtraMetadataAllOfResultHistory
     */
    'datetime': string;
    /**
     * 
     * @type {string}
     * @memberof ResultWithExtraMetadataAllOfResultHistory
     */
    'status': ResultWithExtraMetadataAllOfResultHistoryStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ResultWithExtraMetadataAllOfResultHistory
     */
    'actor'?: string | null;
}

export const ResultWithExtraMetadataAllOfResultHistoryStatusEnum = {
    Activated: 'ACTIVATED',
    AtLab: 'AT_LAB',
    ResultsReady: 'RESULTS_READY',
    ReportReleased: 'REPORT_RELEASED',
    SampleRejected: 'SAMPLE_REJECTED',
    ExpectedReportReleased: 'EXPECTED_REPORT_RELEASED'
} as const;

export type ResultWithExtraMetadataAllOfResultHistoryStatusEnum = typeof ResultWithExtraMetadataAllOfResultHistoryStatusEnum[keyof typeof ResultWithExtraMetadataAllOfResultHistoryStatusEnum];

/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'roleId': string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    'version': number;
}
/**
 * 
 * @export
 * @interface Team
 */
export interface Team {
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'teamId': string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof Team
     */
    'version': number;
    /**
     * 
     * @type {Organisation}
     * @memberof Team
     */
    'organisation'?: Organisation | null;
    /**
     * 
     * @type {Array<AdminWithExtra>}
     * @memberof Team
     */
    'admins'?: Array<AdminWithExtra>;
    /**
     * 
     * @type {Array<Team>}
     * @memberof Team
     */
    'team'?: Array<Team>;
}
/**
 * 
 * @export
 * @interface UpdateAdminRequest
 */
export interface UpdateAdminRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAdminRequest
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminRequest
     */
    'alias'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminRequest
     */
    'alternateEmail'?: string;
    /**
     * 
     * @type {UpdateAdminRequestPhone}
     * @memberof UpdateAdminRequest
     */
    'phone'?: UpdateAdminRequestPhone;
}
/**
 * 
 * @export
 * @interface UpdateAdminRequestPhone
 */
export interface UpdateAdminRequestPhone {
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminRequestPhone
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminRequestPhone
     */
    'number': string;
}
/**
 * 
 * @export
 * @interface UpdateNotificationRequest
 */
export interface UpdateNotificationRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateNotificationRequest
     */
    'titleKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotificationRequest
     */
    'contentKey'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationRequest
     */
    'read'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotificationRequest
     */
    'resultId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationRequest
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateOrganisation400Response
 */
export interface UpdateOrganisation400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganisation400Response
     */
    'error_code': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganisation400Response
     */
    'error_message': string;
}
/**
 * 
 * @export
 * @interface UpdateOrganisationRequest
 */
export interface UpdateOrganisationRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganisationRequest
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOrganisationRequest
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateProfileRequest
 */
export interface UpdateProfileRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProfileRequest
     */
    'urgent'?: boolean;
    /**
     * 
     * @type {HealthUpsert}
     * @memberof UpdateProfileRequest
     */
    'health'?: HealthUpsert;
    /**
     * 
     * @type {NameUpsert}
     * @memberof UpdateProfileRequest
     */
    'name'?: NameUpsert;
    /**
     * External User ID of the customer/patient - usually this ID link to an entity in an external system
     * @type {string}
     * @memberof UpdateProfileRequest
     */
    'externalUserId'?: string;
    /**
     * 
     * @type {UpdateProfileRequestEmail}
     * @memberof UpdateProfileRequest
     */
    'email'?: UpdateProfileRequestEmail;
    /**
     * 
     * @type {UpdateProfileRequestPhone}
     * @memberof UpdateProfileRequest
     */
    'phone'?: UpdateProfileRequestPhone;
}
/**
 * 
 * @export
 * @interface UpdateProfileRequestEmail
 */
export interface UpdateProfileRequestEmail {
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileRequestEmail
     */
    'emailId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileRequestEmail
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface UpdateProfileRequestPhone
 */
export interface UpdateProfileRequestPhone {
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileRequestPhone
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileRequestPhone
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileRequestPhone
     */
    'phoneId': string;
}
/**
 * 
 * @export
 * @interface UpdateProfileRequestPhoneAllOf
 */
export interface UpdateProfileRequestPhoneAllOf {
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileRequestPhoneAllOf
     */
    'phoneId': string;
}
/**
 * 
 * @export
 * @interface UpdateResultRequest
 */
export interface UpdateResultRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateResultRequest
     */
    'urgent'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateResultV2Request
 */
export interface UpdateResultV2Request {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateResultV2Request
     */
    'decoupleProfile'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateTaskRequest
 */
export interface UpdateTaskRequest {
    /**
     * 
     * @type {UpdateTaskRequestDetail}
     * @memberof UpdateTaskRequest
     */
    'detail'?: UpdateTaskRequestDetail;
    /**
     * date as YYYY-MM-DD HH:mm:ss
     * @type {string}
     * @memberof UpdateTaskRequest
     */
    'schedule'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskRequest
     */
    'status'?: UpdateTaskRequestStatusEnum;
}

export const UpdateTaskRequestStatusEnum = {
    Pending: 'pending',
    Progress: 'progress',
    Error: 'error',
    Locked: 'locked',
    Duplicate: 'duplicate',
    Processed: 'processed',
    Prelocked: 'prelocked'
} as const;

export type UpdateTaskRequestStatusEnum = typeof UpdateTaskRequestStatusEnum[keyof typeof UpdateTaskRequestStatusEnum];

/**
 * details to update
 * @export
 * @interface UpdateTaskRequestDetail
 */
export interface UpdateTaskRequestDetail {
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskRequestDetail
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskRequestDetail
     */
    'kitId'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const WeightUnit = {
    Kg: 'kg',
    Lb: 'lb'
} as const;

export type WeightUnit = typeof WeightUnit[keyof typeof WeightUnit];



/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create administrator for organisation or team
         * @param {CreateAdminRequest} [createAdminRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdmin: async (createAdminRequest?: CreateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get administrator for organisation or team
         * @param {string} [externalAdminId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdmin: async (externalAdminId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (externalAdminId !== undefined) {
                localVarQueryParameter['externalAdminId'] = externalAdminId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of admins by externalAdminIds
         * @param {ListAdminRequest} [listAdminRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdmin: async (listAdminRequest?: ListAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/admin/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Admin
         * @param {string} adminId 
         * @param {UpdateAdminRequest} [updateAdminRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdmin: async (adminId: string, updateAdminRequest?: UpdateAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminId' is not null or undefined
            assertParamExists('updateAdmin', 'adminId', adminId)
            const localVarPath = `/v1.0/admin/{adminId}`
                .replace(`{${"adminId"}}`, encodeURIComponent(String(adminId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create administrator for organisation or team
         * @param {CreateAdminRequest} [createAdminRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdmin(createAdminRequest?: CreateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Admin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAdmin(createAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get administrator for organisation or team
         * @param {string} [externalAdminId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdmin(externalAdminId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminWithExtra>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdmin(externalAdminId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of admins by externalAdminIds
         * @param {ListAdminRequest} [listAdminRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAdmin(listAdminRequest?: ListAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAdmin(listAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Admin
         * @param {string} adminId 
         * @param {UpdateAdminRequest} [updateAdminRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdmin(adminId: string, updateAdminRequest?: UpdateAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdmin(adminId, updateAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Create administrator for organisation or team
         * @param {CreateAdminRequest} [createAdminRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdmin(createAdminRequest?: CreateAdminRequest, options?: any): AxiosPromise<Admin> {
            return localVarFp.createAdmin(createAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get administrator for organisation or team
         * @param {string} [externalAdminId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdmin(externalAdminId?: string, options?: any): AxiosPromise<AdminWithExtra> {
            return localVarFp.getAdmin(externalAdminId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of admins by externalAdminIds
         * @param {ListAdminRequest} [listAdminRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdmin(listAdminRequest?: ListAdminRequest, options?: any): AxiosPromise<ListAdmin200Response> {
            return localVarFp.listAdmin(listAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Admin
         * @param {string} adminId 
         * @param {UpdateAdminRequest} [updateAdminRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdmin(adminId: string, updateAdminRequest?: UpdateAdminRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateAdmin(adminId, updateAdminRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @summary Create administrator for organisation or team
     * @param {CreateAdminRequest} [createAdminRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public createAdmin(createAdminRequest?: CreateAdminRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).createAdmin(createAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get administrator for organisation or team
     * @param {string} [externalAdminId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdmin(externalAdminId?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdmin(externalAdminId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of admins by externalAdminIds
     * @param {ListAdminRequest} [listAdminRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public listAdmin(listAdminRequest?: ListAdminRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).listAdmin(listAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Admin
     * @param {string} adminId 
     * @param {UpdateAdminRequest} [updateAdminRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public updateAdmin(adminId: string, updateAdminRequest?: UpdateAdminRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).updateAdmin(adminId, updateAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Notifications by organisationId or teamId
         * @param {string} [organisationId] 
         * @param {string} [teamId] 
         * @param {number} [endingDay] 
         * @param {number} [startingDay] 
         * @param {boolean} [read] 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: async (organisationId?: string, teamId?: string, endingDay?: number, startingDay?: number, read?: boolean, from?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2bAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organisationId !== undefined) {
                localVarQueryParameter['organisationId'] = organisationId;
            }

            if (teamId !== undefined) {
                localVarQueryParameter['teamId'] = teamId;
            }

            if (endingDay !== undefined) {
                localVarQueryParameter['endingDay'] = endingDay;
            }

            if (startingDay !== undefined) {
                localVarQueryParameter['startingDay'] = startingDay;
            }

            if (read !== undefined) {
                localVarQueryParameter['read'] = read;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Notification
         * @param {string} notificationId 
         * @param {UpdateNotificationRequest} [updateNotificationRequest] The body to update notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotification: async (notificationId: string, updateNotificationRequest?: UpdateNotificationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('updateNotification', 'notificationId', notificationId)
            const localVarPath = `/v1.0/notification/{notificationId}`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2bAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNotificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Notifications by organisationId or teamId
         * @param {string} [organisationId] 
         * @param {string} [teamId] 
         * @param {number} [endingDay] 
         * @param {number} [startingDay] 
         * @param {boolean} [read] 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications(organisationId?: string, teamId?: string, endingDay?: number, startingDay?: number, read?: boolean, from?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNotifications200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotifications(organisationId, teamId, endingDay, startingDay, read, from, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Notification
         * @param {string} notificationId 
         * @param {UpdateNotificationRequest} [updateNotificationRequest] The body to update notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotification(notificationId: string, updateNotificationRequest?: UpdateNotificationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotification(notificationId, updateNotificationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Notifications by organisationId or teamId
         * @param {string} [organisationId] 
         * @param {string} [teamId] 
         * @param {number} [endingDay] 
         * @param {number} [startingDay] 
         * @param {boolean} [read] 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(organisationId?: string, teamId?: string, endingDay?: number, startingDay?: number, read?: boolean, from?: number, size?: number, options?: any): AxiosPromise<GetNotifications200Response> {
            return localVarFp.getNotifications(organisationId, teamId, endingDay, startingDay, read, from, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Notification
         * @param {string} notificationId 
         * @param {UpdateNotificationRequest} [updateNotificationRequest] The body to update notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotification(notificationId: string, updateNotificationRequest?: UpdateNotificationRequest, options?: any): AxiosPromise<Notification> {
            return localVarFp.updateNotification(notificationId, updateNotificationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @summary Get Notifications by organisationId or teamId
     * @param {string} [organisationId] 
     * @param {string} [teamId] 
     * @param {number} [endingDay] 
     * @param {number} [startingDay] 
     * @param {boolean} [read] 
     * @param {number} [from] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getNotifications(organisationId?: string, teamId?: string, endingDay?: number, startingDay?: number, read?: boolean, from?: number, size?: number, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).getNotifications(organisationId, teamId, endingDay, startingDay, read, from, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Notification
     * @param {string} notificationId 
     * @param {UpdateNotificationRequest} [updateNotificationRequest] The body to update notification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public updateNotification(notificationId: string, updateNotificationRequest?: UpdateNotificationRequest, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).updateNotification(notificationId, updateNotificationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganisationApi - axios parameter creator
 * @export
 */
export const OrganisationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add barcode to organisation
         * @param {string} organisationId 
         * @param {AddBarcodeToOrganisationRequest} [addBarcodeToOrganisationRequest] The body to add barcode to organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBarcodeToOrganisation: async (organisationId: string, addBarcodeToOrganisationRequest?: AddBarcodeToOrganisationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('addBarcodeToOrganisation', 'organisationId', organisationId)
            const localVarPath = `/v1.0/organisation/{organisationId}/barcode`
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addBarcodeToOrganisationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Organisation
         * @param {CreateOrganisationRequest} [createOrganisationRequest] The body to create organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganisation: async (createOrganisationRequest?: CreateOrganisationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/organisation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrganisationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Organisation
         * @param {string} organisationId 
         * @param {boolean} [adminDetails] 
         * @param {boolean} [teamDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisation: async (organisationId: string, adminDetails?: boolean, teamDetails?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('getOrganisation', 'organisationId', organisationId)
            const localVarPath = `/v1.0/organisation/{organisationId}`
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (adminDetails !== undefined) {
                localVarQueryParameter['adminDetails'] = adminDetails;
            }

            if (teamDetails !== undefined) {
                localVarQueryParameter['teamDetails'] = teamDetails;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Organisation Barcode
         * @param {string} organisationId 
         * @param {string} barcode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisationBarcode: async (organisationId: string, barcode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('getOrganisationBarcode', 'organisationId', organisationId)
            // verify required parameter 'barcode' is not null or undefined
            assertParamExists('getOrganisationBarcode', 'barcode', barcode)
            const localVarPath = `/v1.0/organisation/{organisationId}/barcode`
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2bAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (barcode !== undefined) {
                localVarQueryParameter['barcode'] = barcode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Organisation Overview
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisationOverview: async (organisationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('getOrganisationOverview', 'organisationId', organisationId)
            const localVarPath = `/v1.0/organisation/{organisationId}/overview`
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Organisation Teams
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisationTeams: async (organisationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('getOrganisationTeams', 'organisationId', organisationId)
            const localVarPath = `/v1.0/organisation/{organisationId}/team`
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2bAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Organisations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/organisation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Organisation
         * @param {string} organisationId 
         * @param {UpdateOrganisationRequest} [updateOrganisationRequest] The body to update organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganisation: async (organisationId: string, updateOrganisationRequest?: UpdateOrganisationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('updateOrganisation', 'organisationId', organisationId)
            const localVarPath = `/v1.0/organisation/{organisationId}`
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrganisationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationApi - functional programming interface
 * @export
 */
export const OrganisationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add barcode to organisation
         * @param {string} organisationId 
         * @param {AddBarcodeToOrganisationRequest} [addBarcodeToOrganisationRequest] The body to add barcode to organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBarcodeToOrganisation(organisationId: string, addBarcodeToOrganisationRequest?: AddBarcodeToOrganisationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addBarcodeToOrganisation(organisationId, addBarcodeToOrganisationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Organisation
         * @param {CreateOrganisationRequest} [createOrganisationRequest] The body to create organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrganisation(createOrganisationRequest?: CreateOrganisationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organisation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrganisation(createOrganisationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Organisation
         * @param {string} organisationId 
         * @param {boolean} [adminDetails] 
         * @param {boolean} [teamDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganisation(organisationId: string, adminDetails?: boolean, teamDetails?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organisation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganisation(organisationId, adminDetails, teamDetails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Organisation Barcode
         * @param {string} organisationId 
         * @param {string} barcode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganisationBarcode(organisationId: string, barcode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrganisationBarcode200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganisationBarcode(organisationId, barcode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Organisation Overview
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganisationOverview(organisationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OverviewOrganisation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganisationOverview(organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Organisation Teams
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganisationTeams(organisationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrganisationTeams200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganisationTeams(organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Organisations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganisations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrganisations200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganisations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Organisation
         * @param {string} organisationId 
         * @param {UpdateOrganisationRequest} [updateOrganisationRequest] The body to update organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganisation(organisationId: string, updateOrganisationRequest?: UpdateOrganisationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organisation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganisation(organisationId, updateOrganisationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationApi - factory interface
 * @export
 */
export const OrganisationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationApiFp(configuration)
    return {
        /**
         * 
         * @summary Add barcode to organisation
         * @param {string} organisationId 
         * @param {AddBarcodeToOrganisationRequest} [addBarcodeToOrganisationRequest] The body to add barcode to organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBarcodeToOrganisation(organisationId: string, addBarcodeToOrganisationRequest?: AddBarcodeToOrganisationRequest, options?: any): AxiosPromise<any> {
            return localVarFp.addBarcodeToOrganisation(organisationId, addBarcodeToOrganisationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Organisation
         * @param {CreateOrganisationRequest} [createOrganisationRequest] The body to create organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganisation(createOrganisationRequest?: CreateOrganisationRequest, options?: any): AxiosPromise<Organisation> {
            return localVarFp.createOrganisation(createOrganisationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Organisation
         * @param {string} organisationId 
         * @param {boolean} [adminDetails] 
         * @param {boolean} [teamDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisation(organisationId: string, adminDetails?: boolean, teamDetails?: boolean, options?: any): AxiosPromise<Organisation> {
            return localVarFp.getOrganisation(organisationId, adminDetails, teamDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Organisation Barcode
         * @param {string} organisationId 
         * @param {string} barcode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisationBarcode(organisationId: string, barcode: string, options?: any): AxiosPromise<GetOrganisationBarcode200Response> {
            return localVarFp.getOrganisationBarcode(organisationId, barcode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Organisation Overview
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisationOverview(organisationId: string, options?: any): AxiosPromise<OverviewOrganisation> {
            return localVarFp.getOrganisationOverview(organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Organisation Teams
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisationTeams(organisationId: string, options?: any): AxiosPromise<GetOrganisationTeams200Response> {
            return localVarFp.getOrganisationTeams(organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Organisations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisations(options?: any): AxiosPromise<GetOrganisations200Response> {
            return localVarFp.getOrganisations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Organisation
         * @param {string} organisationId 
         * @param {UpdateOrganisationRequest} [updateOrganisationRequest] The body to update organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganisation(organisationId: string, updateOrganisationRequest?: UpdateOrganisationRequest, options?: any): AxiosPromise<Organisation> {
            return localVarFp.updateOrganisation(organisationId, updateOrganisationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganisationApi - object-oriented interface
 * @export
 * @class OrganisationApi
 * @extends {BaseAPI}
 */
export class OrganisationApi extends BaseAPI {
    /**
     * 
     * @summary Add barcode to organisation
     * @param {string} organisationId 
     * @param {AddBarcodeToOrganisationRequest} [addBarcodeToOrganisationRequest] The body to add barcode to organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public addBarcodeToOrganisation(organisationId: string, addBarcodeToOrganisationRequest?: AddBarcodeToOrganisationRequest, options?: AxiosRequestConfig) {
        return OrganisationApiFp(this.configuration).addBarcodeToOrganisation(organisationId, addBarcodeToOrganisationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Organisation
     * @param {CreateOrganisationRequest} [createOrganisationRequest] The body to create organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public createOrganisation(createOrganisationRequest?: CreateOrganisationRequest, options?: AxiosRequestConfig) {
        return OrganisationApiFp(this.configuration).createOrganisation(createOrganisationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Organisation
     * @param {string} organisationId 
     * @param {boolean} [adminDetails] 
     * @param {boolean} [teamDetails] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public getOrganisation(organisationId: string, adminDetails?: boolean, teamDetails?: boolean, options?: AxiosRequestConfig) {
        return OrganisationApiFp(this.configuration).getOrganisation(organisationId, adminDetails, teamDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Organisation Barcode
     * @param {string} organisationId 
     * @param {string} barcode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public getOrganisationBarcode(organisationId: string, barcode: string, options?: AxiosRequestConfig) {
        return OrganisationApiFp(this.configuration).getOrganisationBarcode(organisationId, barcode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Organisation Overview
     * @param {string} organisationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public getOrganisationOverview(organisationId: string, options?: AxiosRequestConfig) {
        return OrganisationApiFp(this.configuration).getOrganisationOverview(organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Organisation Teams
     * @param {string} organisationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public getOrganisationTeams(organisationId: string, options?: AxiosRequestConfig) {
        return OrganisationApiFp(this.configuration).getOrganisationTeams(organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Organisations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public getOrganisations(options?: AxiosRequestConfig) {
        return OrganisationApiFp(this.configuration).getOrganisations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Organisation
     * @param {string} organisationId 
     * @param {UpdateOrganisationRequest} [updateOrganisationRequest] The body to update organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public updateOrganisation(organisationId: string, updateOrganisationRequest?: UpdateOrganisationRequest, options?: AxiosRequestConfig) {
        return OrganisationApiFp(this.configuration).updateOrganisation(organisationId, updateOrganisationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create profile result
         * @param {string} profileId 
         * @param {CreateResultRequest} [createResultRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResult: async (profileId: string, createResultRequest?: CreateResultRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('createResult', 'profileId', profileId)
            const localVarPath = `/v1.0/profile/{profileId}/result`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2bAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createResultRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Profile by profile id
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileById: async (profileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('getProfileById', 'profileId', profileId)
            const localVarPath = `/v1.0/profile/{profileId}`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2bAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Profiles by team id of organisation id
         * @param {string} [teamId] 
         * @param {string} [organisationId] 
         * @param {number} [from] 
         * @param {string} [search] 
         * @param {number} [size] 
         * @param {boolean} [teamDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfiles: async (teamId?: string, organisationId?: string, from?: number, search?: string, size?: number, teamDetails?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2bAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (teamId !== undefined) {
                localVarQueryParameter['teamId'] = teamId;
            }

            if (organisationId !== undefined) {
                localVarQueryParameter['organisationId'] = organisationId;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (teamDetails !== undefined) {
                localVarQueryParameter['teamDetails'] = teamDetails;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transfer Profile
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferProfile: async (profileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('transferProfile', 'profileId', profileId)
            const localVarPath = `/v1.0/profile/{profileId}/transfer`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2bAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Profile
         * @param {string} profileId 
         * @param {UpdateProfileRequest} [updateProfileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile: async (profileId: string, updateProfileRequest?: UpdateProfileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('updateProfile', 'profileId', profileId)
            const localVarPath = `/v1.0/profile/{profileId}`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2bAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProfileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create profile result
         * @param {string} profileId 
         * @param {CreateResultRequest} [createResultRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createResult(profileId: string, createResultRequest?: CreateResultRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createResult(profileId, createResultRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Profile by profile id
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileById(profileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfileById(profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Profiles by team id of organisation id
         * @param {string} [teamId] 
         * @param {string} [organisationId] 
         * @param {number} [from] 
         * @param {string} [search] 
         * @param {number} [size] 
         * @param {boolean} [teamDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfiles(teamId?: string, organisationId?: string, from?: number, search?: string, size?: number, teamDetails?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProfiles200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfiles(teamId, organisationId, from, search, size, teamDetails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Transfer Profile
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transferProfile(profileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transferProfile(profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Profile
         * @param {string} profileId 
         * @param {UpdateProfileRequest} [updateProfileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProfile(profileId: string, updateProfileRequest?: UpdateProfileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProfile(profileId, updateProfileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileApiFp(configuration)
    return {
        /**
         * 
         * @summary Create profile result
         * @param {string} profileId 
         * @param {CreateResultRequest} [createResultRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResult(profileId: string, createResultRequest?: CreateResultRequest, options?: any): AxiosPromise<Result> {
            return localVarFp.createResult(profileId, createResultRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Profile by profile id
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileById(profileId: string, options?: any): AxiosPromise<Profile> {
            return localVarFp.getProfileById(profileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Profiles by team id of organisation id
         * @param {string} [teamId] 
         * @param {string} [organisationId] 
         * @param {number} [from] 
         * @param {string} [search] 
         * @param {number} [size] 
         * @param {boolean} [teamDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfiles(teamId?: string, organisationId?: string, from?: number, search?: string, size?: number, teamDetails?: boolean, options?: any): AxiosPromise<GetProfiles200Response> {
            return localVarFp.getProfiles(teamId, organisationId, from, search, size, teamDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transfer Profile
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferProfile(profileId: string, options?: any): AxiosPromise<object> {
            return localVarFp.transferProfile(profileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Profile
         * @param {string} profileId 
         * @param {UpdateProfileRequest} [updateProfileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile(profileId: string, updateProfileRequest?: UpdateProfileRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateProfile(profileId, updateProfileRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * 
     * @summary Create profile result
     * @param {string} profileId 
     * @param {CreateResultRequest} [createResultRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public createResult(profileId: string, createResultRequest?: CreateResultRequest, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).createResult(profileId, createResultRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Profile by profile id
     * @param {string} profileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public getProfileById(profileId: string, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).getProfileById(profileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Profiles by team id of organisation id
     * @param {string} [teamId] 
     * @param {string} [organisationId] 
     * @param {number} [from] 
     * @param {string} [search] 
     * @param {number} [size] 
     * @param {boolean} [teamDetails] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public getProfiles(teamId?: string, organisationId?: string, from?: number, search?: string, size?: number, teamDetails?: boolean, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).getProfiles(teamId, organisationId, from, search, size, teamDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Transfer Profile
     * @param {string} profileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public transferProfile(profileId: string, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).transferProfile(profileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Profile
     * @param {string} profileId 
     * @param {UpdateProfileRequest} [updateProfileRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public updateProfile(profileId: string, updateProfileRequest?: UpdateProfileRequest, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).updateProfile(profileId, updateProfileRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ResultApi - axios parameter creator
 * @export
 */
export const ResultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Result Report by id and file type
         * @param {string} resultId 
         * @param {string} reportId 
         * @param {'pdf'} fileType 
         * @param {'en-HK' | 'zh-Hans-HK' | 'zh-Hant-HK' | 'th-TH' | 'ja-JP'} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportById: async (resultId: string, reportId: string, fileType: 'pdf', language?: 'en-HK' | 'zh-Hans-HK' | 'zh-Hant-HK' | 'th-TH' | 'ja-JP', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resultId' is not null or undefined
            assertParamExists('getReportById', 'resultId', resultId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getReportById', 'reportId', reportId)
            // verify required parameter 'fileType' is not null or undefined
            assertParamExists('getReportById', 'fileType', fileType)
            const localVarPath = `/v1.0/result/{resultId}/report/{reportId}/{fileType}`
                .replace(`{${"resultId"}}`, encodeURIComponent(String(resultId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"fileType"}}`, encodeURIComponent(String(fileType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2bAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Result by id
         * @param {string} resultId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResult: async (resultId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resultId' is not null or undefined
            assertParamExists('getResult', 'resultId', resultId)
            const localVarPath = `/v1.0/result/{resultId}`
                .replace(`{${"resultId"}}`, encodeURIComponent(String(resultId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2bAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get result overview
         * @param {string} [profileId] 
         * @param {string} [teamId] 
         * @param {string} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResultOverview: async (profileId?: string, teamId?: string, organisationId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/result/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2bAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (profileId !== undefined) {
                localVarQueryParameter['profileId'] = profileId;
            }

            if (teamId !== undefined) {
                localVarQueryParameter['teamId'] = teamId;
            }

            if (organisationId !== undefined) {
                localVarQueryParameter['organisationId'] = organisationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Results by team id of organisation id
         * @param {string} [profileId] 
         * @param {string} [teamId] 
         * @param {string} [organisationId] 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {ResultStatus} [status] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResults: async (profileId?: string, teamId?: string, organisationId?: string, from?: number, size?: number, status?: ResultStatus, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2bAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (profileId !== undefined) {
                localVarQueryParameter['profileId'] = profileId;
            }

            if (teamId !== undefined) {
                localVarQueryParameter['teamId'] = teamId;
            }

            if (organisationId !== undefined) {
                localVarQueryParameter['organisationId'] = organisationId;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Result
         * @param {string} resultId 
         * @param {UpdateResultRequest} [updateResultRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResult: async (resultId: string, updateResultRequest?: UpdateResultRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resultId' is not null or undefined
            assertParamExists('updateResult', 'resultId', resultId)
            const localVarPath = `/v1.0/result/{resultId}`
                .replace(`{${"resultId"}}`, encodeURIComponent(String(resultId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2bAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateResultRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Result V2
         * @param {string} resultId 
         * @param {UpdateResultV2Request} [updateResultV2Request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResultV2: async (resultId: string, updateResultV2Request?: UpdateResultV2Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resultId' is not null or undefined
            assertParamExists('updateResultV2', 'resultId', resultId)
            const localVarPath = `/v2.0/result/{resultId}`
                .replace(`{${"resultId"}}`, encodeURIComponent(String(resultId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2bAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateResultV2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResultApi - functional programming interface
 * @export
 */
export const ResultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ResultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Result Report by id and file type
         * @param {string} resultId 
         * @param {string} reportId 
         * @param {'pdf'} fileType 
         * @param {'en-HK' | 'zh-Hans-HK' | 'zh-Hant-HK' | 'th-TH' | 'ja-JP'} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportById(resultId: string, reportId: string, fileType: 'pdf', language?: 'en-HK' | 'zh-Hans-HK' | 'zh-Hant-HK' | 'th-TH' | 'ja-JP', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportById(resultId, reportId, fileType, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Result by id
         * @param {string} resultId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResult(resultId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithExtraMetadata>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResult(resultId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get result overview
         * @param {string} [profileId] 
         * @param {string} [teamId] 
         * @param {string} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResultOverview(profileId?: string, teamId?: string, organisationId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetResultOverview200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResultOverview(profileId, teamId, organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Results by team id of organisation id
         * @param {string} [profileId] 
         * @param {string} [teamId] 
         * @param {string} [organisationId] 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {ResultStatus} [status] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResults(profileId?: string, teamId?: string, organisationId?: string, from?: number, size?: number, status?: ResultStatus, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetResults200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResults(profileId, teamId, organisationId, from, size, status, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Result
         * @param {string} resultId 
         * @param {UpdateResultRequest} [updateResultRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateResult(resultId: string, updateResultRequest?: UpdateResultRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateResult(resultId, updateResultRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Result V2
         * @param {string} resultId 
         * @param {UpdateResultV2Request} [updateResultV2Request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateResultV2(resultId: string, updateResultV2Request?: UpdateResultV2Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateResultV2(resultId, updateResultV2Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ResultApi - factory interface
 * @export
 */
export const ResultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ResultApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Result Report by id and file type
         * @param {string} resultId 
         * @param {string} reportId 
         * @param {'pdf'} fileType 
         * @param {'en-HK' | 'zh-Hans-HK' | 'zh-Hant-HK' | 'th-TH' | 'ja-JP'} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportById(resultId: string, reportId: string, fileType: 'pdf', language?: 'en-HK' | 'zh-Hans-HK' | 'zh-Hant-HK' | 'th-TH' | 'ja-JP', options?: any): AxiosPromise<any> {
            return localVarFp.getReportById(resultId, reportId, fileType, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Result by id
         * @param {string} resultId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResult(resultId: string, options?: any): AxiosPromise<ResultWithExtraMetadata> {
            return localVarFp.getResult(resultId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get result overview
         * @param {string} [profileId] 
         * @param {string} [teamId] 
         * @param {string} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResultOverview(profileId?: string, teamId?: string, organisationId?: string, options?: any): AxiosPromise<GetResultOverview200Response> {
            return localVarFp.getResultOverview(profileId, teamId, organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Results by team id of organisation id
         * @param {string} [profileId] 
         * @param {string} [teamId] 
         * @param {string} [organisationId] 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {ResultStatus} [status] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResults(profileId?: string, teamId?: string, organisationId?: string, from?: number, size?: number, status?: ResultStatus, search?: string, options?: any): AxiosPromise<GetResults200Response> {
            return localVarFp.getResults(profileId, teamId, organisationId, from, size, status, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Result
         * @param {string} resultId 
         * @param {UpdateResultRequest} [updateResultRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResult(resultId: string, updateResultRequest?: UpdateResultRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateResult(resultId, updateResultRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Result V2
         * @param {string} resultId 
         * @param {UpdateResultV2Request} [updateResultV2Request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResultV2(resultId: string, updateResultV2Request?: UpdateResultV2Request, options?: any): AxiosPromise<object> {
            return localVarFp.updateResultV2(resultId, updateResultV2Request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ResultApi - object-oriented interface
 * @export
 * @class ResultApi
 * @extends {BaseAPI}
 */
export class ResultApi extends BaseAPI {
    /**
     * 
     * @summary Get Result Report by id and file type
     * @param {string} resultId 
     * @param {string} reportId 
     * @param {'pdf'} fileType 
     * @param {'en-HK' | 'zh-Hans-HK' | 'zh-Hant-HK' | 'th-TH' | 'ja-JP'} [language] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResultApi
     */
    public getReportById(resultId: string, reportId: string, fileType: 'pdf', language?: 'en-HK' | 'zh-Hans-HK' | 'zh-Hant-HK' | 'th-TH' | 'ja-JP', options?: AxiosRequestConfig) {
        return ResultApiFp(this.configuration).getReportById(resultId, reportId, fileType, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Result by id
     * @param {string} resultId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResultApi
     */
    public getResult(resultId: string, options?: AxiosRequestConfig) {
        return ResultApiFp(this.configuration).getResult(resultId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get result overview
     * @param {string} [profileId] 
     * @param {string} [teamId] 
     * @param {string} [organisationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResultApi
     */
    public getResultOverview(profileId?: string, teamId?: string, organisationId?: string, options?: AxiosRequestConfig) {
        return ResultApiFp(this.configuration).getResultOverview(profileId, teamId, organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Results by team id of organisation id
     * @param {string} [profileId] 
     * @param {string} [teamId] 
     * @param {string} [organisationId] 
     * @param {number} [from] 
     * @param {number} [size] 
     * @param {ResultStatus} [status] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResultApi
     */
    public getResults(profileId?: string, teamId?: string, organisationId?: string, from?: number, size?: number, status?: ResultStatus, search?: string, options?: AxiosRequestConfig) {
        return ResultApiFp(this.configuration).getResults(profileId, teamId, organisationId, from, size, status, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Result
     * @param {string} resultId 
     * @param {UpdateResultRequest} [updateResultRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResultApi
     */
    public updateResult(resultId: string, updateResultRequest?: UpdateResultRequest, options?: AxiosRequestConfig) {
        return ResultApiFp(this.configuration).updateResult(resultId, updateResultRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Result V2
     * @param {string} resultId 
     * @param {UpdateResultV2Request} [updateResultV2Request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResultApi
     */
    public updateResultV2(resultId: string, updateResultV2Request?: UpdateResultV2Request, options?: AxiosRequestConfig) {
        return ResultApiFp(this.configuration).updateResultV2(resultId, updateResultV2Request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaskApi - axios parameter creator
 * @export
 */
export const TaskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Task
         * @param {CreateTaskRequest} createTaskRequest The body to create task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTask: async (createTaskRequest: CreateTaskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTaskRequest' is not null or undefined
            assertParamExists('createTask', 'createTaskRequest', createTaskRequest)
            const localVarPath = `/v1.0/task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTaskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Task
         * @param {string} taskid 
         * @param {UpdateTaskRequest} updateTaskRequest The body to update a task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTask: async (taskid: string, updateTaskRequest: UpdateTaskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskid' is not null or undefined
            assertParamExists('updateTask', 'taskid', taskid)
            // verify required parameter 'updateTaskRequest' is not null or undefined
            assertParamExists('updateTask', 'updateTaskRequest', updateTaskRequest)
            const localVarPath = `/v1.0/task/{taskid}`
                .replace(`{${"taskid"}}`, encodeURIComponent(String(taskid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTaskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskApi - functional programming interface
 * @export
 */
export const TaskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaskApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Task
         * @param {CreateTaskRequest} createTaskRequest The body to create task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTask(createTaskRequest: CreateTaskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTask201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTask(createTaskRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a Task
         * @param {string} taskid 
         * @param {UpdateTaskRequest} updateTaskRequest The body to update a task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTask(taskid: string, updateTaskRequest: UpdateTaskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTask(taskid, updateTaskRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaskApi - factory interface
 * @export
 */
export const TaskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaskApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Task
         * @param {CreateTaskRequest} createTaskRequest The body to create task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTask(createTaskRequest: CreateTaskRequest, options?: any): AxiosPromise<CreateTask201Response> {
            return localVarFp.createTask(createTaskRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Task
         * @param {string} taskid 
         * @param {UpdateTaskRequest} updateTaskRequest The body to update a task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTask(taskid: string, updateTaskRequest: UpdateTaskRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateTask(taskid, updateTaskRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaskApi - object-oriented interface
 * @export
 * @class TaskApi
 * @extends {BaseAPI}
 */
export class TaskApi extends BaseAPI {
    /**
     * 
     * @summary Create Task
     * @param {CreateTaskRequest} createTaskRequest The body to create task
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public createTask(createTaskRequest: CreateTaskRequest, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).createTask(createTaskRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Task
     * @param {string} taskid 
     * @param {UpdateTaskRequest} updateTaskRequest The body to update a task
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public updateTask(taskid: string, updateTaskRequest: UpdateTaskRequest, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).updateTask(taskid, updateTaskRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TeamApi - axios parameter creator
 * @export
 */
export const TeamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create profile for a team
         * @param {string} teamId 
         * @param {CreateProfileRequest} [createProfileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProfile: async (teamId: string, createProfileRequest?: CreateProfileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('createProfile', 'teamId', teamId)
            const localVarPath = `/v1.0/team/{teamId}/profile`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2bAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProfileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Team
         * @param {CreateTeamRequest} [createTeamRequest] The body to create team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeam: async (createTeamRequest?: CreateTeamRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/team`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTeamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get team by ID
         * @param {string} teamId 
         * @param {boolean} [adminDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamById: async (teamId: string, adminDetails?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('getTeamById', 'teamId', teamId)
            const localVarPath = `/v1.0/team/{teamId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (adminDetails !== undefined) {
                localVarQueryParameter['adminDetails'] = adminDetails;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamApi - functional programming interface
 * @export
 */
export const TeamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create profile for a team
         * @param {string} teamId 
         * @param {CreateProfileRequest} [createProfileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProfile(teamId: string, createProfileRequest?: CreateProfileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProfile(teamId, createProfileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Team
         * @param {CreateTeamRequest} [createTeamRequest] The body to create team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTeam(createTeamRequest?: CreateTeamRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTeam(createTeamRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get team by ID
         * @param {string} teamId 
         * @param {boolean} [adminDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamById(teamId: string, adminDetails?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamById(teamId, adminDetails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeamApi - factory interface
 * @export
 */
export const TeamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamApiFp(configuration)
    return {
        /**
         * 
         * @summary Create profile for a team
         * @param {string} teamId 
         * @param {CreateProfileRequest} [createProfileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProfile(teamId: string, createProfileRequest?: CreateProfileRequest, options?: any): AxiosPromise<Profile> {
            return localVarFp.createProfile(teamId, createProfileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Team
         * @param {CreateTeamRequest} [createTeamRequest] The body to create team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeam(createTeamRequest?: CreateTeamRequest, options?: any): AxiosPromise<Team> {
            return localVarFp.createTeam(createTeamRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get team by ID
         * @param {string} teamId 
         * @param {boolean} [adminDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamById(teamId: string, adminDetails?: boolean, options?: any): AxiosPromise<Team> {
            return localVarFp.getTeamById(teamId, adminDetails, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamApi - object-oriented interface
 * @export
 * @class TeamApi
 * @extends {BaseAPI}
 */
export class TeamApi extends BaseAPI {
    /**
     * 
     * @summary Create profile for a team
     * @param {string} teamId 
     * @param {CreateProfileRequest} [createProfileRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public createProfile(teamId: string, createProfileRequest?: CreateProfileRequest, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).createProfile(teamId, createProfileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Team
     * @param {CreateTeamRequest} [createTeamRequest] The body to create team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public createTeam(createTeamRequest?: CreateTeamRequest, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).createTeam(createTeamRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get team by ID
     * @param {string} teamId 
     * @param {boolean} [adminDetails] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public getTeamById(teamId: string, adminDetails?: boolean, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).getTeamById(teamId, adminDetails, options).then((request) => request(this.axios, this.basePath));
    }
}


