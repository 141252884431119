export enum CountryCodeIso31661 {
    Australia = 'au',
    Canada = 'ca',
    HongKong = 'hk',
    Macau = 'mo',
    Malaysia = 'my',
    NewZealand = 'nz',
    Philippines = 'ph',
    Singapore = 'sg',
    // Taiwan = 'tw',
    Thailand = 'th',
    Uae = 'ae',
    UnitedKingdom = 'gb',
    UnitedStates = 'us',
    Luxembourg = 'lu',
    Austria = 'at',
    Belgium = 'be',
    Colombia = 'co',
    CzechRepublic = 'cz',
    Denmark = 'dk',
    Finland = 'fi',
    Germany = 'de',
    Greece = 'gr',
    Hungary = 'hu',
    Indonesia = 'id',
    Italy = 'it',
    Japan = 'jp',
    // Mexico = 'mx',
    Norway = 'no',
    Poland = 'pl',
    Portugal = 'pt',
    Romania = 'ro',
    // Russia = 'ru',
    Slovakia = 'sk',
    SouthAfrica = 'za',
    SouthKorea = 'kr',
    Spain = 'es',
    Sweden = 'se',
    Switzerland = 'ch',
    Turkey = 'tr',
    Argentina = 'ar',
    Bahrain = 'bh',
    Botswana = 'bw',
    Bulgaria = 'bg',
    CaymanIslands = 'ky',
    Chile = 'cl',
    CostaRica = 'cr',
    Croatia = 'hr',
    Cyprus = 'cy',
    DominicanRepublic = 'do',
    Egypt = 'eg',
    Estonia = 'ee',
    FrenchGuiana = 'gf',
    Georgia = 'ge',
    Ghana = 'gh',
    Gibraltar = 'gi',
    Iceland = 'is',
    Ireland = 'ie',
    Israel = 'il',
    Jordan = 'jo',
    Kuwait = 'kw',
    Latvia = 'lv',
    Lebanon = 'lb',
    Liechtenstein = 'li',
    Lithuania = 'lt',
    NorthMacedonia = 'mk',
    Madagascar = 'mg',
    Malta = 'mt',
    Mauritius = 'mu',
    Monaco = 'mc',
    Morocco = 'ma',
    Namibia = 'na',
    Netherlands = 'nl',
    Oman = 'om',
    Pakistan = 'pk',
    Paraguay = 'py',
    Peru = 'pe',
    Qatar = 'qa',
    SaudiArabia = 'sa',
    Slovenia = 'si',
    // Ukraine = 'ua',
    Uruguay = 'uy',
    Venezuela = 've',
    // Brazil = 'br',
    India = 'in',
    PuertoRico = 'pr',
    Jamaica = 'jm',
    Suriname = 'sr',
    VirginIslandsUS = 'vi',
    Vietnam = 'vn',
}

const gdprStatusByCountry: Record<CountryCodeIso31661, boolean> = {
    [CountryCodeIso31661.Australia]: false,
    [CountryCodeIso31661.Canada]: false,
    [CountryCodeIso31661.HongKong]: false,
    [CountryCodeIso31661.Macau]: false,
    [CountryCodeIso31661.Malaysia]: false,
    [CountryCodeIso31661.NewZealand]: false,
    [CountryCodeIso31661.Philippines]: false,
    [CountryCodeIso31661.Singapore]: false,
    // [CountryCodeIso31661.Taiwan]: false,
    [CountryCodeIso31661.Thailand]: false,
    [CountryCodeIso31661.Uae]: false,
    [CountryCodeIso31661.UnitedKingdom]: true,
    [CountryCodeIso31661.UnitedStates]: false,
    [CountryCodeIso31661.Luxembourg]: true,
    [CountryCodeIso31661.Austria]: true,
    [CountryCodeIso31661.Belgium]: true,
    [CountryCodeIso31661.Colombia]: false,
    [CountryCodeIso31661.CzechRepublic]: true,
    [CountryCodeIso31661.Denmark]: true,
    [CountryCodeIso31661.Finland]: true,
    [CountryCodeIso31661.Germany]: true,
    [CountryCodeIso31661.Greece]: true,
    [CountryCodeIso31661.Hungary]: true,
    [CountryCodeIso31661.Indonesia]: false,
    [CountryCodeIso31661.Italy]: true,
    [CountryCodeIso31661.Japan]: false,
    // [CountryCodeIso31661.Mexico]: false,
    [CountryCodeIso31661.Norway]: true,
    [CountryCodeIso31661.Poland]: true,
    [CountryCodeIso31661.Portugal]: true,
    [CountryCodeIso31661.Romania]: true,
    // [CountryCodeIso31661.Russia]: false,
    [CountryCodeIso31661.Slovakia]: true,
    [CountryCodeIso31661.SouthAfrica]: false,
    [CountryCodeIso31661.SouthKorea]: false,
    [CountryCodeIso31661.Spain]: true,
    [CountryCodeIso31661.Sweden]: true,
    [CountryCodeIso31661.Switzerland]: true,
    [CountryCodeIso31661.Turkey]: false,
    [CountryCodeIso31661.Argentina]: false,
    [CountryCodeIso31661.Bahrain]: false,
    [CountryCodeIso31661.Botswana]: false,
    [CountryCodeIso31661.Bulgaria]: true,
    [CountryCodeIso31661.CaymanIslands]: false,
    [CountryCodeIso31661.Chile]: false,
    [CountryCodeIso31661.CostaRica]: false,
    [CountryCodeIso31661.Croatia]: true,
    [CountryCodeIso31661.Cyprus]: true,
    [CountryCodeIso31661.DominicanRepublic]: false,
    [CountryCodeIso31661.Egypt]: false,
    [CountryCodeIso31661.Estonia]: true,
    [CountryCodeIso31661.FrenchGuiana]: false,
    [CountryCodeIso31661.Georgia]: false,
    [CountryCodeIso31661.Ghana]: false,
    [CountryCodeIso31661.Gibraltar]: false,
    [CountryCodeIso31661.Iceland]: true,
    [CountryCodeIso31661.Ireland]: true,
    [CountryCodeIso31661.Israel]: false,
    [CountryCodeIso31661.Jordan]: false,
    [CountryCodeIso31661.Kuwait]: false,
    [CountryCodeIso31661.Latvia]: true,
    [CountryCodeIso31661.Lebanon]: false,
    [CountryCodeIso31661.Liechtenstein]: true,
    [CountryCodeIso31661.Lithuania]: true,
    [CountryCodeIso31661.NorthMacedonia]: true,
    [CountryCodeIso31661.Madagascar]: false,
    [CountryCodeIso31661.Malta]: true,
    [CountryCodeIso31661.Mauritius]: false,
    [CountryCodeIso31661.Monaco]: true,
    [CountryCodeIso31661.Morocco]: false,
    [CountryCodeIso31661.Namibia]: false,
    [CountryCodeIso31661.Netherlands]: true,
    [CountryCodeIso31661.Oman]: false,
    [CountryCodeIso31661.Pakistan]: false,
    [CountryCodeIso31661.Paraguay]: false,
    [CountryCodeIso31661.Peru]: false,
    [CountryCodeIso31661.Qatar]: false,
    [CountryCodeIso31661.SaudiArabia]: false,
    [CountryCodeIso31661.Slovenia]: true,
    // [CountryCodeIso31661.Ukraine]: false,
    [CountryCodeIso31661.Uruguay]: false,
    [CountryCodeIso31661.Venezuela]: false,
    // [CountryCodeIso31661.Brazil]: false,
    [CountryCodeIso31661.India]: false,
    [CountryCodeIso31661.PuertoRico]: false,
    [CountryCodeIso31661.Jamaica]: false,
    [CountryCodeIso31661.Suriname]: false,
    [CountryCodeIso31661.VirginIslandsUS]: false,
    [CountryCodeIso31661.Vietnam]: false,
};

const intlMarketingRegionCountries: Record<CountryCodeIso31661, boolean> = {
    [CountryCodeIso31661.Australia]: true,
    [CountryCodeIso31661.Canada]: true,
    [CountryCodeIso31661.HongKong]: false,
    [CountryCodeIso31661.Macau]: false,
    [CountryCodeIso31661.Malaysia]: false,
    [CountryCodeIso31661.NewZealand]: true,
    [CountryCodeIso31661.Philippines]: false,
    [CountryCodeIso31661.Singapore]: false,
    // [CountryCodeIso31661.Taiwan]: false,
    [CountryCodeIso31661.Thailand]: false,
    [CountryCodeIso31661.Uae]: true,
    [CountryCodeIso31661.UnitedKingdom]: true,
    [CountryCodeIso31661.UnitedStates]: true,
    [CountryCodeIso31661.Luxembourg]: true,
    [CountryCodeIso31661.Austria]: true,
    [CountryCodeIso31661.Belgium]: true,
    [CountryCodeIso31661.Colombia]: true,
    [CountryCodeIso31661.CzechRepublic]: true,
    [CountryCodeIso31661.Denmark]: true,
    [CountryCodeIso31661.Finland]: true,
    [CountryCodeIso31661.Germany]: true,
    [CountryCodeIso31661.Greece]: true,
    [CountryCodeIso31661.Hungary]: true,
    [CountryCodeIso31661.Indonesia]: false,
    [CountryCodeIso31661.Italy]: true,
    [CountryCodeIso31661.Japan]: false,
    // [CountryCodeIso31661.Mexico]: true,
    [CountryCodeIso31661.Norway]: true,
    [CountryCodeIso31661.Poland]: true,
    [CountryCodeIso31661.Portugal]: true,
    [CountryCodeIso31661.Romania]: true,
    // [CountryCodeIso31661.Russia]: true,
    [CountryCodeIso31661.Slovakia]: true,
    [CountryCodeIso31661.SouthAfrica]: true,
    [CountryCodeIso31661.SouthKorea]: false,
    [CountryCodeIso31661.Spain]: true,
    [CountryCodeIso31661.Sweden]: true,
    [CountryCodeIso31661.Switzerland]: true,
    [CountryCodeIso31661.Turkey]: true,
    [CountryCodeIso31661.Argentina]: true,
    [CountryCodeIso31661.Bahrain]: true,
    [CountryCodeIso31661.Botswana]: true,
    [CountryCodeIso31661.Bulgaria]: true,
    [CountryCodeIso31661.CaymanIslands]: true,
    [CountryCodeIso31661.Chile]: true,
    [CountryCodeIso31661.CostaRica]: true,
    [CountryCodeIso31661.Croatia]: true,
    [CountryCodeIso31661.Cyprus]: true,
    [CountryCodeIso31661.DominicanRepublic]: true,
    [CountryCodeIso31661.Egypt]: true,
    [CountryCodeIso31661.Estonia]: true,
    [CountryCodeIso31661.FrenchGuiana]: true,
    [CountryCodeIso31661.Georgia]: true,
    [CountryCodeIso31661.Ghana]: true,
    [CountryCodeIso31661.Gibraltar]: true,
    [CountryCodeIso31661.Iceland]: true,
    [CountryCodeIso31661.Ireland]: true,
    [CountryCodeIso31661.Israel]: true,
    [CountryCodeIso31661.Jordan]: true,
    [CountryCodeIso31661.Kuwait]: true,
    [CountryCodeIso31661.Latvia]: true,
    [CountryCodeIso31661.Lebanon]: true,
    [CountryCodeIso31661.Liechtenstein]: true,
    [CountryCodeIso31661.Lithuania]: true,
    [CountryCodeIso31661.NorthMacedonia]: true,
    [CountryCodeIso31661.Madagascar]: true,
    [CountryCodeIso31661.Malta]: true,
    [CountryCodeIso31661.Mauritius]: true,
    [CountryCodeIso31661.Monaco]: true,
    [CountryCodeIso31661.Morocco]: true,
    [CountryCodeIso31661.Namibia]: true,
    [CountryCodeIso31661.Netherlands]: true,
    [CountryCodeIso31661.Oman]: true,
    [CountryCodeIso31661.Pakistan]: true,
    [CountryCodeIso31661.Paraguay]: true,
    [CountryCodeIso31661.Peru]: true,
    [CountryCodeIso31661.Qatar]: true,
    [CountryCodeIso31661.SaudiArabia]: true,
    [CountryCodeIso31661.Slovenia]: true,
    // [CountryCodeIso31661.Ukraine]: true,
    [CountryCodeIso31661.Uruguay]: true,
    [CountryCodeIso31661.Venezuela]: true,
    // [CountryCodeIso31661.Brazil]: true,
    [CountryCodeIso31661.India]: true,
    [CountryCodeIso31661.PuertoRico]: true,
    [CountryCodeIso31661.Jamaica]: true,
    [CountryCodeIso31661.Suriname]: true,
    [CountryCodeIso31661.VirginIslandsUS]: true,
    [CountryCodeIso31661.Vietnam]: false,
};

export function gdprApplies(country: CountryCodeIso31661): boolean {
    return gdprStatusByCountry[country];
}

export function getGdprCountries(): CountryCodeIso31661[] {
    return Object.entries(gdprStatusByCountry)
        .filter(([, gdprIsApplicable]) => gdprIsApplicable)
        .map(([country]) => country as CountryCodeIso31661);
}

export function isCountryIntl(country: CountryCodeIso31661): boolean {
    return intlMarketingRegionCountries[country];
}

export function isNACountry(country: CountryCodeIso31661): boolean {
    return [CountryCodeIso31661.UnitedStates, CountryCodeIso31661.Canada].includes(country);
}

export const countriesFullNameByIso: Record<CountryCodeIso31661, string> = {
    au: 'Australia',
    hk: 'Hong Kong',
    mo: 'Macau',
    my: 'Malaysia',
    nz: 'New Zealand',
    ph: 'Philippines',
    sg: 'Singapore',
    th: 'Thailand',
    gb: 'United Kingdom',
    us: 'United States',
    ca: 'Canada',
    ae: 'United Arab Emirates',
    // tw: 'Taiwan',
    lu: 'Luxembourg',
    at: 'Austria',
    be: 'Belgium',
    co: 'Colombia',
    cz: 'Czech Republic',
    dk: 'Denmark',
    fi: 'Finland',
    de: 'Germany',
    gr: 'Greece',
    hu: 'Hungary',
    id: 'Indonesia',
    it: 'Italy',
    jp: 'Japan',
    // mx: 'Mexico',
    no: 'Norway',
    pl: 'Poland',
    pt: 'Portugal',
    ro: 'Romania',
    // ru: 'Russia',
    sk: 'Slovak Republic',
    za: 'South Africa',
    kr: 'South Korea',
    es: 'Spain',
    se: 'Sweden',
    ch: 'Switzerland',
    tr: 'Turkey',
    ar: 'Argentina',
    bh: 'Bahrain',
    bw: 'Botswana',
    bg: 'Bulgaria',
    ky: 'Cayman Islands',
    cl: 'Chile',
    cr: 'Costa Rica',
    hr: 'Croatia',
    cy: 'Cyprus',
    do: 'Dominican Republic',
    eg: 'Egypt',
    ee: 'Estonia',
    gf: 'French Guiana',
    ge: 'Georgia',
    gh: 'Ghana',
    gi: 'Gibraltar',
    is: 'Iceland',
    ie: 'Ireland',
    il: 'Israel',
    jo: 'Jordan',
    kw: 'Kuwait',
    lv: 'Latvia',
    lb: 'Lebanon',
    li: 'Liechtenstein',
    lt: 'Lithuania',
    mk: 'North Macedonia',
    mg: 'Madagascar',
    mt: 'Malta',
    mu: 'Mauritius',
    mc: 'Monaco',
    ma: 'Morocco',
    na: 'Namibia',
    nl: 'Netherlands',
    om: 'Oman',
    pk: 'Pakistan',
    py: 'Paraguay',
    pe: 'Peru',
    qa: 'Qatar',
    sa: 'Saudi Arabia',
    si: 'Slovenia',
    // ua: 'Ukraine',
    uy: 'Uruguay',
    ve: 'Venezuela',
    // br: 'Brazil',
    in: 'India',
    pr: 'Puerto Rico',
    jm: 'Jamaica',
    sr: 'Suriname',
    vi: 'U.S. Virgin Islands',
    vn: 'Vietnam',
};

export const CDNA_UNSUPPORTED_COUNTRIES: CountryCodeIso31661[] = [CountryCodeIso31661.Thailand];
