import '@components/Card/card.scss';
import './notificationInterval.scss';

import ChevronLeftC5 from '@assets/images/ChevronLeftC5.svg?react';
import NoNotificationAvatar from '@assets/images/noNotification.svg?react';
import { NotificationIntervalList } from '@components/NotificationIntervalList';
import { API_BASE_URL } from '@config';
import { Button, Loader, Pagination, Typography } from '@prenetics/prenetics-react-library';
import { notify } from '@prenetics/prenetics-react-library/lib/Toast';
import { AdminContext } from '@providers/AdminProvider';
import { ApiUpdateContext } from '@providers/ApiUpdateProvider';
import { Notification, NotificationApiFactory } from '@services/api/b2b';
import colors from '@theme/colors.module.scss';
import { isAxiosError } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const b2bNotifcationApi = NotificationApiFactory(undefined, API_BASE_URL + '/b2b');

const PAGE_SIZE = 10;

export const NotificationInterval = () => {
    const { adminOrganisationId, adminTeamId } = useContext(AdminContext);
    const { updateSuccess, setUpdateSuccess } = useContext(ApiUpdateContext);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalNotificationCount, setTotalNotifcationCount] = useState(0);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { interval } = useParams();

    useEffect(() => {
        const getDateInterval = () => {
            switch (interval) {
                case 'today': {
                    return { start: 0, end: 1 };
                }
                case 'this_week': {
                    return { start: 1, end: 7 };
                }
                case 'last_month': {
                    return { start: 7, end: 30 };
                }
                default: {
                    return { start: 7, end: 30 };
                }
            }
        };
        const getNotifications = async (organisationId: string | undefined, teamId: string | undefined, from: number, size: number) => {
            const { start, end } = getDateInterval();
            try {
                setIsLoading(true);
                const {
                    data: { data, totalCount },
                } = await b2bNotifcationApi.getNotifications(organisationId, teamId, end, start, undefined, from, size);

                setTotalNotifcationCount(totalCount || 0);
                setNotifications(data);
            } catch (error) {
                setNotifications([]);
                setTotalNotifcationCount(0);
                console.log(error);
                navigate('/customers');
            } finally {
                setIsLoading(false);
            }
        };
        if (adminOrganisationId) {
            getNotifications(adminOrganisationId, undefined, (currentPage - 1) * PAGE_SIZE, PAGE_SIZE);
        } else {
            if (adminTeamId) {
                getNotifications(undefined, adminTeamId, (currentPage - 1) * PAGE_SIZE, PAGE_SIZE);
            }
        }
        if (updateSuccess.notification) {
            if (adminOrganisationId) {
                getNotifications(adminOrganisationId, undefined, (currentPage - 1) * PAGE_SIZE, PAGE_SIZE);
            } else {
                if (adminTeamId) {
                    getNotifications(undefined, adminTeamId, (currentPage - 1) * PAGE_SIZE, PAGE_SIZE);
                }
            }
        }
    }, [adminOrganisationId, adminTeamId, currentPage, interval, updateSuccess.notification, navigate]);

    const updateReadStatus = async (notificationId: string, profileId?: string) => {
        /** Only call API when read status is false */
        const notification = notifications.find(obj => obj.notificationId === notificationId);
        if (notification && !notification.read) {
            try {
                const { data } = await b2bNotifcationApi.updateNotification(notificationId, { read: true });
                if (data) {
                    setUpdateSuccess({ notification: true });
                    if (profileId) {
                        navigate(`/customers/${profileId}`);
                    }
                }
            } catch (error) {
                if (isAxiosError(error)) {
                    notify({ title: 'Failed to update notification read status', subtitle: `${error.response?.data?.error_message || error.message}`, type: 'error', progressColor: colors.ES5 });
                }
            }
        } else {
            /** `View Patient` button click but notification has been read */
            if (profileId) {
                navigate(`/customers/${profileId}`);
            }
        }
    };

    const handlePageChange = (page: number | string) => {
        if (typeof page === 'string') return;
        setCurrentPage(page);
    };

    const getTitleByInterval = () => {
        switch (interval) {
            case 'today': {
                return ` - ${t('notifications.list.title.today')}`;
            }
            case 'this_week': {
                return ` - ${t('notifications.list.title.this_week')}`;
            }
            case 'last_month': {
                return ` - ${t('notifications.list.title.last_month')}`;
            }
            default: {
                return ` - ${t('notifications.list.title.last_month')}`;
            }
        }
    };

    const handleBackClick = () => {
        navigate('/notifications');
    };

    return (
        <div className="Notifcations">
            <Button
                label={<Typography text={t('notifications.list.title.main')} color={colors.C5} type="p3" weight="black" />}
                buttonType="plain"
                color={colors.C5}
                leftIcon={() => <ChevronLeftC5 />}
                onClick={handleBackClick}
                className="Notifcations__back_button"
            ></Button>
            <div className="Notifcations__header">
                <Typography color={colors.B9} text={t('notifications.list.title.main') + getTitleByInterval()} type="h1" weight="semibold" />
            </div>
            {isLoading ? (
                <Loader />
            ) : notifications.length > 0 ? (
                <>
                    <div className="Notifcations__title">
                        <Typography weight="semibold" type="h4" color={colors.B9} text={t('notifications.search.total.test.count', { count: totalNotificationCount })} />
                        <Pagination totalCount={totalNotificationCount} pageSize={PAGE_SIZE} currentPage={currentPage} onPageChange={handlePageChange} />
                    </div>
                    <NotificationIntervalList updateReadStatus={updateReadStatus} notifications={notifications} />
                </>
            ) : (
                <div className="NoNotifcationContainer">
                    <div className="NoNotifcationCard Card">
                        <div className="Card__body">
                            <NoNotificationAvatar className="NoNotificationAvatar" />
                            <Typography color={colors.B9} text={t('notifications.noNotifications.card.title')} type="h3" weight="semibold" />
                            <Typography color={colors.B9} text={t('notifications.noNotifications.card.description')} type="p2" weight="regular" />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
