import { CIRCLE_DNA_WEBSITE_URL } from '@config';
import { useTranslation } from 'react-i18next';

type Nav = {
    name: string;
    link: string;
};

export const useFooterConstant = () => {
    const { t } = useTranslation('common');
    const CircleDNALink = CIRCLE_DNA_WEBSITE_URL;

    const footerBottomLink: Nav[] = [
        {
            name: t('login.footer.terms'),
            link: `${CircleDNALink}/terms`,
        },
        {
            name: t('login.footer.privacyPolicy'),
            link: `${CircleDNALink}/privacy`,
        },
        {
            name: t('login.footer.informedConsent'),
            link: `${CircleDNALink}/consent`,
        },
    ];

    return {
        footerBottomLink,
    };
};
