import './addTestModal.scss';

import AddIcon from '@assets/images/add.svg?react';
import CloseIcon from '@assets/images/close.svg?react';
import DeleteIcon from '@assets/images/delete.svg?react';
import { AddTestErrorModal, AddTestErrorType } from '@components/AddTestErrorModal';
import { API_BASE_URL, CIRCLE_DNA_WEBSITE_URL } from '@config';
import { ResultSku } from '@customTypes/Result';
import { Button } from '@prenetics/prenetics-react-library/lib/Button';
import { Checkbox } from '@prenetics/prenetics-react-library/lib/Checkbox';
import { Input } from '@prenetics/prenetics-react-library/lib/Input';
import { Modal } from '@prenetics/prenetics-react-library/lib/Modal';
import { Typography } from '@prenetics/prenetics-react-library/lib/Typography';
import type { GetOrganisationBarcode200Response } from '@services/api/b2b';
import { OrganisationApiFactory, ProfileApiFactory } from '@services/api/b2b';
import colors from '@theme/colors.module.scss';
import { getErrorMessage } from '@util/axios';
import { stylize } from '@util/stylize';
import { isAxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const b2bProfileApi = ProfileApiFactory(undefined, API_BASE_URL + '/b2b');
const b2bOrganisationApi = OrganisationApiFactory(undefined, API_BASE_URL + '/b2b');

const createResult = async (barcode: string, profileId: string) => {
    try {
        await b2bProfileApi.createResult(profileId, { barcode, correlationType: 'prenetics-kit' });
        return true;
    } catch (error) {
        if (isAxiosError(error)) {
            return getErrorMessage(error);
        }
        return false;
    }
};

export const AddTestModal = ({
    isOpen,
    closeModal,
    profileId,
    organisationId,
    onSuccess,
}: {
    isOpen: boolean;
    closeModal: () => void;
    profileId?: string;
    organisationId?: string;
    onSuccess: () => void;
}) => {
    const { t } = useTranslation();
    const [hasSignedConsent, setHasSignedConsent] = useState(false);
    const [barcodes, setBarcodes] = useState<GetOrganisationBarcode200Response[]>([]);
    const [barcodeInput, setBarcodeInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isValidatingBarcode, setIsValidatingBarcode] = useState(false);
    const [isAddTestErrorModalOpen, setIsAddTestErrorModalOpen] = useState(false);
    const [addTestErrorType, setAddTestErrorType] = useState<AddTestErrorType | undefined>(undefined);

    const addBarcode = async (value: string) => {
        if (!organisationId || !value) return;

        try {
            setIsValidatingBarcode(true);
            const { data } = await b2bOrganisationApi.getOrganisationBarcode(organisationId, value);

            if (data.kitStatus !== 'ordered') {
                throw Error('has already been assigned');
            }

            setBarcodes([...barcodes, data]);
        } catch (error) {
            if (isAxiosError(error)) {
                const errorMessage = getErrorMessage(error);
                if (errorMessage === 'Barcode is not valid') {
                    setAddTestErrorType(AddTestErrorType.BarcodeInvalid);
                } else {
                    setAddTestErrorType(AddTestErrorType.Other);
                }
            } else if (error instanceof Error && error.message === 'has already been assigned') {
                setAddTestErrorType(AddTestErrorType.KitHasBeenAssigned);
                setIsAddTestErrorModalOpen(true);
            } else {
                setAddTestErrorType(AddTestErrorType.Other);
            }
            setIsAddTestErrorModalOpen(true);
        } finally {
            setBarcodeInput('');
            setIsValidatingBarcode(false);
        }
    };

    const done = async () => {
        if (!hasSignedConsent || !profileId) return;

        if (barcodes.length === 0) {
            closeModal();
        } else if (hasSignedConsent) {
            try {
                setIsLoading(true);

                const response = await Promise.all(barcodes.map(barcode => createResult(barcode.barcode, profileId)));
                if (response.every(result => result === true)) {
                    onSuccess();
                    closeModal();
                } else {
                    if (response.some(result => result === 'Barcode is not valid')) {
                        setAddTestErrorType(AddTestErrorType.BarcodeInvalid);
                    } else if (response.some(result => typeof result === 'string' && result.includes('has already been assigned'))) {
                        setAddTestErrorType(AddTestErrorType.KitHasBeenAssigned);
                    } else {
                        setAddTestErrorType(AddTestErrorType.Other);
                    }
                    setIsAddTestErrorModalOpen(true);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleAddTestErrorModalClose = () => {
        setIsAddTestErrorModalOpen(false);
        setBarcodes([]);
        setBarcodeInput('');
        setHasSignedConsent(false);
    };

    useEffect(() => {
        if (isOpen) {
            setBarcodes([]);
            setBarcodeInput('');
            setHasSignedConsent(false);
        }
    }, [isOpen]);

    return (
        <div className="AddTestModal">
            <Modal isOpen={isOpen} handleModalOpenClose={closeModal}>
                <>
                    <div className="AddTestModal__header">
                        <Typography color={colors.B9} text={t('customers.addTest.modal.title')} type="h3" weight="semibold" />
                        <Button label="" leftIcon={() => <CloseIcon />} buttonType="secondary" onClick={closeModal} />
                    </div>
                    <div className="AddTestModal__body">
                        <Typography color={colors.B9} text={t('customers.addCustomer.modal.addTest.description')} type="p2" weight="regular" />
                        <div className="added-barcode-group">
                            {barcodes.map((barcode, index) => (
                                <div className="added-barcode-group__row" key={`barcode-${index}`}>
                                    <Typography text={`${barcode.barcode} - ${ResultSku[barcode.scope[0] as keyof typeof ResultSku]}`} color={colors.B9} type="p2" weight="semibold" />
                                    <Button
                                        label=""
                                        backgroundColor={colors.ES0}
                                        borderRadius="50%"
                                        leftIcon={() => <DeleteIcon color={colors.ES9} />}
                                        additionalsize="small"
                                        onClick={() => setBarcodes(barcodes.filter((_, i) => i !== index))}
                                        disable={isValidatingBarcode}
                                    ></Button>
                                </div>
                            ))}
                        </div>
                        {barcodes.length === 0 && (
                            <div className="barcode-input-group">
                                <Input placeholder="Test barcode" value={barcodeInput} onChange={e => setBarcodeInput(e.target.value)} />
                                <Button
                                    label=""
                                    backgroundColor={colors.SS0}
                                    borderRadius="50%"
                                    leftIcon={() => <AddIcon color={colors.SS9} />}
                                    additionalsize="small"
                                    onClick={() => addBarcode(barcodeInput)}
                                ></Button>
                            </div>
                        )}
                        <div>
                            <Checkbox
                                text={stylize(t('customers.addCustomer.modal.addTest.checkbox.message'), [`${CIRCLE_DNA_WEBSITE_URL}/consent`]) as string}
                                borderColor={colors.B9}
                                strokeColor={colors.B9}
                                isChecked={hasSignedConsent}
                                onClick={() => setHasSignedConsent(!hasSignedConsent)}
                            />
                        </div>
                    </div>
                    <div className="AddTestModal__footer">
                        <Button
                            label={t('customers.addCustomer.modal.button.done')}
                            buttonType="primary"
                            backgroundColor={colors.C5}
                            onClick={done}
                            disable={!hasSignedConsent || !barcodes.length}
                            isLoading={isLoading}
                        />
                    </div>
                </>
            </Modal>
            <AddTestErrorModal isOpen={isAddTestErrorModalOpen} closeModal={handleAddTestErrorModalClose} errorType={addTestErrorType} />
        </div>
    );
};
