import { Button } from '@prenetics/prenetics-react-library/lib/Button';
import { Modal } from '@prenetics/prenetics-react-library/lib/Modal';
import { Typography } from '@prenetics/prenetics-react-library/lib/Typography';
import colors from '@theme/colors.module.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ConfirmModal = ({ isOpen, closeModal, onConfirm }: { isOpen: boolean; closeModal: () => void; onConfirm: () => void }) => {
    const { t } = useTranslation();

    return (
        <div className="ConfirmModal">
            <Modal isOpen={isOpen} handleModalOpenClose={() => null}>
                <>
                    <div className="ConfirmModal__header">
                        <Typography color={colors.B9} text={t('customers.confirm.modal.title')} type="h3" weight="semibold" />
                    </div>
                    <div className="ConfirmModal__body">
                        <Typography color={colors.B9} text={t('customers.confirm.modal.description')} type="p2" weight="regular" />
                    </div>
                    <div className="ConfirmModal__footer">
                        <Button label={t('customers.confirm.modal.button.cancel')} buttonType="plain" color={colors.C5} backgroundColor="transparent" additionalsize="medium" onClick={closeModal} />
                        <Button
                            label={t('customers.confirm.modal.button.okay')}
                            buttonType="secondary"
                            borderColor={colors.C5}
                            color={colors.C5}
                            onClick={() => {
                                onConfirm();
                                closeModal();
                            }}
                        />
                    </div>
                </>
            </Modal>
        </div>
    );
};
