import { Badge, BadgeVariantEnum } from '@components/Badge';
import { ResultStatus } from '@services/api/b2b';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const TestStatusBadge = ({ status = ResultStatus.Activated, isOld = false }: { status?: ResultStatus; isOld?: boolean }) => {
    const { t } = useTranslation();
    let text = t(`common.test.status.${status.toLowerCase()}`);
    if (isOld) {
        text = t(`common.test.status.${status.toLowerCase()}`, { context: 'old' });
    }

    let badgeVariant = BadgeVariantEnum.Plain;
    switch (status) {
        case ResultStatus.ResultsReady:
        case ResultStatus.AtLab:
            badgeVariant = BadgeVariantEnum.Warning;
            break;
        case ResultStatus.Activated:
            badgeVariant = BadgeVariantEnum.Plain;
            break;
        case ResultStatus.ReportReleased:
            badgeVariant = BadgeVariantEnum.Success;
            break;
        case ResultStatus.SampleRejected:
            badgeVariant = BadgeVariantEnum.Error;
            break;
        default:
            badgeVariant = BadgeVariantEnum.Plain;
    }

    return <Badge text={text} variant={badgeVariant} />;
};
