import SuccessIcon from '@assets/images/activeCheck.svg?react';
import ErrorIcon from '@assets/images/error.svg?react';
import { ResultSkuName } from '@customTypes/Result';
import { Typography } from '@prenetics/prenetics-react-library';
import { Notification, ResultWithExtraMetadata } from '@services/api/b2b';
import colors from '@theme/colors.module.scss';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const NotificationCard = ({ notification, updateReadStatus }: { notification: Notification; updateReadStatus: (notificationId: string, profileId?: string) => void }) => {
    const { t } = useTranslation();

    const formatDateTime = (inputDateTime: string): string => {
        return moment(inputDateTime).format('HH:mm, DD/M/YYYY');
    };

    const returnFullName = (): string => {
        const firstName = notification.result?.profile?.name?.firstName;
        const lastName = notification.result?.profile?.name?.lastName;
        return firstName + (firstName ? ' ' : '') + lastName?.trim();
    };

    const returnSkuName = (result?: ResultWithExtraMetadata): string => {
        if (result && result.kitMetadata && result.kitMetadata.sku) {
            if (result.kitMetadata.sku in ResultSkuName) {
                return ResultSkuName[result.kitMetadata.sku as keyof typeof ResultSkuName];
            }
            return '';
        }
        return '';
    };

    return (
        <div
            className={`NotifcationList--card ${notification.read ? '' : 'unread'}`}
            onClick={() => {
                updateReadStatus(notification.notificationId);
            }}
        >
            <div className="NotifcationList--card-header">
                {notification.titleKey?.includes('REJECTED') ? <ErrorIcon /> : <SuccessIcon />}
                <Typography color={colors.B9} text={t(`notifications.${notification.titleKey?.toLocaleLowerCase()}`)} type="p2" weight="black" />
                <Typography className="NotifcationList--card-header-createdAt" color={colors.B9} text={formatDateTime(notification.createdAt)} type="p3" weight="regular" />
            </div>
            <Typography
                color={colors.B9}
                text={t(`notifications.${notification.contentKey?.toLocaleLowerCase()}`, { sku: returnSkuName(notification.result), customerName: returnFullName() })}
                type="p2"
                weight="regular"
            />
            <Typography
                className="NotifcationList--card-link"
                text={t('notifications.card.link.view_customer')}
                weight="black"
                color={colors.C5}
                type="p3"
                onClick={event => {
                    event.stopPropagation();
                    updateReadStatus(notification.notificationId, notification.result?.profile?.profileId || '');
                }}
            />
        </div>
    );
};
