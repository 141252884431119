import './addCustomerModal.scss';

import AddIcon from '@assets/images/add.svg?react';
import ArrowLeftIcon from '@assets/images/arrowLeft.svg?react';
import CloseIcon from '@assets/images/close.svg?react';
import DeleteIcon from '@assets/images/delete.svg?react';
import { AddCustomerErrorModal } from '@components/AddCustomerErrorModal';
import { AddTestErrorModal, AddTestErrorType } from '@components/AddTestErrorModal';
import { ConfirmModal } from '@components/ConfirmModal';
import { FormInput } from '@components/FormInput';
import { FormInputWithIcon } from '@components/FormInputWithIcon';
import { API_BASE_URL, CIRCLE_DNA_WEBSITE_URL, PHONE_NUMBER_MAX_LENGTH } from '@config';
import { CountryCodeIso31661 } from '@customTypes/CountryCodeIso31661';
import type { Ethnicity } from '@customTypes/Ethnicity';
import { PhoneCountryCode, phoneCountryCodes } from '@customTypes/PhoneCountryCode';
import { ResultSku } from '@customTypes/Result';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@prenetics/prenetics-react-library/lib/Button';
import { Checkbox } from '@prenetics/prenetics-react-library/lib/Checkbox';
import { DatePicker } from '@prenetics/prenetics-react-library/lib/DatePicker';
import { Dropdown } from '@prenetics/prenetics-react-library/lib/DropDown';
import { Input } from '@prenetics/prenetics-react-library/lib/Input';
import { Modal } from '@prenetics/prenetics-react-library/lib/Modal';
import { Typography } from '@prenetics/prenetics-react-library/lib/Typography';
import { AdminContext } from '@providers/AdminProvider';
import type { CreateProfileRequest, GetOrganisationBarcode200Response, HealthUpsertGenderEnum, HeightUnit, WeightUnit } from '@services/api/b2b';
import { OrganisationApiFactory, ProfileApiFactory, TeamApiFactory } from '@services/api/b2b';
import colors from '@theme/colors.module.scss';
import { getErrorMessage } from '@util/axios';
import { stylize } from '@util/stylize';
import { isAxiosError } from 'axios';
import moment from 'moment';
import type { ChangeEvent } from 'react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import isMobilePhone from 'validator/lib/isMobilePhone';
import * as yup from 'yup';
interface CreateProfileRequestSchema {
    firstName: string;
    lastName: string;
    dob: string;
    gender: string;
    ethnicity: string;
    heightValue: string;
    weightValue: string;
    externalUserId?: string;
    email: string;
    phoneCountryCode: string;
    phoneNumber: string;
}

interface PersonalDetailSchema {
    firstName: string;
    lastName: string;
    dob: string;
    gender: string;
    ethnicity: string;
    heightValue: string;
    weightValue: string;
    externalUserId?: string;
}

const personalDetailSchema = yup
    .object({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        weightValue: yup
            .string()
            .required()
            .test('weightValue', 'Weight must be between 10 and 800', value => {
                if (value) {
                    const weightValue = parseFloat(value);
                    return weightValue >= 10 && weightValue <= 800;
                }
                return false;
            }),
        heightValue: yup
            .string()
            .required()
            .test('heightValue', 'Height must be between 50 and 300', value => {
                if (value) {
                    const heightValue = parseInt(value);
                    return heightValue >= 50 && heightValue <= 300;
                }
                return false;
            }),
        dob: yup.string().required(),
        ethnicity: yup.string().required(),
        gender: yup.string().required(),
        externalUserId: yup.string().max(30, 'Unique ID must be less than 30 characters'),
    })
    .required();

const personalDetailDefaultValues = {
    firstName: '',
    lastName: '',
    dob: '',
    gender: '',
    ethnicity: '',
    heightValue: '',
    weightValue: '',
    externalUserId: '',
};

interface ContactDetailSchema {
    email: string;
    phoneCountryCode: string;
    phoneNumber: string;
}

const contactDetailSchema = yup
    .object({
        email: yup.string().email().required(),
        phoneCountryCode: yup.string().required(),
        phoneNumber: yup
            .string()
            .required()
            .test('phoneNumber', 'Phone number invalid', (value, context) => isMobilePhone(`${context.parent.phoneCountryCode}${value}`) && value.length <= PHONE_NUMBER_MAX_LENGTH),
    })
    .required();

const contactDetailDefaultValues = {
    email: '',
    phoneCountryCode: '',
    phoneNumber: '',
};

const genderOptions = [
    { id: 'male', name: 'Male' },
    { id: 'female', name: 'Female' },
];

const filteredPhoneCountryCodes = [...new Set(Object.values(phoneCountryCodes))];
const phoneCountryCodeOptions = filteredPhoneCountryCodes
    .map((value, i) => {
        return {
            id: i.toString(),
            name: value as PhoneCountryCode,
        };
    })
    .sort((a, b) => {
        if (a.name === phoneCountryCodes[CountryCodeIso31661.HongKong]) {
            return -1;
        } else if (b.name === phoneCountryCodes[CountryCodeIso31661.HongKong]) {
            return 1;
        } else {
            return a.name.localeCompare(b.name);
        }
    });

const datePickerFormat = 'DD/MMM/YYYY';

const b2bProfileApi = ProfileApiFactory(undefined, API_BASE_URL + '/b2b');
const b2bTeamApi = TeamApiFactory(undefined, API_BASE_URL + '/b2b');
const b2bOrganisationApi = OrganisationApiFactory(undefined, API_BASE_URL + '/b2b');

const createResult = async (barcode: string, profileId: string) => {
    try {
        await b2bProfileApi.createResult(profileId, { barcode, correlationType: 'prenetics-kit' });
        return true;
    } catch (error) {
        return false;
    }
};

export const ProgressSteps = ({
    steps,
    currentStep,
    backgroundColor = colors.C0,
    activeBackgroundColor = colors.C2,
}: {
    steps: number;
    currentStep: number;
    backgroundColor?: string;
    activeBackgroundColor?: string;
}) => {
    const { t } = useTranslation();
    const progressbarStepClass = 'ProgressSteps__progressbar-step';
    const progressbarStepActiveClass = 'ProgressSteps__progressbar-step ProgressSteps__progressbar-step--active';

    return (
        <div className="ProgressSteps">
            <div className="ProgressSteps__progressbar">
                {Array.from({ length: steps }).map((_, index) => (
                    <div
                        className={currentStep === index ? progressbarStepActiveClass : progressbarStepClass}
                        style={{ backgroundColor: currentStep === index ? activeBackgroundColor : backgroundColor }}
                        key={index}
                    ></div>
                ))}
            </div>
            <div className="ProgressSteps__description">
                <Typography
                    color={colors.B9}
                    text={t('customers.addCustomer.modal.progress.step', { currentStep: currentStep + 1, steps, description: t(`customers.addCustomer.modal.step.${currentStep}`) })}
                    type="p2"
                    weight="semibold"
                />
            </div>
        </div>
    );
};

export const AddCustomerModal = ({ isOpen, closeModal, ethnicityList, onSuccess }: { isOpen: boolean; closeModal: () => void; ethnicityList: Ethnicity[]; onSuccess: () => void }) => {
    const [step, setStep] = useState(0);
    const [hasExplainedTerms, setHasExplainedTerms] = useState(false);
    const [hasSignedConsent, setHasSignedConsent] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isAddCustomerErrorModalOpen, setIsAddCustomerErrorModalOpen] = useState(false);
    const [isAddTestErrorModalOpen, setIsAddTestErrorModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isValidatingBarcode, setIsValidatingBarcode] = useState(false);
    const [newProfileId, setNewProfileId] = useState('');
    const [barcodes, setBarcodes] = useState<GetOrganisationBarcode200Response[]>([]);
    const [barcodeInput, setBarcodeInput] = useState('');
    const [addTestErrorType, setAddTestErrorType] = useState<AddTestErrorType | undefined>(undefined);

    const { t } = useTranslation();
    const { adminTeamId, admin } = useContext(AdminContext);
    const {
        control: personalDetailControl,
        trigger: triggerPersonalDetail,
        formState: personalDetailFormState,
        getValues: getPersonalDetailValues,
        setValue: setPersonalDetailValue,
        reset: resetPersonalDetailForm,
    } = useForm<PersonalDetailSchema>({
        resolver: yupResolver(personalDetailSchema),
        defaultValues: personalDetailDefaultValues,
    });

    const {
        control: contactDetailControl,
        trigger: triggerContactDetail,
        formState: contactDetailFormState,
        getValues: getContactDetailValues,
        setValue: setContactDetailValue,
        reset: resetContactDetailForm,
    } = useForm<ContactDetailSchema>({
        resolver: yupResolver(contactDetailSchema),
        defaultValues: contactDetailDefaultValues,
    });

    const ethnicityOptions = ethnicityList.map(item => ({
        id: item.code,
        name: item.description,
    }));

    const isPersonalDetailFormValid = useMemo(() => {
        // If you want to subscribe to formState via useEffect, make sure that you place the entire formState in the optional array.
        return step === 0 && Object.keys(personalDetailFormState.errors).length === 0;
    }, [personalDetailFormState, step]);

    const isContactDetailFormValid = useMemo(() => {
        return step === 1 && Object.keys(contactDetailFormState.errors).length === 0;
    }, [contactDetailFormState, step]);

    const isNextButtonDisabled = useMemo(() => {
        if (step === 0) {
            return !personalDetailFormState.isDirty || !isPersonalDetailFormValid;
        } else if (step === 1) {
            return !contactDetailFormState.isDirty || !isContactDetailFormValid || !hasExplainedTerms;
        }
    }, [step, personalDetailFormState, isPersonalDetailFormValid, contactDetailFormState, isContactDetailFormValid, hasExplainedTerms]);

    const isDoneButtonDisabled = useMemo(() => {
        return barcodes.length === 0 || !hasSignedConsent;
    }, [barcodes, hasSignedConsent]);

    const nextStep = async () => {
        if (step === 0) {
            const res = await triggerPersonalDetail();
            if (res) setStep(step + 1);
        } else if (step === 1) {
            const res = await triggerContactDetail();
            if (res && hasExplainedTerms) {
                await onSubmit({
                    ...getPersonalDetailValues(),
                    ...getContactDetailValues(),
                });
            }
        } else if (step === 2) {
            try {
                setIsLoading(true);
                const response = await Promise.all(barcodes.map(barcode => createResult(barcode.barcode, newProfileId)));
                if (response.every(result => result)) {
                    handleCloseModal();
                } else {
                    setIsAddTestErrorModalOpen(true);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const previousStep = () => {
        setStep(step - 1);
    };

    const handleCloseModal = () => {
        if (step < 2 && personalDetailFormState.isDirty) {
            setIsConfirmModalOpen(true);
        } else {
            if (step === 2) {
                onSuccess();
            }
            closeModal();
        }
    };

    const handleHeightInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === '') {
            setPersonalDetailValue('heightValue', e.target.value, { shouldDirty: true, shouldValidate: true });
        } else if (Number(e.target.value)) {
            const flooredValue = Math.floor(parseFloat(e.target.value));
            setPersonalDetailValue('heightValue', flooredValue.toString(), { shouldDirty: true, shouldValidate: true });
        }
    };

    const handleWeightInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === '') {
            setPersonalDetailValue('weightValue', e.target.value, { shouldDirty: true, shouldValidate: true });
        } else if (Number(e.target.value)) {
            setPersonalDetailValue('weightValue', e.target.value, { shouldDirty: true, shouldValidate: true });
        }
    };

    const getFormatData = (data: CreateProfileRequestSchema): CreateProfileRequest => {
        const { firstName, lastName, dob, gender, ethnicity, heightValue, weightValue, externalUserId, email, phoneNumber, phoneCountryCode } = data;
        const name = { firstName, lastName };
        const phone = { number: phoneNumber, countryCode: phoneCountryCode };
        const health = {
            dob: moment(dob, datePickerFormat).format('YYYY-MM-DD'),
            gender: gender as HealthUpsertGenderEnum,
            ethnicity,
            height: {
                unit: 'cm' as HeightUnit,
                value: heightValue,
            },
            weight: {
                unit: 'kg' as WeightUnit,
                value: parseFloat(weightValue).toFixed(1),
            },
        };

        return { name, email, phone, health, externalUserId };
    };

    const onSubmit = async (data: CreateProfileRequestSchema) => {
        try {
            setIsLoading(true);
            if (adminTeamId) {
                const {
                    data: { profileId },
                } = await b2bTeamApi.createProfile(adminTeamId, getFormatData(data));
                setNewProfileId(profileId);
                setStep(step + 1);
            }
        } catch (error) {
            setIsAddCustomerErrorModalOpen(true);
        } finally {
            setIsLoading(false);
        }
    };

    const addBarcode = async (value: string) => {
        if (!admin?.organisation?.organisationId || !value) return;

        try {
            setIsValidatingBarcode(true);
            const { data } = await b2bOrganisationApi.getOrganisationBarcode(admin.organisation.organisationId, value);
            setBarcodes([...barcodes, data]);
        } catch (error) {
            if (isAxiosError(error)) {
                const errorMessage = getErrorMessage(error);
                if (errorMessage === 'Barcode is not valid') {
                    setAddTestErrorType(AddTestErrorType.BarcodeInvalid);
                } else {
                    setAddTestErrorType(AddTestErrorType.Other);
                }
            } else if (error instanceof Error && error.message === 'has already been assigned') {
                setAddTestErrorType(AddTestErrorType.KitHasBeenAssigned);
                setIsAddTestErrorModalOpen(true);
            } else {
                setAddTestErrorType(AddTestErrorType.Other);
            }
            setIsAddTestErrorModalOpen(true);
        } finally {
            setBarcodeInput('');
            setIsValidatingBarcode(false);
        }
    };

    const handleAddTestErrorModalClose = () => {
        setIsAddTestErrorModalOpen(false);
        setBarcodes([]);
        setBarcodeInput('');
        setHasSignedConsent(false);
    };

    useEffect(() => {
        if (!isOpen) {
            resetContactDetailForm();
            resetPersonalDetailForm();
            setStep(0);
            setHasExplainedTerms(false);
            setHasSignedConsent(false);
            setBarcodes([]);
            setBarcodeInput('');
        }
    }, [isOpen, resetContactDetailForm, resetPersonalDetailForm]);

    return (
        <>
            <div className="AddCustomerModal">
                <Modal isOpen={isOpen} handleModalOpenClose={handleCloseModal} borderRadius="0.5rem">
                    <>
                        <div className="AddCustomerModal__header">
                            <Typography color={colors.B9} text={t('customers.addCustomer.modal.title')} type="h3" weight="semibold" />
                            <Button label="" leftIcon={() => <CloseIcon />} buttonType="secondary" onClick={handleCloseModal} />
                        </div>
                        <div className="AddCustomerModal__body">
                            <ProgressSteps steps={3} currentStep={step} />
                            <form id="add-customer-form" className="AddCustomerModal__form" onSubmit={e => e.preventDefault()}>
                                {step === 0 && (
                                    <div className="AddCustomerModal__form-body AddCustomerModal__form-step-0">
                                        <div className="AddCustomerModal__form-row">
                                            <FormInput
                                                id="first-name"
                                                name="firstName"
                                                labelcomponent={<Typography text={t('customers.addCustomer.modal.field.firstName')} color={colors.B7} type="p3" weight="regular" />}
                                                control={personalDetailControl as unknown as Control<FieldValues>}
                                                onChange={e => setPersonalDetailValue('firstName', e.target.value, { shouldDirty: true, shouldValidate: true })}
                                                isError={!!personalDetailFormState.errors?.firstName?.message}
                                            />
                                            <FormInput
                                                id="last-name"
                                                name="lastName"
                                                labelcomponent={<Typography text={t('customers.addCustomer.modal.field.lastName')} color={colors.B7} type="p3" weight="regular" />}
                                                control={personalDetailControl as unknown as Control<FieldValues>}
                                                onChange={e => setPersonalDetailValue('lastName', e.target.value, { shouldDirty: true, shouldValidate: true })}
                                                isError={!!personalDetailFormState.errors?.lastName?.message}
                                            />
                                        </div>
                                        <div className="AddCustomerModal__form-row">
                                            <DatePicker
                                                id="dob"
                                                name="dob"
                                                labelcomponent={<Typography text={t('customers.addCustomer.modal.field.dob')} color={colors.B7} type="p3" weight="regular" />}
                                                format={datePickerFormat}
                                                themeColor={colors.C5}
                                                handlechange={value => setPersonalDetailValue('dob', value, { shouldDirty: true, shouldValidate: true })}
                                                isError={!!personalDetailFormState.errors?.dob?.message}
                                            />
                                        </div>
                                        <div className="AddCustomerModal__form-row">
                                            <Dropdown
                                                inputLabel={<Typography text={t('customers.addCustomer.modal.field.gender')} color={colors.B7} type="p3" weight="regular" />}
                                                customDefaultValue={genderOptions.find(gender => gender.id === getPersonalDetailValues('gender'))}
                                                dropDownList={genderOptions}
                                                onItemChange={item => {
                                                    if (typeof item !== 'string') {
                                                        setPersonalDetailValue('gender', item.id as HealthUpsertGenderEnum, { shouldDirty: true, shouldValidate: true });
                                                    } else {
                                                        setPersonalDetailValue('gender', item as HealthUpsertGenderEnum, { shouldDirty: true, shouldValidate: true });
                                                    }
                                                    triggerPersonalDetail('gender');
                                                }}
                                                onCreateNewItem={() => null}
                                                onClickArrow={() => null}
                                                isError={!!personalDetailFormState.errors?.gender?.message}
                                            />
                                            <Dropdown
                                                inputLabel={<Typography text={t('customers.addCustomer.modal.field.ethnicity')} color={colors.B7} type="p3" weight="regular" />}
                                                dropDownList={ethnicityOptions}
                                                customDefaultValue={ethnicityOptions.find(ethnicity => ethnicity.id === getPersonalDetailValues('ethnicity'))}
                                                onItemChange={item => {
                                                    if (typeof item !== 'string') {
                                                        setPersonalDetailValue('ethnicity', item.id, { shouldDirty: true, shouldValidate: true });
                                                    }
                                                    triggerPersonalDetail('ethnicity');
                                                }}
                                                onCreateNewItem={() => null}
                                                onClickArrow={() => null}
                                                isError={!!personalDetailFormState.errors?.ethnicity?.message}
                                            />
                                        </div>
                                        <div className="AddCustomerModal__form-row">
                                            <FormInputWithIcon
                                                id="height"
                                                name="heightValue"
                                                placeholder={t('customers.addCustomer.modal.field.height')}
                                                Icon={<Typography text="cm" color={colors.B7} type="p3" weight="regular" />}
                                                control={personalDetailControl as unknown as Control<FieldValues>}
                                                onChange={handleHeightInputChange}
                                                isError={!!personalDetailFormState.errors?.heightValue?.message}
                                            />
                                            <FormInputWithIcon
                                                id="weight"
                                                name="weightValue"
                                                placeholder={t('customers.addCustomer.modal.field.weight')}
                                                Icon={<Typography text="kg" color={colors.B7} type="p3" weight="regular" />}
                                                control={personalDetailControl as unknown as Control<FieldValues>}
                                                onChange={handleWeightInputChange}
                                                isError={!!personalDetailFormState.errors?.weightValue?.message}
                                            />
                                        </div>
                                        <div className="AddCustomerModal__form-row">
                                            <FormInput
                                                id="unique-id"
                                                name="externalUserId"
                                                placeholder={t('customers.addCustomer.modal.field.uniqueId')}
                                                control={personalDetailControl as unknown as Control<FieldValues>}
                                                onChange={e => setPersonalDetailValue('externalUserId', e.target.value, { shouldDirty: true, shouldValidate: true })}
                                                isError={!!personalDetailFormState.errors?.externalUserId?.message}
                                            />
                                        </div>
                                    </div>
                                )}
                                {step === 1 && (
                                    <div className="AddCustomerModal__form-body AddCustomerModal__form-step-1">
                                        <div className="AddCustomerModal__form-row">
                                            <div>
                                                <FormInput
                                                    id="email"
                                                    name="email"
                                                    labelcomponent={<Typography text={t('customers.addCustomer.modal.field.email')} color={colors.B7} type="p3" weight="regular" />}
                                                    control={contactDetailControl as unknown as Control<FieldValues>}
                                                    onChange={e => setContactDetailValue('email', e.target.value, { shouldValidate: true, shouldDirty: true })}
                                                    isError={!!contactDetailFormState.errors?.email?.message}
                                                />
                                            </div>
                                            <div className="AddCustomerModal__form-row--phone">
                                                <Dropdown
                                                    dropDownList={phoneCountryCodeOptions}
                                                    inputLabel={<Typography text={t('customers.addCustomer.modal.field.countryCode')} color={colors.B7} type="p3" weight="regular" />}
                                                    onItemChange={item => {
                                                        if (typeof item !== 'string') {
                                                            setContactDetailValue('phoneCountryCode', item.name, { shouldDirty: true, shouldValidate: true });
                                                        }
                                                        triggerContactDetail();
                                                    }}
                                                    onCreateNewItem={() => null}
                                                    onClickArrow={() => null}
                                                    isError={!!contactDetailFormState.errors?.phoneCountryCode?.message}
                                                />
                                                <FormInput
                                                    id="contact-number"
                                                    name="phoneNumber"
                                                    labelcomponent={<Typography text={t('customers.addCustomer.modal.field.contactNumber')} color={colors.B7} type="p3" weight="regular" />}
                                                    control={contactDetailControl as unknown as Control<FieldValues>}
                                                    onChange={e => setContactDetailValue('phoneNumber', e.target.value, { shouldValidate: true, shouldDirty: true })}
                                                    isError={!!contactDetailFormState.errors?.phoneNumber?.message}
                                                    errormessage={contactDetailFormState.errors?.phoneNumber?.message ? t('customers.addCustomer.modal.contactNumber.invalid') : ''}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <Checkbox
                                                text={stylize(t('customers.addCustomer.modal.createCustomer.checkbox.message'), [`${CIRCLE_DNA_WEBSITE_URL}/terms`]) as string}
                                                borderColor={colors.B9}
                                                strokeColor={colors.B9}
                                                isChecked={hasExplainedTerms}
                                                onClick={() => setHasExplainedTerms(!hasExplainedTerms)}
                                            />
                                        </div>
                                    </div>
                                )}
                                {step === 2 && (
                                    <div className="AddCustomerModal__form-body AddCustomerModal__form-step-2">
                                        <Typography color={colors.B9} text={t('customers.addCustomer.modal.addTest.description')} type="p2" weight="regular" />
                                        <div className="added-barcode-group">
                                            {barcodes.map((barcode, index) => (
                                                <div className="added-barcode-group__row" key={`barcode-${index}`}>
                                                    <Typography text={`${barcode.barcode} - ${ResultSku[barcode.scope[0] as keyof typeof ResultSku]}`} color={colors.B9} type="p2" weight="semibold" />
                                                    <Button
                                                        label=""
                                                        backgroundColor={colors.ES0}
                                                        borderRadius="50%"
                                                        leftIcon={() => <DeleteIcon color={colors.ES9} />}
                                                        additionalsize="small"
                                                        onClick={() => setBarcodes(barcodes.filter((_, i) => i !== index))}
                                                        disable={isValidatingBarcode}
                                                    ></Button>
                                                </div>
                                            ))}
                                        </div>
                                        {barcodes.length === 0 && (
                                            <div className="barcode-input-group">
                                                <Input placeholder="Test barcode" value={barcodeInput} onChange={e => setBarcodeInput(e.target.value)} />
                                                <Button
                                                    label=""
                                                    backgroundColor={colors.SS0}
                                                    borderRadius="50%"
                                                    leftIcon={() => <AddIcon color={colors.SS9} />}
                                                    additionalsize="small"
                                                    onClick={() => addBarcode(barcodeInput)}
                                                ></Button>
                                            </div>
                                        )}
                                        <Checkbox
                                            text={stylize(t('customers.addCustomer.modal.addTest.checkbox.message'), [`${CIRCLE_DNA_WEBSITE_URL}/consent`]) as string}
                                            borderColor={colors.B9}
                                            strokeColor={colors.B9}
                                            isChecked={hasSignedConsent}
                                            onClick={() => setHasSignedConsent(!hasSignedConsent)}
                                        />
                                    </div>
                                )}
                            </form>
                        </div>
                        <div className="AddCustomerModal__footer">
                            {step === 1 && (
                                <Button
                                    label={t('customers.addCustomer.modal.button.back')}
                                    buttonType="plain"
                                    color={colors.C5}
                                    backgroundColor="transparent"
                                    additionalsize="medium"
                                    leftIcon={() => <ArrowLeftIcon fill={colors.C5} height={16} />}
                                    onClick={previousStep}
                                />
                            )}
                            <div className="AddCustomerModal__footer--end-block">
                                {step === 0 && !isPersonalDetailFormValid && <Typography color={colors.ES5} text={t('customers.addCustomer.modal.message.required')} type="p3" weight="regular" />}
                                {step < 2 && (
                                    <Button
                                        label={t('customers.addCustomer.modal.button.next')}
                                        buttonType="primary"
                                        backgroundColor={colors.C5}
                                        onClick={nextStep}
                                        disable={isNextButtonDisabled}
                                        isLoading={isLoading}
                                    />
                                )}
                                {step === 2 && (
                                    <>
                                        <Button
                                            label={<Typography text={t('customers.addCustomer.modal.button.skip')} type="p3" color={colors.C5} weight="black" />}
                                            buttonType="plain"
                                            onClick={handleCloseModal}
                                        ></Button>
                                        <Button
                                            label={t('customers.addCustomer.modal.button.done')}
                                            buttonType="primary"
                                            backgroundColor={colors.C5}
                                            onClick={nextStep}
                                            disable={isDoneButtonDisabled || isLoading}
                                            isLoading={isLoading}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                </Modal>
            </div>
            <ConfirmModal isOpen={isConfirmModalOpen} closeModal={() => setIsConfirmModalOpen(false)} onConfirm={closeModal} />
            <AddCustomerErrorModal isOpen={isAddCustomerErrorModalOpen} closeModal={() => setIsAddCustomerErrorModalOpen(false)} />
            <AddTestErrorModal isOpen={isAddTestErrorModalOpen} closeModal={handleAddTestErrorModalClose} errorType={addTestErrorType} />
        </>
    );
};
