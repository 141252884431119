import './addCustomerErrorModal.scss';

import WarningIcon from '@assets/images/warning.svg?react';
import { Button } from '@prenetics/prenetics-react-library/lib/Button';
import { Modal } from '@prenetics/prenetics-react-library/lib/Modal';
import { Typography } from '@prenetics/prenetics-react-library/lib/Typography';
import colors from '@theme/colors.module.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';

export enum AddCustomerErrorType {
    DataInvalid = 'data_invalid',
    Other = 'other',
}

export const AddCustomerErrorModal = ({ isOpen, closeModal, errorType = AddCustomerErrorType.DataInvalid }: { isOpen: boolean; closeModal: () => void; errorType?: AddCustomerErrorType }) => {
    const { t } = useTranslation();

    return (
        <div className="AddCustomerErrorModal">
            <Modal isOpen={isOpen} handleModalOpenClose={() => null}>
                <div className="AddCustomerErrorModal__body">
                    <WarningIcon />
                    <div className="AddCustomerErrorModal__title">
                        <Typography text={t('customers.addCustomer.error.modal.title')} weight="black" color={colors.B9} type="h1" />
                    </div>
                    <div className="AddCustomerErrorModal__description">
                        <Typography text={t(`customers.addCustomer.error.modal.description.${errorType}`)} weight="regular" color={colors.B9} type="p2" />
                    </div>
                    <Button
                        label={<Typography weight="black" type="h4" color={colors.White} text={t('customers.addCustomer.error.modal.button.retry')} />}
                        buttonType="primary"
                        backgroundColor={colors.C5}
                        additionalsize="medium"
                        fullWidth
                        onClick={closeModal}
                    />
                </div>
            </Modal>
        </div>
    );
};
