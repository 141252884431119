import './NotificationIntervalList.scss';

import { NotificationCard } from '@components/NotificationCard';
import { Notification } from '@services/api/b2b';
import React from 'react';

interface NotifcationListProps {
    notifications: Notification[];
    updateReadStatus: (notificationId: string, profileId?: string) => void;
}

export const NotificationIntervalList = ({ notifications, updateReadStatus }: NotifcationListProps) => {
    return (
        <div className="NotifcationList">
            {notifications.length > 0 && (
                <div className="NotifcationList--list">
                    {notifications.map(notification => (
                        <NotificationCard updateReadStatus={updateReadStatus} key={notification.notificationId} notification={notification} />
                    ))}
                </div>
            )}
        </div>
    );
};
