import { X_OWNER } from '@config';
import axios, { AxiosRequestHeaders } from 'axios';

export function initializeAxios(logout?: () => void, token?: string) {
    axios.interceptors.request.use(req => {
        if (token && req.headers) {
            const headers = req.headers as { [key: string]: string };
            headers.Authorization = headers.Authorization || `Bearer ${token}`;
            headers[X_OWNER] = headers[X_OWNER] || '*';
            req.headers = headers as AxiosRequestHeaders;
        }
        return req;
    });

    axios.interceptors.response.use(
        res => res,
        err => {
            if (err.response?.status === 401 && logout) {
                logout();
                return Promise.reject(err);
            }

            return Promise.reject(err);
        },
    );
}
