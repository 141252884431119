import './forgotPassword.scss';

import ShowLogo from '@assets/images/show.svg?react';
import { OTP_LENGTH, OTP_RESEND_DELAY_SECONDS } from '@config';
import { Rules } from '@customTypes/PasswordCheck';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@prenetics/prenetics-react-library/lib/Button';
import { Input } from '@prenetics/prenetics-react-library/lib/Input';
import { InputWithButton } from '@prenetics/prenetics-react-library/lib/InputWithButton';
import { InputWithIcon } from '@prenetics/prenetics-react-library/lib/InputWithIcon';
import { Modal } from '@prenetics/prenetics-react-library/lib/Modal';
import { PasswordCheck } from '@prenetics/prenetics-react-library/lib/PasswordCheck';
import { Typography } from '@prenetics/prenetics-react-library/lib/Typography';
import { useAuth } from '@prenetics/react-context-provider';
import colors from '@theme/colors.module.scss';
import { getUserId } from '@util/jwt';
import { isValidOtp } from '@util/validation';
import type { ChangeEvent } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

type FormValues = {
    email: string;
    code: string;
    password: string;
};

const schema = yup
    .object({
        email: yup.string().email('login.field.email.invalid').required('login.field.required'),
        code: yup.string().required(),
        password: yup.string().required('login.field.required'),
    })
    .required();

export const rules: Rules[] = ['length', 'lowercase', 'uppercase', 'number', 'specialChar'];

export const ForgotPassword = () => {
    const { t } = useTranslation('common');
    const [isSendingOTP, setIsSendingOTP] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [OtpBtnDisabled, setOtpBtnDisabled] = useState(true);
    const [OtpFieldDisabled, setOtpFieldDisabled] = useState(true);
    const [disableField, setDisableField] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [emailDisabled, setEmailDisabled] = useState(false);
    const [resendIn, setResendIn] = useState(-1);
    const [resendInterval, setResendInterval] = useState(-1);
    const [otpSentAtLeastOnce, setOTPSentAtLeastOnce] = useState<boolean>(false);
    const [otpToken, setOtpToken] = useState('');
    const [isOTPInvalid, setIsOTPInvalid] = useState<boolean>();
    const [verifyOtpState, setVerifyOtpState] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isPasswordValid, setPasswordValid] = useState<boolean>(false);
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const navigate = useNavigate();
    const { requestOtp, verifyOtp, updatePassword } = useAuth();

    const {
        handleSubmit,
        trigger,
        formState: { errors },
        setValue,
        getValues,
        setError,
    } = useForm<FormValues>({
        resolver: yupResolver(schema),
    });

    const isPasswordMatch = getValues('password') === confirmPassword;

    const isFormValid = isPasswordValid && isPasswordMatch;

    const otpButtonLabel = useMemo(() => {
        if (resendIn > OTP_RESEND_DELAY_SECONDS - 3) return t('login.forgotPassword.button.otp.sent');
        if (resendIn > 0) return resendIn;
        if (otpSentAtLeastOnce) return t('login.forgotPassword.button.otp.resend');
        return t('login.forgotPassword.button.otp.request');
    }, [resendIn, otpSentAtLeastOnce, t]);

    const passwordCheckMessages = useMemo(() => rules.reduce((o, key, index) => ({ ...o, [key]: t(`login.forgotPassword.field.newPassword.tooltipCriteria.${index}`) }), {}), [t]);

    useEffect(() => {
        getValues('email') && !errors.email ? setOtpBtnDisabled(false) : setOtpBtnDisabled(true);
    }, [getValues, errors]);

    const stopCountdown = useCallback(() => window.clearInterval(resendInterval), [resendInterval]);

    useEffect(() => {
        if (resendIn === -1 && getValues('email')) {
            stopCountdown();
            setOtpBtnDisabled(false);
        } else {
            setOtpBtnDisabled(true);
        }
    }, [resendIn, getValues, stopCountdown]);

    const sendEmailOTP = async () => {
        stopCountdown();
        setIsSendingOTP(true);
        const email = getValues('email');
        try {
            await requestOtp(email, true);
            setOtpBtnDisabled(true);
            setOtpFieldDisabled(false);
            setResendIn(OTP_RESEND_DELAY_SECONDS);
            setOTPSentAtLeastOnce(true);
            setResendInterval(window.setInterval(decreaseResendTime, 1000));
        } catch (error) {
            setError('email', { message: t('login.forgotPassword.incorrect.credentials') });
        }
        setIsSendingOTP(false);
    };

    const decreaseResendTime = () => {
        setResendIn(prevState => prevState - 1);
    };

    const onOtpChange = async (e: ChangeEvent<HTMLInputElement>) => {
        setValue('code', e.target.value.replace(/\D/g, ''));
        verifyOTP();
    };

    const verifyOTP = async () => {
        const { email, code } = getValues();
        if (!isValidOtp(code)) return;

        try {
            const response = await verifyOtp(email, code);
            setOtpToken(response);
            setDisableField(false);
            setEmailDisabled(true);
            setOtpFieldDisabled(true);
            setIsOTPInvalid(false);
            setVerifyOtpState(true);
        } catch (error) {
            setIsOTPInvalid(true);
        }
    };

    const onSubmit = async (data: FormValues) => {
        if (!isFormValid) return;

        try {
            setIsSubmitLoading(true);
            if (otpToken) {
                const userId = getUserId(otpToken);
                await updatePassword(data.password, otpToken, userId);
                setIsModalOpen(true);
            }
        } catch (error) {
            setError('password', { message: t('login.forgotPassword.incorrect.credentials') });
        } finally {
            setIsSubmitLoading(false);
        }
    };

    return (
        <div className="ForgotPassword">
            <form className="ForgotPassword__form" id="forgot-password-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="ForgotPassword__title">
                    {/* <div className="ForgotPassword__header">
                        <Typography text={stylize(t('login.forgotPassword.goto.login'), ['/login']) as string} color={colors.N5} type="p3" weight="regular" />
                    </div> */}
                    <Typography text={t('login.forgotPassword.title')} color={colors.B9} type="h1" weight="semibold" />
                    <div className="ForgotPassword__sub-title">
                        <Typography text={t('login.forgotPassword.p.0')} color={colors.B7} type="p3" weight="regular" />
                    </div>
                </div>
                <div className="ForgotPassword__form-body">
                    <InputWithButton
                        id="email"
                        name="email"
                        onChange={e => setValue('email', e.target.value)}
                        additionalsize="small"
                        disabled={emailDisabled}
                        labelcomponent={<Typography text={t('login.forgotPassword.field.email')} color={colors.B7} type="p3" weight="regular" />}
                        onBlur={() => trigger('email')}
                        button={{ label: otpButtonLabel, id: 'resendBtn', onClick: () => sendEmailOTP(), isLoading: isSendingOTP, disable: OtpBtnDisabled }}
                        errormessage={errors.email && errors.email.message && <Typography text={t(errors.email.message as 'login.field.required')} color={colors.ES5} type="p3" weight="regular" />}
                    />
                    <Input
                        name="code"
                        className="code-input"
                        onChange={onOtpChange}
                        additionalsize="small"
                        labelcomponent={<Typography text={t('login.forgotPassword.field.otp.action')} color={OtpFieldDisabled ? colors.B4 : colors.B7} type="p3" weight="regular" />}
                        disabled={OtpFieldDisabled}
                        type={'text'}
                        onBlur={() => trigger('code')}
                        id="otp"
                        maxLength={OTP_LENGTH}
                        errormessage={isOTPInvalid ? <Typography text={t('login.forgotPassword.field.otp.failure')} color={colors.ES5} type="p3" weight="regular" /> : ''}
                        message={verifyOtpState && !isOTPInvalid ? <Typography text={t('login.forgotPassword.field.otp.success')} color={colors.SS8} type="p3" weight="regular" /> : ''}
                    />
                    <InputWithIcon
                        name="password"
                        onChange={e => setValue('password', e.target.value)}
                        Icon={<ShowLogo style={{ fill: disableField ? colors.B4 : colors.B9 }} className="show" onClick={() => setShowPassword(!showPassword)} />}
                        additionalsize="small"
                        labelcomponent={<Typography text={t('login.forgotPassword.field.newPassword.title')} color={disableField ? colors.B4 : colors.B7} type="p3" weight="regular" />}
                        type={showPassword ? 'text' : 'password'}
                        disabled={disableField}
                        onBlur={() => trigger('password')}
                        id="password"
                        errormessage={
                            errors.password && errors.password.message && <Typography text={t(errors.password.message as 'login.field.required')} color={colors.ES5} type="p3" weight="regular" />
                        }
                    />
                    {!disableField && (
                        <PasswordCheck isPasswordValid={setPasswordValid} minLength={8} maxLength={126} value={getValues('password') || ''} rules={rules} messages={passwordCheckMessages} />
                    )}

                    <InputWithIcon
                        name="confirmPassword"
                        onChange={e => setConfirmPassword(e.target.value)}
                        Icon={<ShowLogo style={{ fill: disableField ? colors.B4 : colors.B9 }} className="show" onClick={() => setShowConfirmPassword(!showConfirmPassword)} />}
                        additionalsize="small"
                        labelcomponent={<Typography text={t('login.forgotPassword.field.confirmPassword.title')} color={disableField ? colors.B4 : colors.B7} type="p3" weight="regular" />}
                        type={showConfirmPassword ? 'text' : 'password'}
                        disabled={disableField}
                        onBlur={() => trigger('password')}
                        id="confirm-password"
                        errormessage={getValues('password') && !isPasswordMatch ? t('login.forgotPassword.field.confirmPassword.invalid') : ''}
                    />

                    <Button
                        type="submit"
                        isLoading={isSubmitLoading}
                        loadingColor={'fff'}
                        buttonType="primary"
                        label={<Typography weight="regular" type="p2" color={isFormValid ? colors.B0 : colors.B4} text={t('login.forgotPassword.button.confirm')} />}
                        backgroundColor={isSubmitLoading ? colors.B2 : colors.N5}
                        additionalsize="medium"
                        disable={!isFormValid || isSubmitLoading}
                        id="submitBtn"
                    />
                </div>
            </form>

            <Modal isOpen={isModalOpen} handleModalOpenClose={() => {}}>
                <div className="ForgotPassword__modal">
                    <Typography text={t('login.forgotPassword.changePassword.success')} color={colors.B9} type="p2" weight="black" />
                    <Button
                        buttonType="primary"
                        label={<Typography text={t('login.forgotPassword.goto.login')} color={colors.B0} type="p3" weight="regular" />}
                        backgroundColor={colors.N5}
                        additionalsize="medium"
                        fullWidth
                        onClick={() => navigate('/login')}
                    ></Button>
                </div>
            </Modal>
        </div>
    );
};
