import './routes.scss';

import { AddCustomerModal } from '@components/AddCustomerModal';
import { Footer } from '@components/Footer';
import { Header } from '@components/Header';
import { Sidebar } from '@components/Sidebar';
import type { Ethnicity } from '@customTypes/Ethnicity';
import { ToastMessage } from '@prenetics/prenetics-react-library/lib/Toast';
import { useAuth } from '@prenetics/react-context-provider';
import AdminProvider from '@providers/AdminProvider';
import { ApiUpdateProvider } from '@providers/ApiUpdateProvider';
import { CustomerDetail } from '@screens/CustomerDetail';
import { Customers } from '@screens/Customers';
import { ForgotPassword } from '@screens/ForgotPassword';
import { Login } from '@screens/Login';
import { NotificationInterval } from '@screens/NotificationInterval';
import { Notifications } from '@screens/Notifications';
import { Overview } from '@screens/Overview';
import { TestDetail } from '@screens/TestDetail';
import { Tests } from '@screens/Tests';
import { wrapUseRoutes } from '@sentry/react';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate, useRoutes } from 'react-router-dom';

const ETHNICITY_NOT_AVAILABLE = ['eastasian', 'southeastasian'];

const AuthenticatedRoute = () => {
    const { token } = useAuth();
    const navigate = useNavigate();
    const [isAddCustomerModalOpen, setIsAddCustomerModalOpen] = useState(false);
    const [ethnicityList, setEthnicityList] = useState<Ethnicity[]>([]);
    const [shouldUpdateCustomer, setShouldUpdateCustomer] = useState(false);

    const openAddCustomerModal = useCallback(() => {
        setIsAddCustomerModalOpen(true);
    }, []);

    const closeAddCustomerModal = useCallback(() => {
        setIsAddCustomerModalOpen(false);
    }, []);

    const onAddCustomerSuccess = useCallback(() => {
        setShouldUpdateCustomer(true);
    }, []);

    const onUpdateCustomerSuccess = useCallback(() => {
        setShouldUpdateCustomer(false);
    }, []);

    useEffect(() => {
        const getEthnicity = async () => {
            try {
                const { data } = await axios.get('profile/v1.0/ethnicity?region=hk');
                setEthnicityList(data.filter((item: Ethnicity) => !ETHNICITY_NOT_AVAILABLE.includes(item.code)));
            } catch (error) {
                console.log(error);
            }
        };

        if (!token) {
            navigate('/login');
        } else {
            getEthnicity();
        }
    }, [token, navigate]);

    return (
        <AdminProvider>
            <ApiUpdateProvider>
                <ToastMessage />
                <div className="Layout">
                    <Sidebar openAddCustomerModal={openAddCustomerModal} />
                    <Outlet context={{ shouldUpdateCustomer, onUpdateCustomerSuccess, openAddCustomerModal, ethnicityList }} />
                    <AddCustomerModal isOpen={isAddCustomerModalOpen} closeModal={closeAddCustomerModal} ethnicityList={ethnicityList} onSuccess={onAddCustomerSuccess} />
                </div>
            </ApiUpdateProvider>
        </AdminProvider>
    );
};

const OnboardingRoute = () => {
    const navigate = useNavigate();
    const { token } = useAuth();

    useEffect(() => {
        if (token) {
            navigate('/');
        }
    }, [token, navigate]);

    return (
        <div className="LayoutOnboarding">
            <Header />
            <Outlet />
            <Footer />
        </div>
    );
};

const routes = [
    {
        element: <OnboardingRoute />,
        children: [
            {
                path: '/login',
                element: <Login />,
            },
            {
                path: '/forgot-password',
                element: <ForgotPassword />,
            },
            { path: '*', element: <Navigate to="/login" /> },
        ],
    },
    {
        element: <AuthenticatedRoute />,
        children: [
            {
                path: '/',
                element: <Overview />,
            },
            {
                path: '/customers',
                element: <Customers />,
            },
            {
                path: '/customers/:customerId',
                element: <CustomerDetail />,
            },
            {
                path: '/tests/:testId',
                element: <TestDetail />,
            },
            {
                path: '/tests',
                element: <Tests />,
            },
            {
                path: '/notifications',
                element: <Notifications />,
            },
            {
                path: '/notifications/:interval',
                element: <NotificationInterval />,
            },
        ],
    },
];

const useSentryRoutes = wrapUseRoutes(useRoutes);

export const Routes = () => {
    return useSentryRoutes(routes);
};
