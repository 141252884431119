import { InputWithIcon, InputWithIconProps } from '@prenetics/prenetics-react-library';
import React, { forwardRef } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

const FormInputWithIcon = forwardRef((props: UseControllerProps & InputWithIconProps) => {
    const { name, rules, control, defaultValue = '', ...inputProps } = props;

    const { field } = useController({ name, control, rules, defaultValue });

    return <InputWithIcon {...field} {...inputProps} />;
});

FormInputWithIcon.displayName = 'FormInputWithIcon';

export { FormInputWithIcon };
