import './header.scss';

import CircleDNALogo from '@assets/images/DNAProfessional.png';
import { CustomLanguageSelect } from '@components/CustomLanguageSelect';
import { LazyLoadImageComponent } from '@components/LazyLoadImage';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const Header = () => {
    const { i18n } = useTranslation();

    return (
        <header className="Header">
            <div className="Header__container">
                <div className="Header__logo">
                    {' '}
                    <LazyLoadImageComponent src={CircleDNALogo} data-testid="desktop-left-item-logo" style={{ cursor: 'pointer' }} alt="circle-snapshot" />
                </div>
                <div className="Header__language-select">
                    <CustomLanguageSelect lang={i18n.language} />
                </div>
            </div>
        </header>
    );
};
