import './footer.scss';

import { Typography } from '@prenetics/prenetics-react-library/lib/Typography';
import colors from '@theme/colors.module.scss';
import React from 'react';

import { useFooterConstant } from './constant';

export const Footer = () => {
    const { footerBottomLink } = useFooterConstant();

    return (
        <footer className="Footer">
            <div className="Footer__container">
                <div className="Footer__list">
                    {footerBottomLink.map((link, index) => {
                        return (
                            <a href={link.link} key={index}>
                                <Typography weight="regular" type="caption" color={colors.B8} text={link.name} />
                            </a>
                        );
                    })}
                </div>
            </div>
        </footer>
    );
};
