import { Input, InputProps } from '@prenetics/prenetics-react-library';
import React from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

export const FormInput = (props: UseControllerProps & InputProps) => {
    const { name, rules, control, defaultValue = '', ...inputProps } = props;

    const { field } = useController({ name, control, rules, defaultValue });

    return <Input {...field} {...inputProps} />;
};
