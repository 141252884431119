import React, { createContext, useState } from 'react';

interface ApiUpdateField {
    notification: boolean;
}

interface ApiUpdateContextProps {
    updateSuccess: ApiUpdateField;
    setUpdateSuccess: React.Dispatch<React.SetStateAction<ApiUpdateField>>;
}

const initialState: ApiUpdateField = {
    notification: false,
};

export const ApiUpdateContext = createContext<ApiUpdateContextProps>({
    updateSuccess: initialState,
    setUpdateSuccess: () => {},
});

export const ApiUpdateProvider = ({ children }: { children: React.ReactNode }) => {
    const [updateSuccess, setUpdateSuccess] = useState(initialState);

    return <ApiUpdateContext.Provider value={{ updateSuccess, setUpdateSuccess }}>{children}</ApiUpdateContext.Provider>;
};
