import 'react-lazy-load-image-component/src/effects/blur.css';

import React, { lazy } from 'react';
import { LazyLoadImageProps } from 'react-lazy-load-image-component';

const LazyLoadImage = lazy(() => import('react-lazy-load-image-component').then(module => ({ default: module.LazyLoadImage })));

export const LazyLoadImageComponent = ({ ...props }: LazyLoadImageProps) => {
    return <LazyLoadImage {...props} />;
};
