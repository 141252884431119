import './table.scss';

import type { ColumnDef } from '@tanstack/react-table';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React from 'react';

type TableProps<T> = {
    data: T[];
    columns: ColumnDef<T>[];
    onClickRow?: (row: T) => void;
    disabledRows?: { field: keyof T; ids: string[] };
};

export const Table = <T extends object>({ data, columns, onClickRow, disabledRows }: TableProps<T>) => {
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    const handleRowClick = (payload: T) => {
        if (!onClickRow) return;

        onClickRow(payload);
    };

    return (
        <div className="Table">
            <table className="Table__container">
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th key={header.id}>{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map(row => {
                        let trClassName = '';

                        if (disabledRows && Object.prototype.hasOwnProperty.call(row.original, disabledRows.field) && disabledRows.ids.includes(String(row.original[disabledRows.field]))) {
                            trClassName = 'Table__row--disabled';
                        } else if (onClickRow) {
                            trClassName = 'Table__row--clickable';
                        }

                        return (
                            <tr className={trClassName} key={row.id} onClick={() => handleRowClick(row.original)}>
                                {row.getVisibleCells().map(cell => (
                                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
